<template>
  <div id="crearUsuario" :key="renderizado">

    <h4 id="h4">CREACIÓN DE NUEVOS USUARIOS</h4>
    <section id="sform">
      <form @submit.prevent="handleSubmit">
        <table>
          <tr>
            <td class="celdaizq"><label for="" class="lbl">ROL: </label></td>
            <td>
              <select id="sel12" class="inp" v-model="usu_dat_pers.rol" :disabled="!isEditing" required>
                <option value="" selected>Seleccione una opción</option>
                <option value="2">ADMIN SUBDIRECTIVA</option>
                <option value="3">ADMIN SINDICATO</option>
                <option value="4">ADMIN GENERAL</option>
                <option value="1">AFILIADO</option>
              </select>
            </td>
          </tr>
          <tr><td>&nbsp;</td></tr>
          <template v-if="usu_dat_pers.rol == 1">
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Fecha de Afiliación: </label></td>
              <td><input id="" class="inp" type="date" v-model="datos_afi.fecha_afi" :disabled="!isEditing" required><br></td> <!-- value="2024-08-24" -->
            </tr>
          </template>
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Tipo Documento de Identidad: </label></td>
            <td>
              <select id="sel0" class="inp" v-model="usu_dat_pers.tipo_id" :disabled="!isEditing" required>
                <option value="" selected>Seleccione una opción</option>                            
                <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
                <option value="Cédula de extranjería">Cédula de extranjería</option>
                <option value="Tarjeta de identidad">Tarjeta de identidad</option>                 
                <option value="Pasaporte">Pasaporte</option>
                <option value="Certificado de nacimiento">Certificado de nacimiento</option>
              </select>
            </td>
          </tr>
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Documento de Identidad: </label></td>
            <td><input id="" class="inp" type="text" v-model="usu_dat_pers.id_afiliado" :disabled="!isEditing" required><br></td>
          </tr>
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Nombre Completo: </label></td>
            <td><input id="" class="inp" type="text" v-model="usu_dat_pers.nombre" :disabled="!isEditing" required><br></td>
          </tr>
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Fecha de Nacimiento: </label></td>
            <td><input id="" class="inp" type="date" v-model="usu_dat_pers.fecha_nac" :disabled="!isEditing" required><br></td> <!-- value="2024-08-24" -->
          </tr>
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Edad: </label></td>
            <td><input id="" class="inp" type="number" min="14" max="90" v-model="usu_dat_pers.edad" :disabled="!isEditing"><br></td>
          </tr>
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Sexo Asignado al Nacer: </label></td>
            <td>
              <select id="sel1" class="inp" v-model="usu_dat_pers.sexo" :disabled="!isEditing" required>
                <option value="" selected>Seleccione una opción</option>                            
                <option value="Hombre">Hombre</option>
                <option value="Mujer">Mujer</option>
              </select>
            </td>
          </tr>          
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Teléfono: </label></td>
            <td><input id="" class="inp" type="number" v-model="usu_dat_pers.telefono" :disabled="!isEditing" required><br></td>
          </tr>
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Dirección: </label></td>
            <td><input id="" class="inp" type="text" v-model="usu_dat_pers.direccion" :disabled="!isEditing" required><br></td>
          </tr>
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Departamento: </label></td>
            <td>
              <select id="sel2" class="inp" v-model="usu_dat_pers.depto" :disabled="!isEditing" required>
                <option value="" selected>Seleccione una opción</option>                            
                <option  v-for="(depto, index) in departamentos" :key="index" :value="depto.nombre">{{depto.nombre}}</option>
              </select>
            </td>
          </tr>
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Municipio: </label></td>
            <td>
              <select id="sel4" class="inp" v-model="usu_dat_pers.municipio" :disabled="!isEditing" required>
                <option value="" selected>Seleccione una opción</option>                            
                <option  v-for="(mncp, index) in municipios" :key="index" :value="mncp.nombre_municipio">{{mncp.nombre_municipio}}</option>
              </select>
            </td>
          </tr>
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Correo Electrónico: </label></td>
            <td><input id="" class="inp" type="email" v-model="usu_dat_pers.email" :disabled="!isEditing"><br></td>
          </tr>
          <!-- <template v-if="usu_dat_pers.rol == 1"> -->
            <tr>
              <td class="celdaizq"><label for="" class="lbl">Usuario: </label></td>
              <td><input id="" class="inp" type="text" v-model="usu_dat_pers.id_afiliado" disabled></td>
            </tr>
          <!-- </template>
          <template v-else>
            <tr>
              <td class="celdaizq"><label for="" class="lbl">Usuario: </label></td>
              <td><input id="" class="inp" type="text" v-model="usu_dat_pers.id_afiliado" disabled></td>
            </tr>
          </template> -->
          <tr>
            <td class="celdaizq"><label for="" class="lbl">Contraseña: </label></td>
            <td>
              <input v-if="verpass==''" id="" class="inp" type="password" v-model="usu_dat_pers.passwd" :disabled="!isEditing" required>
              <input v-else id="" class="inp" type="text" v-model="usu_dat_pers.passwd" :disabled="!isEditing" required>
            </td>
          </tr>
          <tr>
            <td class="celdaizq"><label for="" class="lbl"></label></td>
            <td>
              <input id="smmr1" type="checkbox" name="verpasswd" value="s" v-model="verpass">
              <label for="smmr1"> &nbsp;Mostrar Contraseña </label>
            </td>
          </tr>           
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Subdirectiva: </label></td>
            <td>
              <select id="sel8" class="inp" v-model="usu_dat_pers.subdirectiva" :disabled="!isEditing" required>
                <option value="" selected>Seleccione una opción</option>                            
                <template v-for="(sub, index) in ListaSubdirectivas" :key="index">                            
                  <option :value="sub.nombre_sub">{{sub.nombre_sub}}</option>
                </template>
              </select>
            </td>
          </tr>
          <template v-if="usu_dat_pers.rol == 1">
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Código Interno: </label></td>
              <td><input id="" class="inp" type="text" v-model="datos_afi.cod_interno" :disabled="!isEditing"><br></td>
            </tr>
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Estado Civil: </label></td>
              <td>
                <select id="sel5" class="inp" v-model="datos_afi.est_civil" :disabled="!isEditing">
                  <option value="" selected>Seleccione una opción</option>                            
                  <option value="Soltero(a)">Soltero(a)</option>
                  <option value="Casado(a)">Casado(a)</option>
                  <option value="Unión Libre">Unión Libre</option>                 
                  <option value="Separado(a)">Separado(a)</option>
                  <option value="Divorciado(a)">Divorciado(a)</option>
                </select>
              </td>
            </tr>
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Grupo Sanguíneo: </label></td>
              <td>
                <select id="sel3" class="inp" v-model="datos_afi.grupo_sang" :disabled="!isEditing">
                  <option value="" selected>Seleccione una opción</option>                            
                  <option value="O+">O +</option>
                  <option value="A+">A +</option>
                  <option value="B+">B +</option>                 
                  <option value="AB+">AB +</option>
                  <option value="O-">O -</option>
                  <option value="A-">A -</option>
                  <option value="B-">B -</option>                 
                  <option value="AB-">AB -</option>
                </select>
              </td>
            </tr>
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Empresa: </label></td>
              <td><input id="" class="inp" type="text" v-model="datos_afi.empresa" :disabled="!isEditing"><br></td>
            </tr>
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Fecha de Vinculación: </label></td>
              <td><input id="" class="inp" type="date" v-model="datos_afi.fecha_vinc" :disabled="!isEditing"><br></td>
            </tr>
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Tipo de Contrato: </label></td>
              <td>
                <select id="sel6" class="inp" v-model="datos_afi.tipo_contrato" :disabled="!isEditing">
                  <option value="" selected>Seleccione una opción</option>                            
                  <option value="A Término Indefinido">A Término Indefinido</option>
                  <option value="A Término Fijo">A Término Fijo</option>
                  <option value="Por Obra o Labor">Por Obra o Labor</option>
                  <option value="A Destajo">A Destajo</option>
                  <option value="Por Horas">Por Horas</option>
                  <option value="Prestación de Servicios">Prestación de Servicios</option>
                  <option value="Medio Tiempo">Medio Tiempo</option>
                  <option value="Trabajo Ocasional">Trabajo Ocasional</option>
                  <option value="De Aprendizaje">De Aprendizaje</option> 
                  <option value="Sindical">Sindical</option>                            
                </select>
              </td>
            </tr>
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Cargo: </label></td>
              <td><input id="" class="inp" type="text" v-model="datos_afi.cargo" :disabled="!isEditing"><br></td>
            </tr>
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Área: </label></td>
              <td><input id="" class="inp" type="text" v-model="datos_afi.area" :disabled="!isEditing" required><br></td>
            </tr>
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Departamento donde labora: </label></td>
              <td>
                <select id="sel7" class="inp" v-model="datos_afi.depto_labor" :disabled="!isEditing">
                  <option value="" selected>Seleccione una opción</option>                            
                  <option  v-for="(depto, index) in departamentos" :key="index" :value="depto.nombre">{{depto.nombre}}</option>
                </select>
              </td>
            </tr>
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Municipio donde labora: </label></td>
              <td>
                <select id="sel9" class="inp" v-model="datos_afi.municipio_labor" :disabled="!isEditing">
                  <option value="" selected>Seleccione una opción</option>                            
                  <option  v-for="(mncp, index) in municipios" :key="index" :value="mncp.nombre_municipio">{{mncp.nombre_municipio}}</option>
                </select>
              </td>
            </tr>
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Tiempo de Vinculación (Meses): </label></td>
              <td><input id="" class="inp" type="number" min="1" v-model="datos_afi.tiempo_vinc" :disabled="!isEditing"><br></td>
            </tr>          
            <tr>  
              <td class="celdaizq"><label for="" class="lbl">Empleado Activo? </label></td>
              <td class="celdaizq">
                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <input id="smur1" type="radio" name="empleactivo" value="Si" v-model="datos_afi.empl_activo" :disabled="!isEditing">
                <label for="smur1">&nbsp; Si </label>
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <input id="smur2" type="radio" name="empleactivo" value="No" v-model="datos_afi.empl_activo" :disabled="!isEditing">
                <label for="smur2">&nbsp; No </label><br>
              </td>
            </tr>
            <template v-if="datos_afi.empl_activo == 'No'">
              <tr>  
                <td class="celdaizq"><label for="" class="lbl">Fecha de retiro: </label></td>
                <td><input id="" class="inp" type="date" v-model="datos_afi.fecha_ret" :disabled="!isEditing"><br></td>
              </tr>
              <tr>  
                <td class="celdaizq"><label for="" class="lbl">Motivo del retiro: </label></td>
                <td>
                  <select id="sel10" class="inp" v-model="datos_afi.motivo_ret" :disabled="!isEditing">
                    <option value="" selected>Seleccione una opción</option>
                    <option value="Terminación de Contrato">Terminación de Contrato</option>
                    <option value="Jubilación">Jubilación</option>                            
                    <option value="Despido Justificado">Despido Justificado</option>
                    <option value="Despido No Justificado">Despido No Justificado</option>
                    <option value="Mejor Oportunidad Laboral">Mejor Oportunidad Laboral</option>
                    <option value="Problemas de Salud">Problemas de Salud</option>
                    <option value="Cambio de Actividad o Carrera">Cambio de Actividad o Carrera</option>
                    <option value="Ambiente Laboral Tóxico">Ambiente Laboral Tóxico</option>
                    <option value="Falta de Oportunidades de Crecimiento">Falta de Oportunidades de Crecimiento</option>
                    <option value="Reubicación Geográfica">Reubicación Geográfica</option>
                    <option value="Motivos Familiares">Motivos Familiares</option>                            
                    <option value="Insatisfacción Laboral">Insatisfacción Laboral</option>
                    <option value="Emprendimiento">Emprendimiento</option>
                    <option value="Cambios Organizacionales">Cambios Organizacionales</option>
                    <option value="Condiciones Laborales Inadecuadas">Condiciones Laborales Inadecuadas</option>
                    <option value="Falta de Reconocimiento">Falta de Reconocimiento</option>
                  </select>
                </td>
              </tr>
            </template>
          </template>
          <tr><td> </td></tr>
        </table>
        <br><br>
        <table id="protecdatos"> <!-- Protección de Datos -->
          <tr>  
            <td>
              <p id="p1">El Sindicato Nacional de Trabajadores de la Agroindustria de la Caña de Azúcar, de la azúcar, afines y similares SINTRAICAÑAZUCOL, en consideración a la Ley 1581 de 2012 y el Decreto 1377 de 2013 (“Régimen de Protección de Datos Personales”), se compromete a mantener en absoluta confidencialidad y bajo las medidas de protección y seguridad necesarias la información de las bases de datos suministrados por usted como Afiliado a cualquiera de nuestras subdirectivas.<br>
              El Sindicato se compromete a no ceder, comercializar o transferir a terceros información personal que haya sido obtenida mediante el presente forrmulario o cualquier otro proveído por la organización para fines legales, administrativos, estatutarios, contractuales o de uso en los programas desarrollados por las diferentes secretarías del Sindicato.</p>
            </td>
          </tr>
          <tr>  
            <td><label class="lbl">Autoriza la recopilación y tratamiento de sus datos?</label>
                <br>
                <input id="smur15" type="radio" name="tratamiento" value="Si" v-model="trata_datos.autorizacion" :disabled="!isEditing">
                <label for="smur15">&nbsp; Si </label>
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <input id="smur16" type="radio" name="tratamiento" value="No" v-model="trata_datos.autorizacion" :disabled="!isEditing">
                <label for="smur16">&nbsp; No </label><br>
            </td>
          </tr>
        </table>        
        <button v-if="isEditing" id="" class="boton" type="submit">INGRESAR</button>
        <label id="lblbtn"> </label>
        <button id="" class="boton" type="button" @click="limpiarCampos(); recargarComponente();">CANCELAR</button>
        <label id="lblbtn"> </label>
        <button id="" class="boton" type="button" @click="retornar">CERRAR</button>
      </form>
    </section>
  </div>
</template>

<script>
import store from '../store';
import axios from 'axios'; 
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex'; 
import { cnxsrv } from '../config';

export default {
  name: 'CrearUsuario',

  setup(){

    let renderizado = ref(0);
    let isEditing = ref(true);
    let verpass = ref('');

    let Usuario = computed(() => store.state.Usuario);

    const departamentos = ref([    
      { codigo: 5, nombre: 'ANTIOQUIA' },
      { codigo: 8, nombre: 'ATLÁNTICO' },
      { codigo: 11, nombre: 'BOGOTÁ, D.C.' },
      { codigo: 13, nombre: 'BOLÍVAR' },
      { codigo: 15, nombre: 'BOYACÁ' },
      { codigo: 17, nombre: 'CALDAS' },
      { codigo: 18, nombre: 'CAQUETÁ' },
      { codigo: 19, nombre: 'CAUCA' },
      { codigo: 20, nombre: 'CESAR' },
      { codigo: 23, nombre: 'CÓRDOBA' },
      { codigo: 25, nombre: 'CUNDINAMARCA' },
      { codigo: 27, nombre: 'CHOCÓ' },
      { codigo: 41, nombre: 'HUILA' },
      { codigo: 44, nombre: 'LA GUAJIRA' },
      { codigo: 47, nombre: 'MAGDALENA' },
      { codigo: 50, nombre: 'META' },
      { codigo: 52, nombre: 'NARIÑO' },
      { codigo: 54, nombre: 'NORTE DE SANTANDER' },
      { codigo: 63, nombre: 'QUINDÍO' },
      { codigo: 66, nombre: 'RISARALDA' },
      { codigo: 68, nombre: 'SANTANDER' },
      { codigo: 70, nombre: 'SUCRE' },
      { codigo: 73, nombre: 'TOLIMA' },
      { codigo: 76, nombre: 'VALLE DEL CAUCA' },
      { codigo: 81, nombre: 'ARAUCA' },
      { codigo: 85, nombre: 'CASANARE' },
      { codigo: 86, nombre: 'PUTUMAYO' },
      { codigo: 88, nombre: 'ARCHIPIÉLAGO DE SAN ANDRÉS' },
      { codigo: 91, nombre: 'AMAZONAS' },
      { codigo: 94, nombre: 'GUAINÍA' },
      { codigo: 95, nombre: 'GUAVIARE' },
      { codigo: 97, nombre: 'VAUPÉS' },
      { codigo: 99, nombre: 'VICHADA' },
    ]);
    
    const municipios = ref([    
      // Municipios de Antioquia
      { codigo_municipio: '05001', codigo_departamento: 5, nombre_municipio: 'MEDELLÍN' },
      { codigo_municipio: '05002', codigo_departamento: 5, nombre_municipio: 'ABEJORRAL' },
      { codigo_municipio: '05004', codigo_departamento: 5, nombre_municipio: 'ABRIAQUÍ' },
      { codigo_municipio: '05021', codigo_departamento: 5, nombre_municipio: 'ALEJANDRÍA' },
      { codigo_municipio: '05030', codigo_departamento: 5, nombre_municipio: 'AMAGÁ' },
      { codigo_municipio: '05031', codigo_departamento: 5, nombre_municipio: 'AMALFI' },
      { codigo_municipio: '05034', codigo_departamento: 5, nombre_municipio: 'ANDES' },
      { codigo_municipio: '05036', codigo_departamento: 5, nombre_municipio: 'ANGELÓPOLIS' },
      { codigo_municipio: '05038', codigo_departamento: 5, nombre_municipio: 'ANGOSTURA' },
      { codigo_municipio: '05040', codigo_departamento: 5, nombre_municipio: 'ANORÍ' },
      { codigo_municipio: '05042', codigo_departamento: 5, nombre_municipio: 'SANTAFÉ DE ANTIOQUIA' },
      { codigo_municipio: '05044', codigo_departamento: 5, nombre_municipio: 'ANZA' },
      { codigo_municipio: '05045', codigo_departamento: 5, nombre_municipio: 'APARTADÓ' },
      { codigo_municipio: '05051', codigo_departamento: 5, nombre_municipio: 'ARBOLETES' },
      { codigo_municipio: '05055', codigo_departamento: 5, nombre_municipio: 'ARGELIA' },
      { codigo_municipio: '05059', codigo_departamento: 5, nombre_municipio: 'ARMENIA' },
      { codigo_municipio: '05079', codigo_departamento: 5, nombre_municipio: 'BARBOSA' },
      { codigo_municipio: '05086', codigo_departamento: 5, nombre_municipio: 'BELMIRA' },
      { codigo_municipio: '05088', codigo_departamento: 5, nombre_municipio: 'BELLO' },
      { codigo_municipio: '05091', codigo_departamento: 5, nombre_municipio: 'BETANIA' },
      { codigo_municipio: '05093', codigo_departamento: 5, nombre_municipio: 'BETULIA' },
      { codigo_municipio: '05101', codigo_departamento: 5, nombre_municipio: 'CIUDAD BOLÍVAR' },
      { codigo_municipio: '05107', codigo_departamento: 5, nombre_municipio: 'BRICEÑO' },
      { codigo_municipio: '05113', codigo_departamento: 5, nombre_municipio: 'BURITICÁ' },
      { codigo_municipio: '05120', codigo_departamento: 5, nombre_municipio: 'CÁCERES' },
      { codigo_municipio: '05125', codigo_departamento: 5, nombre_municipio: 'CAICEDO' },
      { codigo_municipio: '05129', codigo_departamento: 5, nombre_municipio: 'CALDAS' },
      { codigo_municipio: '05134', codigo_departamento: 5, nombre_municipio: 'CAMPAMENTO' },
      { codigo_municipio: '05138', codigo_departamento: 5, nombre_municipio: 'CAÑASGORDAS' },
      { codigo_municipio: '05142', codigo_departamento: 5, nombre_municipio: 'CARACOLÍ' },
      { codigo_municipio: '05145', codigo_departamento: 5, nombre_municipio: 'CARAMANTA' },
      { codigo_municipio: '05147', codigo_departamento: 5, nombre_municipio: 'CAREPA' },
      { codigo_municipio: '05150', codigo_departamento: 5, nombre_municipio: 'CAROLINA' },
      { codigo_municipio: '05154', codigo_departamento: 5, nombre_municipio: 'CAUCASIA' },
      { codigo_municipio: '05172', codigo_departamento: 5, nombre_municipio: 'CHIGORODÓ' },
      { codigo_municipio: '05190', codigo_departamento: 5, nombre_municipio: 'CISNEROS' },
      { codigo_municipio: '05197', codigo_departamento: 5, nombre_municipio: 'COCORNÁ' },
      { codigo_municipio: '05206', codigo_departamento: 5, nombre_municipio: 'CONCEPCIÓN' },
      { codigo_municipio: '05209', codigo_departamento: 5, nombre_municipio: 'CONCORDIA' },
      { codigo_municipio: '05212', codigo_departamento: 5, nombre_municipio: 'COPACABANA' },
      { codigo_municipio: '05234', codigo_departamento: 5, nombre_municipio: 'DABEIBA' },
      { codigo_municipio: '05237', codigo_departamento: 5, nombre_municipio: 'DONMATÍAS' },
      { codigo_municipio: '05240', codigo_departamento: 5, nombre_municipio: 'EBÉJICO' },
      { codigo_municipio: '05250', codigo_departamento: 5, nombre_municipio: 'EL BAGRE' },
      { codigo_municipio: '05264', codigo_departamento: 5, nombre_municipio: 'ENTRERRÍOS' },
      { codigo_municipio: '05266', codigo_departamento: 5, nombre_municipio: 'ENVIGADO' },
      { codigo_municipio: '05282', codigo_departamento: 5, nombre_municipio: 'FREDONIA' },
      { codigo_municipio: '05284', codigo_departamento: 5, nombre_municipio: 'FRONTINO' },
      { codigo_municipio: '05306', codigo_departamento: 5, nombre_municipio: 'GIRALDO' },
      { codigo_municipio: '05308', codigo_departamento: 5, nombre_municipio: 'GIRARDOTA' },
      { codigo_municipio: '05310', codigo_departamento: 5, nombre_municipio: 'GÓMEZ PLATA' },
      { codigo_municipio: '05313', codigo_departamento: 5, nombre_municipio: 'GRANADA' },
      { codigo_municipio: '05315', codigo_departamento: 5, nombre_municipio: 'GUADALUPE' },
      { codigo_municipio: '05318', codigo_departamento: 5, nombre_municipio: 'GUARNE' },
      { codigo_municipio: '05321', codigo_departamento: 5, nombre_municipio: 'GUATAPÉ' },
      { codigo_municipio: '05347', codigo_departamento: 5, nombre_municipio: 'HELICONIA' },
      { codigo_municipio: '05353', codigo_departamento: 5, nombre_municipio: 'HISPANIA' },
      { codigo_municipio: '05360', codigo_departamento: 5, nombre_municipio: 'ITAGÜÍ' },
      { codigo_municipio: '05361', codigo_departamento: 5, nombre_municipio: 'ITUANGO' },
      { codigo_municipio: '05364', codigo_departamento: 5, nombre_municipio: 'JARDÍN' },
      { codigo_municipio: '05368', codigo_departamento: 5, nombre_municipio: 'JERICÓ' },
      { codigo_municipio: '05376', codigo_departamento: 5, nombre_municipio: 'LA CEJA' },
      { codigo_municipio: '05380', codigo_departamento: 5, nombre_municipio: 'LA ESTRELLA' },
      { codigo_municipio: '05390', codigo_departamento: 5, nombre_municipio: 'LA PINTADA' },
      { codigo_municipio: '05400', codigo_departamento: 5, nombre_municipio: 'LA UNIÓN' },
      { codigo_municipio: '05411', codigo_departamento: 5, nombre_municipio: 'LIBORINA' },
      { codigo_municipio: '05425', codigo_departamento: 5, nombre_municipio: 'MACEO' },
      { codigo_municipio: '05440', codigo_departamento: 5, nombre_municipio: 'MARINILLA' },
      { codigo_municipio: '05467', codigo_departamento: 5, nombre_municipio: 'MONTEBELLO' },
      { codigo_municipio: '05475', codigo_departamento: 5, nombre_municipio: 'MURINDÓ' },
      { codigo_municipio: '05480', codigo_departamento: 5, nombre_municipio: 'MUTATÁ' },
      { codigo_municipio: '05483', codigo_departamento: 5, nombre_municipio: 'NARIÑO' },
      { codigo_municipio: '05490', codigo_departamento: 5, nombre_municipio: 'NECOCLÍ' },
      { codigo_municipio: '05495', codigo_departamento: 5, nombre_municipio: 'NECHÍ' },
      { codigo_municipio: '05501', codigo_departamento: 5, nombre_municipio: 'OLAYA' },
      { codigo_municipio: '05541', codigo_departamento: 5, nombre_municipio: 'PEÑOL' },
      { codigo_municipio: '05543', codigo_departamento: 5, nombre_municipio: 'PEQUE' },
      { codigo_municipio: '05576', codigo_departamento: 5, nombre_municipio: 'PUEBLORRICO' },
      { codigo_municipio: '05579', codigo_departamento: 5, nombre_municipio: 'PUERTO BERRÍO' },
      { codigo_municipio: '05585', codigo_departamento: 5, nombre_municipio: 'PUERTO NARE' },
      { codigo_municipio: '05591', codigo_departamento: 5, nombre_municipio: 'PUERTO TRIUNFO' },
      { codigo_municipio: '05604', codigo_departamento: 5, nombre_municipio: 'REMEDIOS' },
      { codigo_municipio: '05607', codigo_departamento: 5, nombre_municipio: 'RETIRO' },
      { codigo_municipio: '05615', codigo_departamento: 5, nombre_municipio: 'RIONEGRO' },
      { codigo_municipio: '05628', codigo_departamento: 5, nombre_municipio: 'SABANALARGA' },
      { codigo_municipio: '05631', codigo_departamento: 5, nombre_municipio: 'SABANETA' },
      { codigo_municipio: '05642', codigo_departamento: 5, nombre_municipio: 'SALGAR' },
      { codigo_municipio: '05647', codigo_departamento: 5, nombre_municipio: 'SAN ANDRÉS' },
      { codigo_municipio: '05649', codigo_departamento: 5, nombre_municipio: 'SAN CARLOS' },
      { codigo_municipio: '05652', codigo_departamento: 5, nombre_municipio: 'SAN FRANCISCO' },
      { codigo_municipio: '05656', codigo_departamento: 5, nombre_municipio: 'SAN JERÓNIMO' },
      { codigo_municipio: '05658', codigo_departamento: 5, nombre_municipio: 'SAN JOSÉ DE LA MONTAÑA' },
      { codigo_municipio: '05659', codigo_departamento: 5, nombre_municipio: 'SAN JUAN DE URABÁ' },
      { codigo_municipio: '05660', codigo_departamento: 5, nombre_municipio: 'SAN LUIS' },
      { codigo_municipio: '05664', codigo_departamento: 5, nombre_municipio: 'SAN PEDRO' },
      { codigo_municipio: '05665', codigo_departamento: 5, nombre_municipio: 'SAN PEDRO DE URABÁ' },
      { codigo_municipio: '05667', codigo_departamento: 5, nombre_municipio: 'SAN RAFAEL' },
      { codigo_municipio: '05670', codigo_departamento: 5, nombre_municipio: 'SAN ROQUE' },
      { codigo_municipio: '05674', codigo_departamento: 5, nombre_municipio: 'SAN VICENTE' },
      { codigo_municipio: '05679', codigo_departamento: 5, nombre_municipio: 'SANTA BÁRBARA' },
      { codigo_municipio: '05686', codigo_departamento: 5, nombre_municipio: 'SANTA ROSA DE OSOS' },
      { codigo_municipio: '05690', codigo_departamento: 5, nombre_municipio: 'SANTO DOMINGO' },
      { codigo_municipio: '05697', codigo_departamento: 5, nombre_municipio: 'EL SANTUARIO' },
      { codigo_municipio: '05736', codigo_departamento: 5, nombre_municipio: 'SEGOVIA' },
      { codigo_municipio: '05756', codigo_departamento: 5, nombre_municipio: 'SONSÓN' },
      { codigo_municipio: '05761', codigo_departamento: 5, nombre_municipio: 'SOPETRÁN' },
      { codigo_municipio: '05789', codigo_departamento: 5, nombre_municipio: 'TÁMESIS' },
      { codigo_municipio: '05790', codigo_departamento: 5, nombre_municipio: 'TARAZÁ' },
      { codigo_municipio: '05792', codigo_departamento: 5, nombre_municipio: 'TARSO' },
      { codigo_municipio: '05809', codigo_departamento: 5, nombre_municipio: 'TITIRIBÍ' },
      { codigo_municipio: '05819', codigo_departamento: 5, nombre_municipio: 'TOLEDO' },
      { codigo_municipio: '05837', codigo_departamento: 5, nombre_municipio: 'TURBO' },
      { codigo_municipio: '05842', codigo_departamento: 5, nombre_municipio: 'URAMITA' },
      { codigo_municipio: '05847', codigo_departamento: 5, nombre_municipio: 'URRAO' },
      { codigo_municipio: '05854', codigo_departamento: 5, nombre_municipio: 'VALDIVIA' },
      { codigo_municipio: '05856', codigo_departamento: 5, nombre_municipio: 'VALPARAÍSO' },
      { codigo_municipio: '05858', codigo_departamento: 5, nombre_municipio: 'VEGACHÍ' },
      { codigo_municipio: '05861', codigo_departamento: 5, nombre_municipio: 'VENECIA' },
      { codigo_municipio: '05873', codigo_departamento: 5, nombre_municipio: 'VIGÍA DEL FUERTE' },
      { codigo_municipio: '05885', codigo_departamento: 5, nombre_municipio: 'YALÍ' },
      { codigo_municipio: '05887', codigo_departamento: 5, nombre_municipio: 'YARUMAL' },
      { codigo_municipio: '05890', codigo_departamento: 5, nombre_municipio: 'YOLOMBÓ' },
      { codigo_municipio: '05893', codigo_departamento: 5, nombre_municipio: 'YONDÓ' },
      { codigo_municipio: '05895', codigo_departamento: 5, nombre_municipio: 'ZARAGOZA' },

      // Municipios de Atlántico
      { codigo_municipio: '08001', codigo_departamento: 8, nombre_municipio: 'BARRANQUILLA' },
      { codigo_municipio: '08078', codigo_departamento: 8, nombre_municipio: 'BARANOA' },
      { codigo_municipio: '08137', codigo_departamento: 8, nombre_municipio: 'CAMPO DE LA CRUZ' },
      { codigo_municipio: '08141', codigo_departamento: 8, nombre_municipio: 'CANDELARIA' },
      { codigo_municipio: '08296', codigo_departamento: 8, nombre_municipio: 'GALAPA' },
      { codigo_municipio: '08372', codigo_departamento: 8, nombre_municipio: 'JUAN DE ACOSTA' },
      { codigo_municipio: '08421', codigo_departamento: 8, nombre_municipio: 'LURUACO' },
      { codigo_municipio: '08433', codigo_departamento: 8, nombre_municipio: 'MALAMBO' },
      { codigo_municipio: '08436', codigo_departamento: 8, nombre_municipio: 'MANATÍ' },
      { codigo_municipio: '08520', codigo_departamento: 8, nombre_municipio: 'PALMAR DE VARELA' },
      { codigo_municipio: '08549', codigo_departamento: 8, nombre_municipio: 'PIOJÓ' },
      { codigo_municipio: '08558', codigo_departamento: 8, nombre_municipio: 'POLONUEVO' },
      { codigo_municipio: '08560', codigo_departamento: 8, nombre_municipio: 'PONEDERA' },
      { codigo_municipio: '08573', codigo_departamento: 8, nombre_municipio: 'PUERTO COLOMBIA' },
      { codigo_municipio: '08606', codigo_departamento: 8, nombre_municipio: 'REPELÓN' },
      { codigo_municipio: '08634', codigo_departamento: 8, nombre_municipio: 'SABANAGRANDE' },
      { codigo_municipio: '08638', codigo_departamento: 8, nombre_municipio: 'SABANALARGA' },
      { codigo_municipio: '08675', codigo_departamento: 8, nombre_municipio: 'SANTA LUCÍA' },
      { codigo_municipio: '08685', codigo_departamento: 8, nombre_municipio: 'SANTO TOMÁS' },
      { codigo_municipio: '08758', codigo_departamento: 8, nombre_municipio: 'SOLEDAD' },
      { codigo_municipio: '08770', codigo_departamento: 8, nombre_municipio: 'SUAN' },
      { codigo_municipio: '08832', codigo_departamento: 8, nombre_municipio: 'TUBARÁ' },
      { codigo_municipio: '08849', codigo_departamento: 8, nombre_municipio: 'USIACURÍ' },
      
      // Municipios de Bogotá, D.C.
      { codigo_municipio: '11001', codigo_departamento: 11, nombre_municipio: 'BOGOTÁ, D.C.' },
      
      // Municipios de Bolívar
      { codigo_municipio: '13001', codigo_departamento: 13, nombre_municipio: 'CARTAGENA' },
      { codigo_municipio: '13006', codigo_departamento: 13, nombre_municipio: 'ACHÍ' },
      { codigo_municipio: '13030', codigo_departamento: 13, nombre_municipio: 'ALTOS DEL ROSARIO' },
      { codigo_municipio: '13042', codigo_departamento: 13, nombre_municipio: 'ARENAL' },
      { codigo_municipio: '13052', codigo_departamento: 13, nombre_municipio: 'ARJONA' },
      { codigo_municipio: '13062', codigo_departamento: 13, nombre_municipio: 'ARROYOHONDO' },
      { codigo_municipio: '13074', codigo_departamento: 13, nombre_municipio: 'BARRANCO DE LOBA' },
      { codigo_municipio: '13140', codigo_departamento: 13, nombre_municipio: 'CALAMAR' },
      { codigo_municipio: '13160', codigo_departamento: 13, nombre_municipio: 'CANTAGALLO' },
      { codigo_municipio: '13188', codigo_departamento: 13, nombre_municipio: 'CICUCO' },
      { codigo_municipio: '13212', codigo_departamento: 13, nombre_municipio: 'CÓRDOBA' },
      { codigo_municipio: '13222', codigo_departamento: 13, nombre_municipio: 'CLEMENCIA' },
      { codigo_municipio: '13244', codigo_departamento: 13, nombre_municipio: 'EL CARMEN DE BOLÍVAR' },
      { codigo_municipio: '13248', codigo_departamento: 13, nombre_municipio: 'EL GUAMO' },
      { codigo_municipio: '13268', codigo_departamento: 13, nombre_municipio: 'EL PEÑÓN' },
      { codigo_municipio: '13300', codigo_departamento: 13, nombre_municipio: 'HATILLO DE LOBA' },
      { codigo_municipio: '13430', codigo_departamento: 13, nombre_municipio: 'MAGANGUÉ' },
      { codigo_municipio: '13433', codigo_departamento: 13, nombre_municipio: 'MAHATES' },
      { codigo_municipio: '13440', codigo_departamento: 13, nombre_municipio: 'MARGARITA' },
      { codigo_municipio: '13442', codigo_departamento: 13, nombre_municipio: 'MARÍA LA BAJA' },
      { codigo_municipio: '13458', codigo_departamento: 13, nombre_municipio: 'MONTECRISTO' },
      { codigo_municipio: '13468', codigo_departamento: 13, nombre_municipio: 'MOMPÓS' },
      { codigo_municipio: '13473', codigo_departamento: 13, nombre_municipio: 'MORALES' },
      { codigo_municipio: '13490', codigo_departamento: 13, nombre_municipio: 'NOROSÍ' },
      { codigo_municipio: '13549', codigo_departamento: 13, nombre_municipio: 'PINILLOS' },
      { codigo_municipio: '13580', codigo_departamento: 13, nombre_municipio: 'REGIDOR' },
      { codigo_municipio: '13600', codigo_departamento: 13, nombre_municipio: 'RÍO VIEJO' },
      { codigo_municipio: '13620', codigo_departamento: 13, nombre_municipio: 'SAN CRISTÓBAL' },
      { codigo_municipio: '13647', codigo_departamento: 13, nombre_municipio: 'SAN ESTANISLAO' },
      { codigo_municipio: '13650', codigo_departamento: 13, nombre_municipio: 'SAN FERNANDO' },
      { codigo_municipio: '13654', codigo_departamento: 13, nombre_municipio: 'SAN JACINTO' },
      { codigo_municipio: '13655', codigo_departamento: 13, nombre_municipio: 'SAN JACINTO DEL CAUCA' },
      { codigo_municipio: '13657', codigo_departamento: 13, nombre_municipio: 'SAN JUAN NEPOMUCENO' },
      { codigo_municipio: '13667', codigo_departamento: 13, nombre_municipio: 'SAN MARTÍN DE LOBA' },
      { codigo_municipio: '13670', codigo_departamento: 13, nombre_municipio: 'SAN PABLO' },
      { codigo_municipio: '13673', codigo_departamento: 13, nombre_municipio: 'SANTA CATALINA' },
      { codigo_municipio: '13683', codigo_departamento: 13, nombre_municipio: 'SANTA ROSA' },
      { codigo_municipio: '13688', codigo_departamento: 13, nombre_municipio: 'SANTA ROSA DEL SUR' },
      { codigo_municipio: '13744', codigo_departamento: 13, nombre_municipio: 'SIMITÍ' },
      { codigo_municipio: '13760', codigo_departamento: 13, nombre_municipio: 'SOPLAVIENTO' },
      { codigo_municipio: '13780', codigo_departamento: 13, nombre_municipio: 'TALAIGUA NUEVO' },
      { codigo_municipio: '13810', codigo_departamento: 13, nombre_municipio: 'TIQUISIO' },
      { codigo_municipio: '13836', codigo_departamento: 13, nombre_municipio: 'TURBACO' },
      { codigo_municipio: '13838', codigo_departamento: 13, nombre_municipio: 'TURBANÁ' },
      { codigo_municipio: '13873', codigo_departamento: 13, nombre_municipio: 'VILLANUEVA' },
      { codigo_municipio: '13894', codigo_departamento: 13, nombre_municipio: 'ZAMBRANO' },
      
      // Municipios de Boyacá
      { codigo_municipio: '15001', codigo_departamento: 15, nombre_municipio: 'TUNJA' },
      { codigo_municipio: '15022', codigo_departamento: 15, nombre_municipio: 'ALMEIDA' },
      { codigo_municipio: '15047', codigo_departamento: 15, nombre_municipio: 'AQUITANIA' },
      { codigo_municipio: '15051', codigo_departamento: 15, nombre_municipio: 'ARCABUCO' },
      { codigo_municipio: '15087', codigo_departamento: 15, nombre_municipio: 'BELÉN' },
      { codigo_municipio: '15090', codigo_departamento: 15, nombre_municipio: 'BERBEO' },
      { codigo_municipio: '15092', codigo_departamento: 15, nombre_municipio: 'BETÉITIVA' },
      { codigo_municipio: '15097', codigo_departamento: 15, nombre_municipio: 'BOAVITA' },
      { codigo_municipio: '15104', codigo_departamento: 15, nombre_municipio: 'BOYACÁ' },
      { codigo_municipio: '15106', codigo_departamento: 15, nombre_municipio: 'BRICEÑO' },
      { codigo_municipio: '15109', codigo_departamento: 15, nombre_municipio: 'BUENAVISTA' },
      { codigo_municipio: '15114', codigo_departamento: 15, nombre_municipio: 'BUSBANZÁ' },
      { codigo_municipio: '15131', codigo_departamento: 15, nombre_municipio: 'CALDAS' },
      { codigo_municipio: '15135', codigo_departamento: 15, nombre_municipio: 'CAMPOHERMOSO' },
      { codigo_municipio: '15162', codigo_departamento: 15, nombre_municipio: 'CERINZA' },
      { codigo_municipio: '15172', codigo_departamento: 15, nombre_municipio: 'CHINAVITA' },
      { codigo_municipio: '15176', codigo_departamento: 15, nombre_municipio: 'CHIQUINQUIRÁ' },
      { codigo_municipio: '15180', codigo_departamento: 15, nombre_municipio: 'CHISCAS' },
      { codigo_municipio: '15183', codigo_departamento: 15, nombre_municipio: 'CHITA' },
      { codigo_municipio: '15185', codigo_departamento: 15, nombre_municipio: 'CHITARAQUE' },
      { codigo_municipio: '15187', codigo_departamento: 15, nombre_municipio: 'CHIVATÁ' },
      { codigo_municipio: '15189', codigo_departamento: 15, nombre_municipio: 'CIÉNEGA' },
      { codigo_municipio: '15204', codigo_departamento: 15, nombre_municipio: 'CÓMBITA' },
      { codigo_municipio: '15212', codigo_departamento: 15, nombre_municipio: 'COPER' },
      { codigo_municipio: '15215', codigo_departamento: 15, nombre_municipio: 'CORRALES' },
      { codigo_municipio: '15218', codigo_departamento: 15, nombre_municipio: 'COVARACHÍA' },
      { codigo_municipio: '15223', codigo_departamento: 15, nombre_municipio: 'CUBARÁ' },
      { codigo_municipio: '15224', codigo_departamento: 15, nombre_municipio: 'CUCAITA' },
      { codigo_municipio: '15226', codigo_departamento: 15, nombre_municipio: 'CUÍTIVA' },
      { codigo_municipio: '15232', codigo_departamento: 15, nombre_municipio: 'CHÍQUIZA' },
      { codigo_municipio: '15236', codigo_departamento: 15, nombre_municipio: 'CHIVOR' },
      { codigo_municipio: '15238', codigo_departamento: 15, nombre_municipio: 'DUITAMA' },
      { codigo_municipio: '15244', codigo_departamento: 15, nombre_municipio: 'EL COCUY' },
      { codigo_municipio: '15248', codigo_departamento: 15, nombre_municipio: 'EL ESPINO' },
      { codigo_municipio: '15272', codigo_departamento: 15, nombre_municipio: 'FIRAVITOBA' },
      { codigo_municipio: '15276', codigo_departamento: 15, nombre_municipio: 'FLORESTA' },
      { codigo_municipio: '15293', codigo_departamento: 15, nombre_municipio: 'GACHANTIVÁ' },
      { codigo_municipio: '15296', codigo_departamento: 15, nombre_municipio: 'GÁMEZA' },
      { codigo_municipio: '15299', codigo_departamento: 15, nombre_municipio: 'GARAGOA' },
      { codigo_municipio: '15317', codigo_departamento: 15, nombre_municipio: 'GUACAMAYAS' },
      { codigo_municipio: '15322', codigo_departamento: 15, nombre_municipio: 'GUATEQUE' },
      { codigo_municipio: '15325', codigo_departamento: 15, nombre_municipio: 'GUAYATÁ' },
      { codigo_municipio: '15332', codigo_departamento: 15, nombre_municipio: 'GÜICÁN DE LA SIERRA' },
      { codigo_municipio: '15362', codigo_departamento: 15, nombre_municipio: 'IZA' },
      { codigo_municipio: '15367', codigo_departamento: 15, nombre_municipio: 'JENESANO' },
      { codigo_municipio: '15368', codigo_departamento: 15, nombre_municipio: 'JERICÓ' },
      { codigo_municipio: '15377', codigo_departamento: 15, nombre_municipio: 'LABRANZAGRANDE' },
      { codigo_municipio: '15380', codigo_departamento: 15, nombre_municipio: 'LA CAPILLA' },
      { codigo_municipio: '15401', codigo_departamento: 15, nombre_municipio: 'LA VICTORIA' },
      { codigo_municipio: '15403', codigo_departamento: 15, nombre_municipio: 'LA UVITA' },
      { codigo_municipio: '15407', codigo_departamento: 15, nombre_municipio: 'VILLA DE LEYVA' },
      { codigo_municipio: '15425', codigo_departamento: 15, nombre_municipio: 'MACANAL' },
      { codigo_municipio: '15442', codigo_departamento: 15, nombre_municipio: 'MARIPÍ' },
      { codigo_municipio: '15455', codigo_departamento: 15, nombre_municipio: 'MIRAFLORES' },
      { codigo_municipio: '15464', codigo_departamento: 15, nombre_municipio: 'MONGUA' },
      { codigo_municipio: '15466', codigo_departamento: 15, nombre_municipio: 'MONGUÍ' },
      { codigo_municipio: '15469', codigo_departamento: 15, nombre_municipio: 'MONIQUIRÁ' },
      { codigo_municipio: '15476', codigo_departamento: 15, nombre_municipio: 'MOTAVITA' },
      { codigo_municipio: '15480', codigo_departamento: 15, nombre_municipio: 'MUZO' },
      { codigo_municipio: '15491', codigo_departamento: 15, nombre_municipio: 'NOBSA' },
      { codigo_municipio: '15494', codigo_departamento: 15, nombre_municipio: 'NUEVO COLÓN' },
      { codigo_municipio: '15500', codigo_departamento: 15, nombre_municipio: 'OICATÁ' },
      { codigo_municipio: '15507', codigo_departamento: 15, nombre_municipio: 'OTANCHE' },
      { codigo_municipio: '15511', codigo_departamento: 15, nombre_municipio: 'PACHAVITA' },
      { codigo_municipio: '15514', codigo_departamento: 15, nombre_municipio: 'PÁEZ' },
      { codigo_municipio: '15516', codigo_departamento: 15, nombre_municipio: 'PAIPA' },
      { codigo_municipio: '15518', codigo_departamento: 15, nombre_municipio: 'PAJARITO' },
      { codigo_municipio: '15522', codigo_departamento: 15, nombre_municipio: 'PANQUEBA' },
      { codigo_municipio: '15531', codigo_departamento: 15, nombre_municipio: 'PAUNA' },
      { codigo_municipio: '15533', codigo_departamento: 15, nombre_municipio: 'PAYA' },
      { codigo_municipio: '15537', codigo_departamento: 15, nombre_municipio: 'PAZ DE RÍO' },
      { codigo_municipio: '15542', codigo_departamento: 15, nombre_municipio: 'PESCA' },
      { codigo_municipio: '15550', codigo_departamento: 15, nombre_municipio: 'PISBA' },
      { codigo_municipio: '15572', codigo_departamento: 15, nombre_municipio: 'PUERTO BOYACÁ' },
      { codigo_municipio: '15580', codigo_departamento: 15, nombre_municipio: 'QUÍPAMA' },
      { codigo_municipio: '15599', codigo_departamento: 15, nombre_municipio: 'RAMIRIQUÍ' },
      { codigo_municipio: '15600', codigo_departamento: 15, nombre_municipio: 'RÁQUIRA' },
      { codigo_municipio: '15621', codigo_departamento: 15, nombre_municipio: 'RONDÓN' },
      { codigo_municipio: '15632', codigo_departamento: 15, nombre_municipio: 'SABOYÁ' },
      { codigo_municipio: '15638', codigo_departamento: 15, nombre_municipio: 'SÁCHICA' },
      { codigo_municipio: '15646', codigo_departamento: 15, nombre_municipio: 'SAMACÁ' },
      { codigo_municipio: '15660', codigo_departamento: 15, nombre_municipio: 'SAN EDUARDO' },
      { codigo_municipio: '15664', codigo_departamento: 15, nombre_municipio: 'SAN JOSÉ DE PARE' },
      { codigo_municipio: '15667', codigo_departamento: 15, nombre_municipio: 'SAN LUIS DE GACENO' },
      { codigo_municipio: '15673', codigo_departamento: 15, nombre_municipio: 'SAN MATEO' },
      { codigo_municipio: '15676', codigo_departamento: 15, nombre_municipio: 'SAN MIGUEL DE SEMA' },
      { codigo_municipio: '15681', codigo_departamento: 15, nombre_municipio: 'SAN PABLO DE BORBUR' },
      { codigo_municipio: '15686', codigo_departamento: 15, nombre_municipio: 'SANTANA' },
      { codigo_municipio: '15690', codigo_departamento: 15, nombre_municipio: 'SANTA MARÍA' },
      { codigo_municipio: '15693', codigo_departamento: 15, nombre_municipio: 'SANTA ROSA DE VITERBO' },
      { codigo_municipio: '15696', codigo_departamento: 15, nombre_municipio: 'SANTA SOFÍA' },
      { codigo_municipio: '15720', codigo_departamento: 15, nombre_municipio: 'SATIVANORTE' },
      { codigo_municipio: '15723', codigo_departamento: 15, nombre_municipio: 'SATIVASUR' },
      { codigo_municipio: '15740', codigo_departamento: 15, nombre_municipio: 'SIACHOQUE' },
      { codigo_municipio: '15753', codigo_departamento: 15, nombre_municipio: 'SOATÁ' },
      { codigo_municipio: '15755', codigo_departamento: 15, nombre_municipio: 'SOCOTÁ' },
      { codigo_municipio: '15757', codigo_departamento: 15, nombre_municipio: 'SOCHA' },
      { codigo_municipio: '15759', codigo_departamento: 15, nombre_municipio: 'SOGAMOSO' },
      { codigo_municipio: '15761', codigo_departamento: 15, nombre_municipio: 'SOMONDOCO' },
      { codigo_municipio: '15762', codigo_departamento: 15, nombre_municipio: 'SORA' },
      { codigo_municipio: '15763', codigo_departamento: 15, nombre_municipio: 'SOTAQUIRÁ' },
      { codigo_municipio: '15764', codigo_departamento: 15, nombre_municipio: 'SORACÁ' },
      { codigo_municipio: '15774', codigo_departamento: 15, nombre_municipio: 'SUSACÓN' },
      { codigo_municipio: '15776', codigo_departamento: 15, nombre_municipio: 'SUTAMARCHÁN' },
      { codigo_municipio: '15778', codigo_departamento: 15, nombre_municipio: 'SUTATENZA' },
      { codigo_municipio: '15790', codigo_departamento: 15, nombre_municipio: 'TASCO' },
      { codigo_municipio: '15798', codigo_departamento: 15, nombre_municipio: 'TENZA' },
      { codigo_municipio: '15804', codigo_departamento: 15, nombre_municipio: 'TIBANÁ' },
      { codigo_municipio: '15806', codigo_departamento: 15, nombre_municipio: 'TIBASOSA' },
      { codigo_municipio: '15808', codigo_departamento: 15, nombre_municipio: 'TINJACÁ' },
      { codigo_municipio: '15810', codigo_departamento: 15, nombre_municipio: 'TIPACOQUE' },
      { codigo_municipio: '15814', codigo_departamento: 15, nombre_municipio: 'TOCA' },
      { codigo_municipio: '15816', codigo_departamento: 15, nombre_municipio: 'TOGÜÍ' },
      { codigo_municipio: '15820', codigo_departamento: 15, nombre_municipio: 'TÓPAGA' },
      { codigo_municipio: '15822', codigo_departamento: 15, nombre_municipio: 'TOTA' },
      { codigo_municipio: '15832', codigo_departamento: 15, nombre_municipio: 'TUNUNGUÁ' },
      { codigo_municipio: '15835', codigo_departamento: 15, nombre_municipio: 'TURMEQUÉ' },
      { codigo_municipio: '15837', codigo_departamento: 15, nombre_municipio: 'TUTA' },
      { codigo_municipio: '15839', codigo_departamento: 15, nombre_municipio: 'TUTAZÁ' },
      { codigo_municipio: '15842', codigo_departamento: 15, nombre_municipio: 'ÚMBITA' },
      { codigo_municipio: '15861', codigo_departamento: 15, nombre_municipio: 'VENTAQUEMADA' },
      { codigo_municipio: '15879', codigo_departamento: 15, nombre_municipio: 'VIRACACHÁ' },
      { codigo_municipio: '15897', codigo_departamento: 15, nombre_municipio: 'ZETAQUIRA' },
      
      // Municipios de Caldas
      { codigo_municipio: '17001', codigo_departamento: 17, nombre_municipio: 'MANIZALES' },
      { codigo_municipio: '17013', codigo_departamento: 17, nombre_municipio: 'AGUADAS' },
      { codigo_municipio: '17042', codigo_departamento: 17, nombre_municipio: 'ANSERMA' },
      { codigo_municipio: '17050', codigo_departamento: 17, nombre_municipio: 'ARANZAZU' },
      { codigo_municipio: '17088', codigo_departamento: 17, nombre_municipio: 'BELALCÁZAR' },
      { codigo_municipio: '17174', codigo_departamento: 17, nombre_municipio: 'CHINCHINÁ' },
      { codigo_municipio: '17272', codigo_departamento: 17, nombre_municipio: 'FILADELFIA' },
      { codigo_municipio: '17380', codigo_departamento: 17, nombre_municipio: 'LA DORADA' },
      { codigo_municipio: '17388', codigo_departamento: 17, nombre_municipio: 'LA MERCED' },
      { codigo_municipio: '17433', codigo_departamento: 17, nombre_municipio: 'MANZANARES' },
      { codigo_municipio: '17442', codigo_departamento: 17, nombre_municipio: 'MARMATO' },
      { codigo_municipio: '17444', codigo_departamento: 17, nombre_municipio: 'MARQUETALIA' },
      { codigo_municipio: '17446', codigo_departamento: 17, nombre_municipio: 'MARULANDA' },
      { codigo_municipio: '17486', codigo_departamento: 17, nombre_municipio: 'NEIRA' },
      { codigo_municipio: '17495', codigo_departamento: 17, nombre_municipio: 'NORCASIA' },
      { codigo_municipio: '17513', codigo_departamento: 17, nombre_municipio: 'PÁCORA' },
      { codigo_municipio: '17524', codigo_departamento: 17, nombre_municipio: 'PALESTINA' },
      { codigo_municipio: '17541', codigo_departamento: 17, nombre_municipio: 'PENSILVANIA' },
      { codigo_municipio: '17614', codigo_departamento: 17, nombre_municipio: 'RIOSUCIO' },
      { codigo_municipio: '17616', codigo_departamento: 17, nombre_municipio: 'RISARALDA' },
      { codigo_municipio: '17653', codigo_departamento: 17, nombre_municipio: 'SALAMINA' },
      { codigo_municipio: '17662', codigo_departamento: 17, nombre_municipio: 'SAMANÁ' },
      { codigo_municipio: '17665', codigo_departamento: 17, nombre_municipio: 'SAN JOSÉ' },
      { codigo_municipio: '17777', codigo_departamento: 17, nombre_municipio: 'SUPÍA' },
      { codigo_municipio: '17867', codigo_departamento: 17, nombre_municipio: 'VICTORIA' },
      { codigo_municipio: '17873', codigo_departamento: 17, nombre_municipio: 'VILLAMARÍA' },
      { codigo_municipio: '17877', codigo_departamento: 17, nombre_municipio: 'VITERBO' },
      
      // Municipios de Caquetá
      { codigo_municipio: '18001', codigo_departamento: 18, nombre_municipio: 'FLORENCIA' },
      { codigo_municipio: '18029', codigo_departamento: 18, nombre_municipio: 'ALBANIA' },
      { codigo_municipio: '18094', codigo_departamento: 18, nombre_municipio: 'BELÉN DE LOS ANDAQUÍES' },
      { codigo_municipio: '18150', codigo_departamento: 18, nombre_municipio: 'CARTAGENA DEL CHAIRÁ' },
      { codigo_municipio: '18205', codigo_departamento: 18, nombre_municipio: 'CURILLO' },
      { codigo_municipio: '18247', codigo_departamento: 18, nombre_municipio: 'EL DONCELLO' },
      { codigo_municipio: '18256', codigo_departamento: 18, nombre_municipio: 'EL PAUJÍL' },
      { codigo_municipio: '18410', codigo_departamento: 18, nombre_municipio: 'LA MONTAÑITA' },
      { codigo_municipio: '18460', codigo_departamento: 18, nombre_municipio: 'MILÁN' },
      { codigo_municipio: '18479', codigo_departamento: 18, nombre_municipio: 'MORELIA' },
      { codigo_municipio: '18592', codigo_departamento: 18, nombre_municipio: 'PUERTO RICO' },
      { codigo_municipio: '18610', codigo_departamento: 18, nombre_municipio: 'SAN JOSÉ DEL FRAGUA' },
      { codigo_municipio: '18753', codigo_departamento: 18, nombre_municipio: 'SAN VICENTE DEL CAGUÁN' },
      { codigo_municipio: '18756', codigo_departamento: 18, nombre_municipio: 'SOLANO' },
      { codigo_municipio: '18785', codigo_departamento: 18, nombre_municipio: 'SOLITA' },
      { codigo_municipio: '18860', codigo_departamento: 18, nombre_municipio: 'VALPARAÍSO' },
      
      // Municipios de Casanare
      { codigo_municipio: '85001', codigo_departamento: 85, nombre_municipio: 'YOPAL' },
      { codigo_municipio: '85010', codigo_departamento: 85, nombre_municipio: 'AGUAZUL' },
      { codigo_municipio: '85015', codigo_departamento: 85, nombre_municipio: 'CHÁMEZA' },
      { codigo_municipio: '85125', codigo_departamento: 85, nombre_municipio: 'HATO COROZAL' },
      { codigo_municipio: '85136', codigo_departamento: 85, nombre_municipio: 'LA SALINA' },
      { codigo_municipio: '85139', codigo_departamento: 85, nombre_municipio: 'MANÍ' },
      { codigo_municipio: '85162', codigo_departamento: 85, nombre_municipio: 'MONTERREY' },
      { codigo_municipio: '85225', codigo_departamento: 85, nombre_municipio: 'NUNCHÍA' },
      { codigo_municipio: '85230', codigo_departamento: 85, nombre_municipio: 'OROCUÉ' },
      { codigo_municipio: '85250', codigo_departamento: 85, nombre_municipio: 'PAZ DE ARIPORO' },
      { codigo_municipio: '85263', codigo_departamento: 85, nombre_municipio: 'PORE' },
      { codigo_municipio: '85279', codigo_departamento: 85, nombre_municipio: 'RECETOR' },
      { codigo_municipio: '85300', codigo_departamento: 85, nombre_municipio: 'SABANALARGA' },
      { codigo_municipio: '85315', codigo_departamento: 85, nombre_municipio: 'SÁCAMA' },
      { codigo_municipio: '85325', codigo_departamento: 85, nombre_municipio: 'SAN LUIS DE PALENQUE' },
      { codigo_municipio: '85400', codigo_departamento: 85, nombre_municipio: 'TÁMARA' },
      { codigo_municipio: '85410', codigo_departamento: 85, nombre_municipio: 'TAURAMENA' },
      { codigo_municipio: '85430', codigo_departamento: 85, nombre_municipio: 'TRINIDAD' },
      { codigo_municipio: '85440', codigo_departamento: 85, nombre_municipio: 'VILLANUEVA' },
      
      // Municipios de Cauca
      { codigo_municipio: '19001', codigo_departamento: 19, nombre_municipio: 'POPAYÁN' },
      { codigo_municipio: '19022', codigo_departamento: 19, nombre_municipio: 'ALMAGUER' },
      { codigo_municipio: '19050', codigo_departamento: 19, nombre_municipio: 'ARGELIA' },
      { codigo_municipio: '19075', codigo_departamento: 19, nombre_municipio: 'BALBOA' },
      { codigo_municipio: '19100', codigo_departamento: 19, nombre_municipio: 'BOLÍVAR' },
      { codigo_municipio: '19110', codigo_departamento: 19, nombre_municipio: 'BUENOS AIRES' },
      { codigo_municipio: '19130', codigo_departamento: 19, nombre_municipio: 'CAJIBÍO' },
      { codigo_municipio: '19137', codigo_departamento: 19, nombre_municipio: 'CALDONO' },
      { codigo_municipio: '19142', codigo_departamento: 19, nombre_municipio: 'CALOTO' },
      { codigo_municipio: '19212', codigo_departamento: 19, nombre_municipio: 'CORINTO' },
      { codigo_municipio: '19256', codigo_departamento: 19, nombre_municipio: 'EL TAMBO' },
      { codigo_municipio: '19290', codigo_departamento: 19, nombre_municipio: 'FLORENCIA' },
      { codigo_municipio: '19300', codigo_departamento: 19, nombre_municipio: 'GUACHENÉ' },
      { codigo_municipio: '19318', codigo_departamento: 19, nombre_municipio: 'GUAPÍ' },
      { codigo_municipio: '19355', codigo_departamento: 19, nombre_municipio: 'INZÁ' },
      { codigo_municipio: '19364', codigo_departamento: 19, nombre_municipio: 'JAMBALÓ' },
      { codigo_municipio: '19392', codigo_departamento: 19, nombre_municipio: 'LA SIERRA' },
      { codigo_municipio: '19397', codigo_departamento: 19, nombre_municipio: 'LA VEGA' },
      { codigo_municipio: '19418', codigo_departamento: 19, nombre_municipio: 'LÓPEZ DE MICAY' },
      { codigo_municipio: '19450', codigo_departamento: 19, nombre_municipio: 'MERCADERES' },
      { codigo_municipio: '19455', codigo_departamento: 19, nombre_municipio: 'MIRANDA' },
      { codigo_municipio: '19473', codigo_departamento: 19, nombre_municipio: 'MORALES' },
      { codigo_municipio: '19513', codigo_departamento: 19, nombre_municipio: 'PADILLA' },
      { codigo_municipio: '19517', codigo_departamento: 19, nombre_municipio: 'PÁEZ' },
      { codigo_municipio: '19532', codigo_departamento: 19, nombre_municipio: 'PATÍA' },
      { codigo_municipio: '19533', codigo_departamento: 19, nombre_municipio: 'PIAMONTE' },
      { codigo_municipio: '19548', codigo_departamento: 19, nombre_municipio: 'PIENDAMÓ' },
      { codigo_municipio: '19573', codigo_departamento: 19, nombre_municipio: 'PUERTO TEJADA' },
      { codigo_municipio: '19585', codigo_departamento: 19, nombre_municipio: 'PURACÉ' },
      { codigo_municipio: '19622', codigo_departamento: 19, nombre_municipio: 'ROSAS' },
      { codigo_municipio: '19693', codigo_departamento: 19, nombre_municipio: 'SAN SEBASTIÁN' },
      { codigo_municipio: '19698', codigo_departamento: 19, nombre_municipio: 'SANTANDER DE QUILICHAO' },
      { codigo_municipio: '19701', codigo_departamento: 19, nombre_municipio: 'SANTA ROSA' },
      { codigo_municipio: '19743', codigo_departamento: 19, nombre_municipio: 'SILVIA' },
      { codigo_municipio: '19760', codigo_departamento: 19, nombre_municipio: 'SOTARA' },
      { codigo_municipio: '19780', codigo_departamento: 19, nombre_municipio: 'SUÁREZ' },
      { codigo_municipio: '19785', codigo_departamento: 19, nombre_municipio: 'SUCRE' },
      { codigo_municipio: '19807', codigo_departamento: 19, nombre_municipio: 'TIMBÍO' },
      { codigo_municipio: '19809', codigo_departamento: 19, nombre_municipio: 'TIMBIQUÍ' },
      { codigo_municipio: '19821', codigo_departamento: 19, nombre_municipio: 'TORIBÍO' },
      { codigo_municipio: '19824', codigo_departamento: 19, nombre_municipio: 'TOTORÓ' },
      { codigo_municipio: '19845', codigo_departamento: 19, nombre_municipio: 'VILLA RICA' },
      
      // Municipios de Cesar
      { codigo_municipio: '20001', codigo_departamento: 20, nombre_municipio: 'VALLEDUPAR' },
      { codigo_municipio: '20011', codigo_departamento: 20, nombre_municipio: 'AGUACHICA' },
      { codigo_municipio: '20013', codigo_departamento: 20, nombre_municipio: 'AGUSTÍN CODAZZI' },
      { codigo_municipio: '20032', codigo_departamento: 20, nombre_municipio: 'ASTREA' },
      { codigo_municipio: '20045', codigo_departamento: 20, nombre_municipio: 'BECERRIL' },
      { codigo_municipio: '20060', codigo_departamento: 20, nombre_municipio: 'BOSCONIA' },
      { codigo_municipio: '20175', codigo_departamento: 20, nombre_municipio: 'CHIMICHAGUA' },
      { codigo_municipio: '20178', codigo_departamento: 20, nombre_municipio: 'CHIRIGUANÁ' },
      { codigo_municipio: '20228', codigo_departamento: 20, nombre_municipio: 'CURUMANÍ' },
      { codigo_municipio: '20238', codigo_departamento: 20, nombre_municipio: 'EL COPEY' },
      { codigo_municipio: '20250', codigo_departamento: 20, nombre_municipio: 'EL PASO' },
      { codigo_municipio: '20295', codigo_departamento: 20, nombre_municipio: 'GAMARRA' },
      { codigo_municipio: '20310', codigo_departamento: 20, nombre_municipio: 'GONZÁLEZ' },
      { codigo_municipio: '20383', codigo_departamento: 20, nombre_municipio: 'LA GLORIA' },
      { codigo_municipio: '20400', codigo_departamento: 20, nombre_municipio: 'LA JAGUA DE IBIRICO' },
      { codigo_municipio: '20443', codigo_departamento: 20, nombre_municipio: 'MANAURE' },
      { codigo_municipio: '20517', codigo_departamento: 20, nombre_municipio: 'PAILITAS' },
      { codigo_municipio: '20550', codigo_departamento: 20, nombre_municipio: 'PELAYA' },
      { codigo_municipio: '20570', codigo_departamento: 20, nombre_municipio: 'PUEBLO BELLO' },
      { codigo_municipio: '20614', codigo_departamento: 20, nombre_municipio: 'RÍO DE ORO' },
      { codigo_municipio: '20621', codigo_departamento: 20, nombre_municipio: 'LA PAZ' },
      { codigo_municipio: '20710', codigo_departamento: 20, nombre_municipio: 'SAN ALBERTO' },
      { codigo_municipio: '20750', codigo_departamento: 20, nombre_municipio: 'SAN DIEGO' },
      { codigo_municipio: '20770', codigo_departamento: 20, nombre_municipio: 'SAN MARTÍN' },
      { codigo_municipio: '20787', codigo_departamento: 20, nombre_municipio: 'TAMALAMEQUE' },
      
      // Municipios de Chocó
      { codigo_municipio: '27001', codigo_departamento: 27, nombre_municipio: 'QUIBDÓ' },
      { codigo_municipio: '27006', codigo_departamento: 27, nombre_municipio: 'ACANDÍ' },
      { codigo_municipio: '27025', codigo_departamento: 27, nombre_municipio: 'ALTO BAUDÓ' },
      { codigo_municipio: '27050', codigo_departamento: 27, nombre_municipio: 'ATRATO' },
      { codigo_municipio: '27073', codigo_departamento: 27, nombre_municipio: 'BAGADÓ' },
      { codigo_municipio: '27075', codigo_departamento: 27, nombre_municipio: 'BAHÍA SOLANO' },
      { codigo_municipio: '27077', codigo_departamento: 27, nombre_municipio: 'BAJO BAUDÓ' },
      { codigo_municipio: '27099', codigo_departamento: 27, nombre_municipio: 'BOJAYÁ' },
      { codigo_municipio: '27135', codigo_departamento: 27, nombre_municipio: 'CANTÓN DE SAN PABLO' },
      { codigo_municipio: '27150', codigo_departamento: 27, nombre_municipio: 'CARMEN DEL DARIÉN' },
      { codigo_municipio: '27160', codigo_departamento: 27, nombre_municipio: 'CERTEGUI' },
      { codigo_municipio: '27205', codigo_departamento: 27, nombre_municipio: 'CONDOTO' },
      { codigo_municipio: '27245', codigo_departamento: 27, nombre_municipio: 'EL CARMEN DE ATRATO' },
      { codigo_municipio: '27250', codigo_departamento: 27, nombre_municipio: 'EL LITORAL DEL SAN JUAN' },
      { codigo_municipio: '27361', codigo_departamento: 27, nombre_municipio: 'ISTMINA' },
      { codigo_municipio: '27372', codigo_departamento: 27, nombre_municipio: 'JURADÓ' },
      { codigo_municipio: '27413', codigo_departamento: 27, nombre_municipio: 'LLORÓ' },
      { codigo_municipio: '27425', codigo_departamento: 27, nombre_municipio: 'MEDIO ATRATO' },
      { codigo_municipio: '27430', codigo_departamento: 27, nombre_municipio: 'MEDIO BAUDÓ' },
      { codigo_municipio: '27450', codigo_departamento: 27, nombre_municipio: 'MEDIO SAN JUAN' },
      { codigo_municipio: '27491', codigo_departamento: 27, nombre_municipio: 'NÓVITA' },
      { codigo_municipio: '27495', codigo_departamento: 27, nombre_municipio: 'NUQUÍ' },
      { codigo_municipio: '27580', codigo_departamento: 27, nombre_municipio: 'RÍO IRÓ' },
      { codigo_municipio: '27600', codigo_departamento: 27, nombre_municipio: 'RÍO QUITO' },
      { codigo_municipio: '27615', codigo_departamento: 27, nombre_municipio: 'RIOSUCIO' },
      { codigo_municipio: '27660', codigo_departamento: 27, nombre_municipio: 'SAN JOSÉ DEL PALMAR' },
      { codigo_municipio: '27745', codigo_departamento: 27, nombre_municipio: 'SIPÍ' },
      { codigo_municipio: '27787', codigo_departamento: 27, nombre_municipio: 'TADÓ' },
      { codigo_municipio: '27800', codigo_departamento: 27, nombre_municipio: 'UNGUÍA' },
      { codigo_municipio: '27810', codigo_departamento: 27, nombre_municipio: 'UNIÓN PANAMERICANA' },
      
      // Municipios de Córdoba
      { codigo_municipio: '23001', codigo_departamento: 23, nombre_municipio: 'MONTERÍA' },
      { codigo_municipio: '23068', codigo_departamento: 23, nombre_municipio: 'AYAPEL' },
      { codigo_municipio: '23079', codigo_departamento: 23, nombre_municipio: 'BUENAVISTA' },
      { codigo_municipio: '23090', codigo_departamento: 23, nombre_municipio: 'CANALETE' },
      { codigo_municipio: '23162', codigo_departamento: 23, nombre_municipio: 'CERETÉ' },
      { codigo_municipio: '23168', codigo_departamento: 23, nombre_municipio: 'CHIMÁ' },
      { codigo_municipio: '23182', codigo_departamento: 23, nombre_municipio: 'CHINÚ' },
      { codigo_municipio: '23189', codigo_departamento: 23, nombre_municipio: 'CIÉNAGA DE ORO' },
      { codigo_municipio: '23300', codigo_departamento: 23, nombre_municipio: 'COTORRA' },
      { codigo_municipio: '23350', codigo_departamento: 23, nombre_municipio: 'LA APARTADA' },
      { codigo_municipio: '23417', codigo_departamento: 23, nombre_municipio: 'LORICA' },
      { codigo_municipio: '23419', codigo_departamento: 23, nombre_municipio: 'LOS CÓRDOBAS' },
      { codigo_municipio: '23464', codigo_departamento: 23, nombre_municipio: 'MOMIL' },
      { codigo_municipio: '23466', codigo_departamento: 23, nombre_municipio: 'MONTELÍBANO' },
      { codigo_municipio: '23500', codigo_departamento: 23, nombre_municipio: 'MOÑITOS' },
      { codigo_municipio: '23555', codigo_departamento: 23, nombre_municipio: 'PLANETA RICA' },
      { codigo_municipio: '23570', codigo_departamento: 23, nombre_municipio: 'PUEBLO NUEVO' },
      { codigo_municipio: '23574', codigo_departamento: 23, nombre_municipio: 'PUERTO ESCONDIDO' },
      { codigo_municipio: '23580', codigo_departamento: 23, nombre_municipio: 'PUERTO LIBERTADOR' },
      { codigo_municipio: '23586', codigo_departamento: 23, nombre_municipio: 'PURÍSIMA' },
      { codigo_municipio: '23660', codigo_departamento: 23, nombre_municipio: 'SAHAGÚN' },
      { codigo_municipio: '23670', codigo_departamento: 23, nombre_municipio: 'SAN ANDRÉS SOTAVENTO' },
      { codigo_municipio: '23672', codigo_departamento: 23, nombre_municipio: 'SAN ANTERO' },
      { codigo_municipio: '23675', codigo_departamento: 23, nombre_municipio: 'SAN BERNARDO DEL VIENTO' },
      { codigo_municipio: '23678', codigo_departamento: 23, nombre_municipio: 'SAN CARLOS' },
      { codigo_municipio: '23682', codigo_departamento: 23, nombre_municipio: 'SAN JOSÉ DE URÉ' },
      { codigo_municipio: '23686', codigo_departamento: 23, nombre_municipio: 'SAN PELAYO' },
      { codigo_municipio: '23807', codigo_departamento: 23, nombre_municipio: 'TIERRALTA' },
      { codigo_municipio: '23815', codigo_departamento: 23, nombre_municipio: 'TUCHÍN' },
      { codigo_municipio: '23855', codigo_departamento: 23, nombre_municipio: 'VALENCIA' },
      
      // Municipios de Cundinamarca
      { codigo_municipio: '25001', codigo_departamento: 25, nombre_municipio: 'AGUA DE DIOS' },
      { codigo_municipio: '25019', codigo_departamento: 25, nombre_municipio: 'ALBÁN' },
      { codigo_municipio: '25035', codigo_departamento: 25, nombre_municipio: 'ANAPOIMA' },
      { codigo_municipio: '25040', codigo_departamento: 25, nombre_municipio: 'ANOLAIMA' },
      { codigo_municipio: '25053', codigo_departamento: 25, nombre_municipio: 'ARBELÁEZ' },
      { codigo_municipio: '25086', codigo_departamento: 25, nombre_municipio: 'BELTRÁN' },
      { codigo_municipio: '25095', codigo_departamento: 25, nombre_municipio: 'BITUIMA' },
      { codigo_municipio: '25099', codigo_departamento: 25, nombre_municipio: 'BOJACÁ' },
      { codigo_municipio: '25120', codigo_departamento: 25, nombre_municipio: 'CABRERA' },
      { codigo_municipio: '25123', codigo_departamento: 25, nombre_municipio: 'CACHIPAY' },
      { codigo_municipio: '25126', codigo_departamento: 25, nombre_municipio: 'CAJICÁ' },
      { codigo_municipio: '25148', codigo_departamento: 25, nombre_municipio: 'CAPARRAPÍ' },
      { codigo_municipio: '25151', codigo_departamento: 25, nombre_municipio: 'CÁQUEZA' },
      { codigo_municipio: '25154', codigo_departamento: 25, nombre_municipio: 'CARMEN DE CARUPA' },
      { codigo_municipio: '25168', codigo_departamento: 25, nombre_municipio: 'CHAGUANÍ' },
      { codigo_municipio: '25175', codigo_departamento: 25, nombre_municipio: 'CHÍA' },
      { codigo_municipio: '25178', codigo_departamento: 25, nombre_municipio: 'CHIPAQUE' },
      { codigo_municipio: '25181', codigo_departamento: 25, nombre_municipio: 'CHOACHÍ' },
      { codigo_municipio: '25183', codigo_departamento: 25, nombre_municipio: 'CHOCONTÁ' },
      { codigo_municipio: '25200', codigo_departamento: 25, nombre_municipio: 'COGUA' },
      { codigo_municipio: '25214', codigo_departamento: 25, nombre_municipio: 'COTA' },
      { codigo_municipio: '25224', codigo_departamento: 25, nombre_municipio: 'CUCUNUBÁ' },
      { codigo_municipio: '25245', codigo_departamento: 25, nombre_municipio: 'EL COLEGIO' },
      { codigo_municipio: '25258', codigo_departamento: 25, nombre_municipio: 'EL PEÑÓN' },
      { codigo_municipio: '25260', codigo_departamento: 25, nombre_municipio: 'EL ROSAL' },
      { codigo_municipio: '25269', codigo_departamento: 25, nombre_municipio: 'FACATATIVÁ' },
      { codigo_municipio: '25279', codigo_departamento: 25, nombre_municipio: 'FÓMEQUE' },
      { codigo_municipio: '25281', codigo_departamento: 25, nombre_municipio: 'FOSCA' },
      { codigo_municipio: '25286', codigo_departamento: 25, nombre_municipio: 'FUNZA' },
      { codigo_municipio: '25288', codigo_departamento: 25, nombre_municipio: 'FÚQUENE' },
      { codigo_municipio: '25290', codigo_departamento: 25, nombre_municipio: 'FUSAGASUGÁ' },
      { codigo_municipio: '25293', codigo_departamento: 25, nombre_municipio: 'GACHALA' },
      { codigo_municipio: '25295', codigo_departamento: 25, nombre_municipio: 'GACHANCIPÁ' },
      { codigo_municipio: '25297', codigo_departamento: 25, nombre_municipio: 'GACHETÁ' },
      { codigo_municipio: '25299', codigo_departamento: 25, nombre_municipio: 'GAMA' },
      { codigo_municipio: '25307', codigo_departamento: 25, nombre_municipio: 'GIRARDOT' },
      { codigo_municipio: '25312', codigo_departamento: 25, nombre_municipio: 'GRANADA' },
      { codigo_municipio: '25317', codigo_departamento: 25, nombre_municipio: 'GUACHETÁ' },
      { codigo_municipio: '25320', codigo_departamento: 25, nombre_municipio: 'GUADUAS' },
      { codigo_municipio: '25322', codigo_departamento: 25, nombre_municipio: 'GUASCA' },
      { codigo_municipio: '25324', codigo_departamento: 25, nombre_municipio: 'GUATAQUÍ' },
      { codigo_municipio: '25326', codigo_departamento: 25, nombre_municipio: 'GUATAVITA' },
      { codigo_municipio: '25328', codigo_departamento: 25, nombre_municipio: 'GUAYABAL DE SÍQUIMA' },
      { codigo_municipio: '25335', codigo_departamento: 25, nombre_municipio: 'GUAYABETAL' },
      { codigo_municipio: '25339', codigo_departamento: 25, nombre_municipio: 'GUTIÉRREZ' },
      { codigo_municipio: '25368', codigo_departamento: 25, nombre_municipio: 'JERUSALÉN' },
      { codigo_municipio: '25372', codigo_departamento: 25, nombre_municipio: 'JUNÍN' },
      { codigo_municipio: '25377', codigo_departamento: 25, nombre_municipio: 'LA CALERA' },
      { codigo_municipio: '25386', codigo_departamento: 25, nombre_municipio: 'LA MESA' },
      { codigo_municipio: '25394', codigo_departamento: 25, nombre_municipio: 'LA PALMA' },
      { codigo_municipio: '25398', codigo_departamento: 25, nombre_municipio: 'LA PEÑA' },
      { codigo_municipio: '25402', codigo_departamento: 25, nombre_municipio: 'LA VEGA' },
      { codigo_municipio: '25407', codigo_departamento: 25, nombre_municipio: 'LENGUAZAQUE' },
      { codigo_municipio: '25426', codigo_departamento: 25, nombre_municipio: 'MACHETA' },
      { codigo_municipio: '25430', codigo_departamento: 25, nombre_municipio: 'MADRID' },
      { codigo_municipio: '25436', codigo_departamento: 25, nombre_municipio: 'MANTA' },
      { codigo_municipio: '25438', codigo_departamento: 25, nombre_municipio: 'MEDINA' },
      { codigo_municipio: '25473', codigo_departamento: 25, nombre_municipio: 'MOSQUERA' },
      { codigo_municipio: '25483', codigo_departamento: 25, nombre_municipio: 'NARIÑO' },
      { codigo_municipio: '25486', codigo_departamento: 25, nombre_municipio: 'NEMOCÓN' },
      { codigo_municipio: '25488', codigo_departamento: 25, nombre_municipio: 'NILO' },
      { codigo_municipio: '25489', codigo_departamento: 25, nombre_municipio: 'NIMAIMA' },
      { codigo_municipio: '25491', codigo_departamento: 25, nombre_municipio: 'NOCAIMA' },
      { codigo_municipio: '25506', codigo_departamento: 25, nombre_municipio: 'VENECIA' },
      { codigo_municipio: '25513', codigo_departamento: 25, nombre_municipio: 'PACHO' },
      { codigo_municipio: '25518', codigo_departamento: 25, nombre_municipio: 'PAIME' },
      { codigo_municipio: '25524', codigo_departamento: 25, nombre_municipio: 'PANDI' },
      { codigo_municipio: '25530', codigo_departamento: 25, nombre_municipio: 'PARATEBUENO' },
      { codigo_municipio: '25535', codigo_departamento: 25, nombre_municipio: 'PASCA' },
      { codigo_municipio: '25572', codigo_departamento: 25, nombre_municipio: 'PUERTO SALGAR' },
      { codigo_municipio: '25580', codigo_departamento: 25, nombre_municipio: 'PULÍ' },
      { codigo_municipio: '25592', codigo_departamento: 25, nombre_municipio: 'QUEBRADANEGRA' },
      { codigo_municipio: '25594', codigo_departamento: 25, nombre_municipio: 'QUETAME' },
      { codigo_municipio: '25596', codigo_departamento: 25, nombre_municipio: 'QUIPILE' },
      { codigo_municipio: '25599', codigo_departamento: 25, nombre_municipio: 'APULO' },
      { codigo_municipio: '25612', codigo_departamento: 25, nombre_municipio: 'RICAURTE' },
      { codigo_municipio: '25645', codigo_departamento: 25, nombre_municipio: 'SAN ANTONIO DEL TEQUENDAMA' },
      { codigo_municipio: '25649', codigo_departamento: 25, nombre_municipio: 'SAN BERNARDO' },
      { codigo_municipio: '25653', codigo_departamento: 25, nombre_municipio: 'SAN CAYETANO' },
      { codigo_municipio: '25658', codigo_departamento: 25, nombre_municipio: 'SAN FRANCISCO' },
      { codigo_municipio: '25662', codigo_departamento: 25, nombre_municipio: 'SAN JUAN DE RIOSECO' },
      { codigo_municipio: '25718', codigo_departamento: 25, nombre_municipio: 'SASAIMA' },
      { codigo_municipio: '25736', codigo_departamento: 25, nombre_municipio: 'SESQUILÉ' },
      { codigo_municipio: '25740', codigo_departamento: 25, nombre_municipio: 'SIBATÉ' },
      { codigo_municipio: '25743', codigo_departamento: 25, nombre_municipio: 'SILVANIA' },
      { codigo_municipio: '25745', codigo_departamento: 25, nombre_municipio: 'SIMIJACA' },
      { codigo_municipio: '25754', codigo_departamento: 25, nombre_municipio: 'SOACHA' },
      { codigo_municipio: '25758', codigo_departamento: 25, nombre_municipio: 'SOPÓ' },
      { codigo_municipio: '25769', codigo_departamento: 25, nombre_municipio: 'SUBACHOQUE' },
      { codigo_municipio: '25772', codigo_departamento: 25, nombre_municipio: 'SUESCA' },
      { codigo_municipio: '25777', codigo_departamento: 25, nombre_municipio: 'SUPATÁ' },
      { codigo_municipio: '25779', codigo_departamento: 25, nombre_municipio: 'SUSA' },
      { codigo_municipio: '25781', codigo_departamento: 25, nombre_municipio: 'SUTATAUSA' },
      { codigo_municipio: '25785', codigo_departamento: 25, nombre_municipio: 'TABIO' },
      { codigo_municipio: '25793', codigo_departamento: 25, nombre_municipio: 'TAUSA' },
      { codigo_municipio: '25797', codigo_departamento: 25, nombre_municipio: 'TENA' },
      { codigo_municipio: '25799', codigo_departamento: 25, nombre_municipio: 'TENJO' },
      { codigo_municipio: '25805', codigo_departamento: 25, nombre_municipio: 'TIBACUY' },
      { codigo_municipio: '25807', codigo_departamento: 25, nombre_municipio: 'TIBIRITA' },
      { codigo_municipio: '25815', codigo_departamento: 25, nombre_municipio: 'TOCAIMA' },
      { codigo_municipio: '25817', codigo_departamento: 25, nombre_municipio: 'TOCANCIPÁ' },
      { codigo_municipio: '25823', codigo_departamento: 25, nombre_municipio: 'TOPAIPÍ' },
      { codigo_municipio: '25839', codigo_departamento: 25, nombre_municipio: 'UBALÁ' },
      { codigo_municipio: '25841', codigo_departamento: 25, nombre_municipio: 'UBAQUE' },
      { codigo_municipio: '25843', codigo_departamento: 25, nombre_municipio: 'VILLA DE SAN DIEGO DE UBATÉ' },
      { codigo_municipio: '25845', codigo_departamento: 25, nombre_municipio: 'UNE' },
      { codigo_municipio: '25851', codigo_departamento: 25, nombre_municipio: 'ÚTICA' },
      { codigo_municipio: '25862', codigo_departamento: 25, nombre_municipio: 'VERGARA' },
      { codigo_municipio: '25867', codigo_departamento: 25, nombre_municipio: 'VIANÍ' },
      { codigo_municipio: '25871', codigo_departamento: 25, nombre_municipio: 'VILLAGÓMEZ' },
      { codigo_municipio: '25873', codigo_departamento: 25, nombre_municipio: 'VILLAPINZÓN' },
      { codigo_municipio: '25875', codigo_departamento: 25, nombre_municipio: 'VILLETA' },
      { codigo_municipio: '25878', codigo_departamento: 25, nombre_municipio: 'VIOTÁ' },
      { codigo_municipio: '25885', codigo_departamento: 25, nombre_municipio: 'YACOPÍ' },
      { codigo_municipio: '25898', codigo_departamento: 25, nombre_municipio: 'ZIPACÓN' },
      { codigo_municipio: '25899', codigo_departamento: 25, nombre_municipio: 'ZIPAQUIRÁ' },
      
      // Municipios de Guainía
      { codigo_municipio: '94001', codigo_departamento: 94, nombre_municipio: 'INÍRIDA' },
      { codigo_municipio: '94343', codigo_departamento: 94, nombre_municipio: 'BARRANCO MINAS' },
      { codigo_municipio: '94663', codigo_departamento: 94, nombre_municipio: 'MAPIRIPANA' },
      { codigo_municipio: '94883', codigo_departamento: 94, nombre_municipio: 'SAN FELIPE' },
      { codigo_municipio: '94884', codigo_departamento: 94, nombre_municipio: 'PUERTO COLOMBIA' },
      { codigo_municipio: '94885', codigo_departamento: 94, nombre_municipio: 'LA GUADALUPE' },
      { codigo_municipio: '94886', codigo_departamento: 94, nombre_municipio: 'CACAHUAL' },
      { codigo_municipio: '94887', codigo_departamento: 94, nombre_municipio: 'PANA PANA' },
      { codigo_municipio: '94888', codigo_departamento: 94, nombre_municipio: 'MORICHAL' },
      
      // Municipios de Guaviare
      { codigo_municipio: '95001', codigo_departamento: 95, nombre_municipio: 'SAN JOSÉ DEL GUAVIARE' },
      { codigo_municipio: '95015', codigo_departamento: 95, nombre_municipio: 'CALAMAR' },
      { codigo_municipio: '95025', codigo_departamento: 95, nombre_municipio: 'EL RETORNO' },
      { codigo_municipio: '95200', codigo_departamento: 95, nombre_municipio: 'MIRAFLORES' },
      
      // Municipios de Huila
      { codigo_municipio: '41001', codigo_departamento: 41, nombre_municipio: 'NEIVA' },
      { codigo_municipio: '41006', codigo_departamento: 41, nombre_municipio: 'ACEVEDO' },
      { codigo_municipio: '41013', codigo_departamento: 41, nombre_municipio: 'AGRADO' },
      { codigo_municipio: '41016', codigo_departamento: 41, nombre_municipio: 'AIPE' },
      { codigo_municipio: '41020', codigo_departamento: 41, nombre_municipio: 'ALGECIRAS' },
      { codigo_municipio: '41026', codigo_departamento: 41, nombre_municipio: 'ALTAMIRA' },
      { codigo_municipio: '41078', codigo_departamento: 41, nombre_municipio: 'BARAYA' },
      { codigo_municipio: '41132', codigo_departamento: 41, nombre_municipio: 'CAMPOALEGRE' },
      { codigo_municipio: '41206', codigo_departamento: 41, nombre_municipio: 'COLOMBIA' },
      { codigo_municipio: '41244', codigo_departamento: 41, nombre_municipio: 'ELÍAS' },
      { codigo_municipio: '41298', codigo_departamento: 41, nombre_municipio: 'GARZÓN' },
      { codigo_municipio: '41306', codigo_departamento: 41, nombre_municipio: 'GIGANTE' },
      { codigo_municipio: '41319', codigo_departamento: 41, nombre_municipio: 'GUADALUPE' },
      { codigo_municipio: '41349', codigo_departamento: 41, nombre_municipio: 'HOBO' },
      { codigo_municipio: '41357', codigo_departamento: 41, nombre_municipio: 'IQUIRA' },
      { codigo_municipio: '41359', codigo_departamento: 41, nombre_municipio: 'ISNOS' },
      { codigo_municipio: '41378', codigo_departamento: 41, nombre_municipio: 'LA ARGENTINA' },
      { codigo_municipio: '41396', codigo_departamento: 41, nombre_municipio: 'LA PLATA' },
      { codigo_municipio: '41483', codigo_departamento: 41, nombre_municipio: 'NÁTAGA' },
      { codigo_municipio: '41503', codigo_departamento: 41, nombre_municipio: 'OPORAPA' },
      { codigo_municipio: '41518', codigo_departamento: 41, nombre_municipio: 'PAICOL' },
      { codigo_municipio: '41524', codigo_departamento: 41, nombre_municipio: 'PALERMO' },
      { codigo_municipio: '41530', codigo_departamento: 41, nombre_municipio: 'PALESTINA' },
      { codigo_municipio: '41548', codigo_departamento: 41, nombre_municipio: 'PITAL' },
      { codigo_municipio: '41551', codigo_departamento: 41, nombre_municipio: 'PITALITO' },
      { codigo_municipio: '41615', codigo_departamento: 41, nombre_municipio: 'RIVERA' },
      { codigo_municipio: '41660', codigo_departamento: 41, nombre_municipio: 'SALADOBLANCO' },
      { codigo_municipio: '41668', codigo_departamento: 41, nombre_municipio: 'SAN AGUSTÍN' },
      { codigo_municipio: '41676', codigo_departamento: 41, nombre_municipio: 'SANTA MARÍA' },
      { codigo_municipio: '41770', codigo_departamento: 41, nombre_municipio: 'SUAZA' },
      { codigo_municipio: '41791', codigo_departamento: 41, nombre_municipio: 'TARQUI' },
      { codigo_municipio: '41797', codigo_departamento: 41, nombre_municipio: 'TESALIA' },
      { codigo_municipio: '41799', codigo_departamento: 41, nombre_municipio: 'TELLO' },
      { codigo_municipio: '41801', codigo_departamento: 41, nombre_municipio: 'TERUEL' },
      { codigo_municipio: '41807', codigo_departamento: 41, nombre_municipio: 'TIMANÁ' },
      { codigo_municipio: '41872', codigo_departamento: 41, nombre_municipio: 'VILLAVIEJA' },
      { codigo_municipio: '41885', codigo_departamento: 41, nombre_municipio: 'YAGUARÁ' },
      
      // Municipios de La Guajira
      { codigo_municipio: '44001', codigo_departamento: 44, nombre_municipio: 'RIOHACHA' },
      { codigo_municipio: '44035', codigo_departamento: 44, nombre_municipio: 'ALBANIA' },
      { codigo_municipio: '44078', codigo_departamento: 44, nombre_municipio: 'BARRANCAS' },
      { codigo_municipio: '44090', codigo_departamento: 44, nombre_municipio: 'DIBULLA' },
      { codigo_municipio: '44098', codigo_departamento: 44, nombre_municipio: 'DISTRACCIÓN' },
      { codigo_municipio: '44110', codigo_departamento: 44, nombre_municipio: 'EL MOLINO' },
      { codigo_municipio: '44279', codigo_departamento: 44, nombre_municipio: 'FONSECA' },
      { codigo_municipio: '44378', codigo_departamento: 44, nombre_municipio: 'HATONUEVO' },
      { codigo_municipio: '44420', codigo_departamento: 44, nombre_municipio: 'LA JAGUA DEL PILAR' },
      { codigo_municipio: '44430', codigo_departamento: 44, nombre_municipio: 'MAICAO' },
      { codigo_municipio: '44560', codigo_departamento: 44, nombre_municipio: 'MANAURE' },
      { codigo_municipio: '44650', codigo_departamento: 44, nombre_municipio: 'SAN JUAN DEL CESAR' },
      { codigo_municipio: '44847', codigo_departamento: 44, nombre_municipio: 'URIBIA' },
      { codigo_municipio: '44855', codigo_departamento: 44, nombre_municipio: 'URUMITA' },
      { codigo_municipio: '44874', codigo_departamento: 44, nombre_municipio: 'VILLANUEVA' },
      
      // Municipios de Magdalena
      { codigo_municipio: '47001', codigo_departamento: 47, nombre_municipio: 'SANTA MARTA' },
      { codigo_municipio: '47030', codigo_departamento: 47, nombre_municipio: 'ALGARROBO' },
      { codigo_municipio: '47053', codigo_departamento: 47, nombre_municipio: 'ARACATACA' },
      { codigo_municipio: '47058', codigo_departamento: 47, nombre_municipio: 'ARIGUANÍ' },
      { codigo_municipio: '47161', codigo_departamento: 47, nombre_municipio: 'CERRO SAN ANTONIO' },
      { codigo_municipio: '47170', codigo_departamento: 47, nombre_municipio: 'CHIVOLO' },
      { codigo_municipio: '47189', codigo_departamento: 47, nombre_municipio: 'CIÉNAGA' },
      { codigo_municipio: '47205', codigo_departamento: 47, nombre_municipio: 'CONCORDIA' },
      { codigo_municipio: '47245', codigo_departamento: 47, nombre_municipio: 'EL BANCO' },
      { codigo_municipio: '47258', codigo_departamento: 47, nombre_municipio: 'EL PIÑÓN' },
      { codigo_municipio: '47268', codigo_departamento: 47, nombre_municipio: 'EL RETÉN' },
      { codigo_municipio: '47288', codigo_departamento: 47, nombre_municipio: 'FUNDACIÓN' },
      { codigo_municipio: '47318', codigo_departamento: 47, nombre_municipio: 'GUAMAL' },
      { codigo_municipio: '47460', codigo_departamento: 47, nombre_municipio: 'NUEVA GRANADA' },
      { codigo_municipio: '47541', codigo_departamento: 47, nombre_municipio: 'PEDRAZA' },
      { codigo_municipio: '47545', codigo_departamento: 47, nombre_municipio: 'PIJIÑO DEL CARMEN' },
      { codigo_municipio: '47551', codigo_departamento: 47, nombre_municipio: 'PIVIJAY' },
      { codigo_municipio: '47555', codigo_departamento: 47, nombre_municipio: 'PLATO' },
      { codigo_municipio: '47570', codigo_departamento: 47, nombre_municipio: 'PUEBLOVIEJO' },
      { codigo_municipio: '47605', codigo_departamento: 47, nombre_municipio: 'REMOLINO' },
      { codigo_municipio: '47660', codigo_departamento: 47, nombre_municipio: 'SABANAS DE SAN ÁNGEL' },
      { codigo_municipio: '47675', codigo_departamento: 47, nombre_municipio: 'SALAMINA' },
      { codigo_municipio: '47692', codigo_departamento: 47, nombre_municipio: 'SAN SEBASTIÁN DE BUENAVISTA' },
      { codigo_municipio: '47703', codigo_departamento: 47, nombre_municipio: 'SAN ZENÓN' },
      { codigo_municipio: '47707', codigo_departamento: 47, nombre_municipio: 'SANTA ANA' },
      { codigo_municipio: '47720', codigo_departamento: 47, nombre_municipio: 'SANTA BÁRBARA DE PINTO' },
      { codigo_municipio: '47745', codigo_departamento: 47, nombre_municipio: 'SITIONUEVO' },
      { codigo_municipio: '47798', codigo_departamento: 47, nombre_municipio: 'TENERIFE' },
      { codigo_municipio: '47960', codigo_departamento: 47, nombre_municipio: 'ZAPAYÁN' },
      { codigo_municipio: '47980', codigo_departamento: 47, nombre_municipio: 'ZONA BANANERA' },
      
      // Municipios de Meta
      { codigo_municipio: '50001', codigo_departamento: 50, nombre_municipio: 'VILLAVICENCIO' },
      { codigo_municipio: '50006', codigo_departamento: 50, nombre_municipio: 'ACACÍAS' },
      { codigo_municipio: '50110', codigo_departamento: 50, nombre_municipio: 'BARRANCA DE UPÍA' },
      { codigo_municipio: '50124', codigo_departamento: 50, nombre_municipio: 'CABUYARO' },
      { codigo_municipio: '50150', codigo_departamento: 50, nombre_municipio: 'CASTILLA LA NUEVA' },
      { codigo_municipio: '50223', codigo_departamento: 50, nombre_municipio: 'CUBARRAL' },
      { codigo_municipio: '50226', codigo_departamento: 50, nombre_municipio: 'CUMARAL' },
      { codigo_municipio: '50245', codigo_departamento: 50, nombre_municipio: 'EL CALVARIO' },
      { codigo_municipio: '50251', codigo_departamento: 50, nombre_municipio: 'EL CASTILLO' },
      { codigo_municipio: '50270', codigo_departamento: 50, nombre_municipio: 'EL DORADO' },
      { codigo_municipio: '50287', codigo_departamento: 50, nombre_municipio: 'FUENTE DE ORO' },
      { codigo_municipio: '50313', codigo_departamento: 50, nombre_municipio: 'GRANADA' },
      { codigo_municipio: '50318', codigo_departamento: 50, nombre_municipio: 'GUAMAL' },
      { codigo_municipio: '50325', codigo_departamento: 50, nombre_municipio: 'MAPIRIPÁN' },
      { codigo_municipio: '50330', codigo_departamento: 50, nombre_municipio: 'MESETAS' },
      { codigo_municipio: '50350', codigo_departamento: 50, nombre_municipio: 'LA MACARENA' },
      { codigo_municipio: '50370', codigo_departamento: 50, nombre_municipio: 'URIBE' },
      { codigo_municipio: '50400', codigo_departamento: 50, nombre_municipio: 'LEJANÍAS' },
      { codigo_municipio: '50450', codigo_departamento: 50, nombre_municipio: 'PUERTO CONCORDIA' },
      { codigo_municipio: '50568', codigo_departamento: 50, nombre_municipio: 'PUERTO GAITÁN' },
      { codigo_municipio: '50573', codigo_departamento: 50, nombre_municipio: 'PUERTO LÓPEZ' },
      { codigo_municipio: '50577', codigo_departamento: 50, nombre_municipio: 'PUERTO LLERAS' },
      { codigo_municipio: '50590', codigo_departamento: 50, nombre_municipio: 'PUERTO RICO' },
      { codigo_municipio: '50606', codigo_departamento: 50, nombre_municipio: 'RESTREPO' },
      { codigo_municipio: '50680', codigo_departamento: 50, nombre_municipio: 'SAN CARLOS DE GUAROA' },
      { codigo_municipio: '50683', codigo_departamento: 50, nombre_municipio: 'SAN JUAN DE ARAMA' },
      { codigo_municipio: '50686', codigo_departamento: 50, nombre_municipio: 'SAN JUANITO' },
      { codigo_municipio: '50689', codigo_departamento: 50, nombre_municipio: 'SAN MARTÍN' },
      { codigo_municipio: '50711', codigo_departamento: 50, nombre_municipio: 'VISTAHERMOSA' },
      
      // Municipios de Nariño
      { codigo_municipio: '52001', codigo_departamento: 52, nombre_municipio: 'PASTO' },
      { codigo_municipio: '52019', codigo_departamento: 52, nombre_municipio: 'ALBÁN' },
      { codigo_municipio: '52022', codigo_departamento: 52, nombre_municipio: 'ALDANA' },
      { codigo_municipio: '52036', codigo_departamento: 52, nombre_municipio: 'ANCUYÁ' },
      { codigo_municipio: '52051', codigo_departamento: 52, nombre_municipio: 'ARBOLEDA' },
      { codigo_municipio: '52079', codigo_departamento: 52, nombre_municipio: 'BARBACOAS' },
      { codigo_municipio: '52083', codigo_departamento: 52, nombre_municipio: 'BELÉN' },
      { codigo_municipio: '52110', codigo_departamento: 52, nombre_municipio: 'BUESACO' },
      { codigo_municipio: '52203', codigo_departamento: 52, nombre_municipio: 'COLÓN' },
      { codigo_municipio: '52207', codigo_departamento: 52, nombre_municipio: 'CONSACÁ' },
      { codigo_municipio: '52210', codigo_departamento: 52, nombre_municipio: 'CONTADERO' },
      { codigo_municipio: '52215', codigo_departamento: 52, nombre_municipio: 'CÓRDOBA' },
      { codigo_municipio: '52224', codigo_departamento: 52, nombre_municipio: 'CUASPUD' },
      { codigo_municipio: '52227', codigo_departamento: 52, nombre_municipio: 'CUMBAL' },
      { codigo_municipio: '52233', codigo_departamento: 52, nombre_municipio: 'CUMBITARA' },
      { codigo_municipio: '52240', codigo_departamento: 52, nombre_municipio: 'CHACHAGÜÍ' },
      { codigo_municipio: '52250', codigo_departamento: 52, nombre_municipio: 'EL CHARCO' },
      { codigo_municipio: '52254', codigo_departamento: 52, nombre_municipio: 'EL PEÑOL' },
      { codigo_municipio: '52256', codigo_departamento: 52, nombre_municipio: 'EL ROSARIO' },
      { codigo_municipio: '52258', codigo_departamento: 52, nombre_municipio: 'EL TABLÓN DE GÓMEZ' },
      { codigo_municipio: '52260', codigo_departamento: 52, nombre_municipio: 'EL TAMBO' },
      { codigo_municipio: '52287', codigo_departamento: 52, nombre_municipio: 'FUNES' },
      { codigo_municipio: '52317', codigo_departamento: 52, nombre_municipio: 'GUACHUCAL' },
      { codigo_municipio: '52320', codigo_departamento: 52, nombre_municipio: 'GUAITARILLA' },
      { codigo_municipio: '52323', codigo_departamento: 52, nombre_municipio: 'GUALMATÁN' },
      { codigo_municipio: '52352', codigo_departamento: 52, nombre_municipio: 'ILES' },
      { codigo_municipio: '52354', codigo_departamento: 52, nombre_municipio: 'IMUÉS' },
      { codigo_municipio: '52356', codigo_departamento: 52, nombre_municipio: 'IPIALES' },
      { codigo_municipio: '52378', codigo_departamento: 52, nombre_municipio: 'LA CRUZ' },
      { codigo_municipio: '52381', codigo_departamento: 52, nombre_municipio: 'LA FLORIDA' },
      { codigo_municipio: '52385', codigo_departamento: 52, nombre_municipio: 'LA LLANADA' },
      { codigo_municipio: '52390', codigo_departamento: 52, nombre_municipio: 'LA TOLA' },
      { codigo_municipio: '52399', codigo_departamento: 52, nombre_municipio: 'LA UNIÓN' },
      { codigo_municipio: '52405', codigo_departamento: 52, nombre_municipio: 'LEIVA' },
      { codigo_municipio: '52411', codigo_departamento: 52, nombre_municipio: 'LINARES' },
      { codigo_municipio: '52418', codigo_departamento: 52, nombre_municipio: 'LOS ANDES' },
      { codigo_municipio: '52427', codigo_departamento: 52, nombre_municipio: 'MAGÜI' },
      { codigo_municipio: '52435', codigo_departamento: 52, nombre_municipio: 'MALLAMA' },
      { codigo_municipio: '52473', codigo_departamento: 52, nombre_municipio: 'MOSQUERA' },
      { codigo_municipio: '52480', codigo_departamento: 52, nombre_municipio: 'NARIÑO' },
      { codigo_municipio: '52490', codigo_departamento: 52, nombre_municipio: 'OLAYA HERRERA' },
      { codigo_municipio: '52506', codigo_departamento: 52, nombre_municipio: 'OSPINA' },
      { codigo_municipio: '52520', codigo_departamento: 52, nombre_municipio: 'FRANCISCO PIZARRO' },
      { codigo_municipio: '52540', codigo_departamento: 52, nombre_municipio: 'POLICARPA' },
      { codigo_municipio: '52560', codigo_departamento: 52, nombre_municipio: 'POTOSÍ' },
      { codigo_municipio: '52565', codigo_departamento: 52, nombre_municipio: 'PROVIDENCIA' },
      { codigo_municipio: '52573', codigo_departamento: 52, nombre_municipio: 'PUERRES' },
      { codigo_municipio: '52585', codigo_departamento: 52, nombre_municipio: 'PUPIALES' },
      { codigo_municipio: '52612', codigo_departamento: 52, nombre_municipio: 'RICAURTE' },
      { codigo_municipio: '52621', codigo_departamento: 52, nombre_municipio: 'ROBERTO PAYÁN' },
      { codigo_municipio: '52678', codigo_departamento: 52, nombre_municipio: 'SAMANIEGO' },
      { codigo_municipio: '52683', codigo_departamento: 52, nombre_municipio: 'SANDONÁ' },
      { codigo_municipio: '52685', codigo_departamento: 52, nombre_municipio: 'SAN BERNARDO' },
      { codigo_municipio: '52687', codigo_departamento: 52, nombre_municipio: 'SAN LORENZO' },
      { codigo_municipio: '52693', codigo_departamento: 52, nombre_municipio: 'SAN PABLO' },
      { codigo_municipio: '52694', codigo_departamento: 52, nombre_municipio: 'SAN PEDRO DE CARTAGO' },
      { codigo_municipio: '52696', codigo_departamento: 52, nombre_municipio: 'SANTA BÁRBARA' },
      { codigo_municipio: '52699', codigo_departamento: 52, nombre_municipio: 'SANTACRUZ' },
      { codigo_municipio: '52720', codigo_departamento: 52, nombre_municipio: 'SAPUYES' },
      { codigo_municipio: '52786', codigo_departamento: 52, nombre_municipio: 'TAMINANGO' },
      { codigo_municipio: '52788', codigo_departamento: 52, nombre_municipio: 'TANGUA' },
      { codigo_municipio: '52835', codigo_departamento: 52, nombre_municipio: 'SAN ANDRES DE TUMACO' },
      { codigo_municipio: '52838', codigo_departamento: 52, nombre_municipio: 'TÚQUERRES' },
      { codigo_municipio: '52885', codigo_departamento: 52, nombre_municipio: 'YACUANQUER' },
      
      // Municipios de Norte de Santander
      { codigo_municipio: '54001', codigo_departamento: 54, nombre_municipio: 'CÚCUTA' },
      { codigo_municipio: '54003', codigo_departamento: 54, nombre_municipio: 'ABREGO' },
      { codigo_municipio: '54051', codigo_departamento: 54, nombre_municipio: 'ARBOLEDAS' },
      { codigo_municipio: '54099', codigo_departamento: 54, nombre_municipio: 'BOCHALEMA' },
      { codigo_municipio: '54109', codigo_departamento: 54, nombre_municipio: 'BUCARASICA' },
      { codigo_municipio: '54125', codigo_departamento: 54, nombre_municipio: 'CÁCOTA' },
      { codigo_municipio: '54128', codigo_departamento: 54, nombre_municipio: 'CACHIRÁ' },
      { codigo_municipio: '54172', codigo_departamento: 54, nombre_municipio: 'CHINÁCOTA' },
      { codigo_municipio: '54174', codigo_departamento: 54, nombre_municipio: 'CHITAGÁ' },
      { codigo_municipio: '54206', codigo_departamento: 54, nombre_municipio: 'CONVENCIÓN' },
      { codigo_municipio: '54223', codigo_departamento: 54, nombre_municipio: 'CUCUTILLA' },
      { codigo_municipio: '54239', codigo_departamento: 54, nombre_municipio: 'DURANIA' },
      { codigo_municipio: '54245', codigo_departamento: 54, nombre_municipio: 'EL CARMEN' },
      { codigo_municipio: '54250', codigo_departamento: 54, nombre_municipio: 'EL TARRA' },
      { codigo_municipio: '54261', codigo_departamento: 54, nombre_municipio: 'EL ZULIA' },
      { codigo_municipio: '54313', codigo_departamento: 54, nombre_municipio: 'GRAMALOTE' },
      { codigo_municipio: '54344', codigo_departamento: 54, nombre_municipio: 'HACARÍ' },
      { codigo_municipio: '54347', codigo_departamento: 54, nombre_municipio: 'HERRÁN' },
      { codigo_municipio: '54377', codigo_departamento: 54, nombre_municipio: 'LABATECA' },
      { codigo_municipio: '54385', codigo_departamento: 54, nombre_municipio: 'LA ESPERANZA' },
      { codigo_municipio: '54398', codigo_departamento: 54, nombre_municipio: 'LA PLAYA' },
      { codigo_municipio: '54405', codigo_departamento: 54, nombre_municipio: 'LOS PATIOS' },
      { codigo_municipio: '54418', codigo_departamento: 54, nombre_municipio: 'LOURDES' },
      { codigo_municipio: '54480', codigo_departamento: 54, nombre_municipio: 'MUTISCUA' },
      { codigo_municipio: '54498', codigo_departamento: 54, nombre_municipio: 'OCAÑA' },
      { codigo_municipio: '54518', codigo_departamento: 54, nombre_municipio: 'PAMPLONA' },
      { codigo_municipio: '54520', codigo_departamento: 54, nombre_municipio: 'PAMPLONITA' },
      { codigo_municipio: '54553', codigo_departamento: 54, nombre_municipio: 'PUERTO SANTANDER' },
      { codigo_municipio: '54599', codigo_departamento: 54, nombre_municipio: 'RAGONVALIA' },
      { codigo_municipio: '54660', codigo_departamento: 54, nombre_municipio: 'SALAZAR' },
      { codigo_municipio: '54670', codigo_departamento: 54, nombre_municipio: 'SAN CALIXTO' },
      { codigo_municipio: '54673', codigo_departamento: 54, nombre_municipio: 'SAN CAYETANO' },
      { codigo_municipio: '54680', codigo_departamento: 54, nombre_municipio: 'SANTIAGO' },
      { codigo_municipio: '54720', codigo_departamento: 54, nombre_municipio: 'SARDINATA' },
      { codigo_municipio: '54743', codigo_departamento: 54, nombre_municipio: 'SILOS' },
      { codigo_municipio: '54800', codigo_departamento: 54, nombre_municipio: 'TEORAMA' },
      { codigo_municipio: '54810', codigo_departamento: 54, nombre_municipio: 'TIBÚ' },
      { codigo_municipio: '54820', codigo_departamento: 54, nombre_municipio: 'TOLEDO' },
      { codigo_municipio: '54871', codigo_departamento: 54, nombre_municipio: 'VILLA CARO' },
      { codigo_municipio: '54874', codigo_departamento: 54, nombre_municipio: 'VILLA DEL ROSARIO' },
      
      // Municipios de Putumayo
      { codigo_municipio: '86001', codigo_departamento: 86, nombre_municipio: 'MOCOA' },
      { codigo_municipio: '86219', codigo_departamento: 86, nombre_municipio: 'COLÓN' },
      { codigo_municipio: '86320', codigo_departamento: 86, nombre_municipio: 'ORITO' },
      { codigo_municipio: '86568', codigo_departamento: 86, nombre_municipio: 'PUERTO ASÍS' },
      { codigo_municipio: '86569', codigo_departamento: 86, nombre_municipio: 'PUERTO CAICEDO' },
      { codigo_municipio: '86571', codigo_departamento: 86, nombre_municipio: 'PUERTO GUZMÁN' },
      { codigo_municipio: '86573', codigo_departamento: 86, nombre_municipio: 'PUERTO LEGUÍZAMO' },
      { codigo_municipio: '86749', codigo_departamento: 86, nombre_municipio: 'SIBUNDOY' },
      { codigo_municipio: '86755', codigo_departamento: 86, nombre_municipio: 'SAN FRANCISCO' },
      { codigo_municipio: '86757', codigo_departamento: 86, nombre_municipio: 'SAN MIGUEL' },
      { codigo_municipio: '86760', codigo_departamento: 86, nombre_municipio: 'SANTIAGO' },
      { codigo_municipio: '86865', codigo_departamento: 86, nombre_municipio: 'VALLE DEL GUAMUEZ' },
      { codigo_municipio: '86885', codigo_departamento: 86, nombre_municipio: 'VILLAGARZÓN' },
      
      // Municipios de Quindio
      { codigo_municipio: '63001', codigo_departamento: 63, nombre_municipio: 'ARMENIA' },
      { codigo_municipio: '63111', codigo_departamento: 63, nombre_municipio: 'BUENAVISTA' },
      { codigo_municipio: '63130', codigo_departamento: 63, nombre_municipio: 'CALARCÁ' },
      { codigo_municipio: '63190', codigo_departamento: 63, nombre_municipio: 'CIRCASIA' },
      { codigo_municipio: '63212', codigo_departamento: 63, nombre_municipio: 'CÓRDOBA' },
      { codigo_municipio: '63272', codigo_departamento: 63, nombre_municipio: 'FILANDIA' },
      { codigo_municipio: '63302', codigo_departamento: 63, nombre_municipio: 'GÉNOVA' },
      { codigo_municipio: '63401', codigo_departamento: 63, nombre_municipio: 'LA TEBAIDA' },
      { codigo_municipio: '63470', codigo_departamento: 63, nombre_municipio: 'MONTENEGRO' },
      { codigo_municipio: '63548', codigo_departamento: 63, nombre_municipio: 'PIJAO' },
      { codigo_municipio: '63594', codigo_departamento: 63, nombre_municipio: 'QUIMBAYA' },
      { codigo_municipio: '63690', codigo_departamento: 63, nombre_municipio: 'SALENTO' },
      
      // Municipios de Risaralda
      { codigo_municipio: '66001', codigo_departamento: 66, nombre_municipio: 'PEREIRA' },
      { codigo_municipio: '66045', codigo_departamento: 66, nombre_municipio: 'APÍA' },
      { codigo_municipio: '66075', codigo_departamento: 66, nombre_municipio: 'BALBOA' },
      { codigo_municipio: '66088', codigo_departamento: 66, nombre_municipio: 'BELÉN DE UMBRÍA' },
      { codigo_municipio: '66170', codigo_departamento: 66, nombre_municipio: 'DOSQUEBRADAS' },
      { codigo_municipio: '66318', codigo_departamento: 66, nombre_municipio: 'GUÁTICA' },
      { codigo_municipio: '66383', codigo_departamento: 66, nombre_municipio: 'LA CELIA' },
      { codigo_municipio: '66400', codigo_departamento: 66, nombre_municipio: 'LA VIRGINIA' },
      { codigo_municipio: '66440', codigo_departamento: 66, nombre_municipio: 'MARSELLA' },
      { codigo_municipio: '66456', codigo_departamento: 66, nombre_municipio: 'MISTRATÓ' },
      { codigo_municipio: '66572', codigo_departamento: 66, nombre_municipio: 'PUEBLO RICO' },
      { codigo_municipio: '66594', codigo_departamento: 66, nombre_municipio: 'QUINCHÍA' },
      { codigo_municipio: '66682', codigo_departamento: 66, nombre_municipio: 'SANTA ROSA DE CABAL' },
      { codigo_municipio: '66687', codigo_departamento: 66, nombre_municipio: 'SANTUARIO' },
      
      // Municipios de Santander
      { codigo_municipio: '68001', codigo_departamento: 68, nombre_municipio: 'BUCARAMANGA' },
      { codigo_municipio: '68013', codigo_departamento: 68, nombre_municipio: 'AGUADA' },
      { codigo_municipio: '68020', codigo_departamento: 68, nombre_municipio: 'ALBANIA' },
      { codigo_municipio: '68051', codigo_departamento: 68, nombre_municipio: 'ARATOCA' },
      { codigo_municipio: '68077', codigo_departamento: 68, nombre_municipio: 'BARBOSA' },
      { codigo_municipio: '68079', codigo_departamento: 68, nombre_municipio: 'BARICHARA' },
      { codigo_municipio: '68081', codigo_departamento: 68, nombre_municipio: 'BARRANCABERMEJA' },
      { codigo_municipio: '68092', codigo_departamento: 68, nombre_municipio: 'BETULIA' },
      { codigo_municipio: '68101', codigo_departamento: 68, nombre_municipio: 'BOLÍVAR' },
      { codigo_municipio: '68121', codigo_departamento: 68, nombre_municipio: 'CABRERA' },
      { codigo_municipio: '68132', codigo_departamento: 68, nombre_municipio: 'CALIFORNIA' },
      { codigo_municipio: '68147', codigo_departamento: 68, nombre_municipio: 'CAPITANEJO' },
      { codigo_municipio: '68152', codigo_departamento: 68, nombre_municipio: 'CARCASÍ' },
      { codigo_municipio: '68160', codigo_departamento: 68, nombre_municipio: 'CEPITÁ' },
      { codigo_municipio: '68162', codigo_departamento: 68, nombre_municipio: 'CERRITO' },
      { codigo_municipio: '68167', codigo_departamento: 68, nombre_municipio: 'CHARALÁ' },
      { codigo_municipio: '68169', codigo_departamento: 68, nombre_municipio: 'CHARTA' },
      { codigo_municipio: '68176', codigo_departamento: 68, nombre_municipio: 'CHIMA' },
      { codigo_municipio: '68179', codigo_departamento: 68, nombre_municipio: 'CHIPATÁ' },
      { codigo_municipio: '68190', codigo_departamento: 68, nombre_municipio: 'CIMITARRA' },
      { codigo_municipio: '68207', codigo_departamento: 68, nombre_municipio: 'CONCEPCIÓN' },
      { codigo_municipio: '68209', codigo_departamento: 68, nombre_municipio: 'CONFINES' },
      { codigo_municipio: '68211', codigo_departamento: 68, nombre_municipio: 'CONTRATACIÓN' },
      { codigo_municipio: '68217', codigo_departamento: 68, nombre_municipio: 'COROMORO' },
      { codigo_municipio: '68229', codigo_departamento: 68, nombre_municipio: 'CURITÍ' },
      { codigo_municipio: '68235', codigo_departamento: 68, nombre_municipio: 'EL CARMEN DE CHUCURÍ' },
      { codigo_municipio: '68245', codigo_departamento: 68, nombre_municipio: 'EL GUACAMAYO' },
      { codigo_municipio: '68250', codigo_departamento: 68, nombre_municipio: 'EL PEÑÓN' },
      { codigo_municipio: '68255', codigo_departamento: 68, nombre_municipio: 'EL PLAYÓN' },
      { codigo_municipio: '68264', codigo_departamento: 68, nombre_municipio: 'ENCINO' },
      { codigo_municipio: '68266', codigo_departamento: 68, nombre_municipio: 'ENCISO' },
      { codigo_municipio: '68271', codigo_departamento: 68, nombre_municipio: 'FLORIÁN' },
      { codigo_municipio: '68276', codigo_departamento: 68, nombre_municipio: 'FLORIDABLANCA' },
      { codigo_municipio: '68296', codigo_departamento: 68, nombre_municipio: 'GALÁN' },
      { codigo_municipio: '68298', codigo_departamento: 68, nombre_municipio: 'GÁMBITA' },
      { codigo_municipio: '68307', codigo_departamento: 68, nombre_municipio: 'GIRÓN' },
      { codigo_municipio: '68318', codigo_departamento: 68, nombre_municipio: 'GUACA' },
      { codigo_municipio: '68320', codigo_departamento: 68, nombre_municipio: 'GUADALUPE' },
      { codigo_municipio: '68322', codigo_departamento: 68, nombre_municipio: 'GUAPOTÁ' },
      { codigo_municipio: '68324', codigo_departamento: 68, nombre_municipio: 'GUAVATÁ' },
      { codigo_municipio: '68327', codigo_departamento: 68, nombre_municipio: 'GÜEPSA' },
      { codigo_municipio: '68344', codigo_departamento: 68, nombre_municipio: 'HATO' },
      { codigo_municipio: '68368', codigo_departamento: 68, nombre_municipio: 'JESÚS MARÍA' },
      { codigo_municipio: '68370', codigo_departamento: 68, nombre_municipio: 'JORDÁN' },
      { codigo_municipio: '68377', codigo_departamento: 68, nombre_municipio: 'LA BELLEZA' },
      { codigo_municipio: '68385', codigo_departamento: 68, nombre_municipio: 'LANDÁZURI' },
      { codigo_municipio: '68397', codigo_departamento: 68, nombre_municipio: 'LA PAZ' },
      { codigo_municipio: '68406', codigo_departamento: 68, nombre_municipio: 'LEBRIJA' },
      { codigo_municipio: '68418', codigo_departamento: 68, nombre_municipio: 'LOS SANTOS' },
      { codigo_municipio: '68425', codigo_departamento: 68, nombre_municipio: 'MACARAVITA' },
      { codigo_municipio: '68432', codigo_departamento: 68, nombre_municipio: 'MÁLAGA' },
      { codigo_municipio: '68444', codigo_departamento: 68, nombre_municipio: 'MATANZA' },
      { codigo_municipio: '68464', codigo_departamento: 68, nombre_municipio: 'MOGOTES' },
      { codigo_municipio: '68468', codigo_departamento: 68, nombre_municipio: 'MOLAGAVITA' },
      { codigo_municipio: '68498', codigo_departamento: 68, nombre_municipio: 'OCAMONTE' },
      { codigo_municipio: '68500', codigo_departamento: 68, nombre_municipio: 'OIBA' },
      { codigo_municipio: '68502', codigo_departamento: 68, nombre_municipio: 'ONZAGA' },
      { codigo_municipio: '68522', codigo_departamento: 68, nombre_municipio: 'PALMAR' },
      { codigo_municipio: '68524', codigo_departamento: 68, nombre_municipio: 'PALMAS DEL SOCORRO' },
      { codigo_municipio: '68533', codigo_departamento: 68, nombre_municipio: 'PÁRAMO' },
      { codigo_municipio: '68547', codigo_departamento: 68, nombre_municipio: 'PIEDECUESTA' },
      { codigo_municipio: '68549', codigo_departamento: 68, nombre_municipio: 'PINCHOTE' },
      { codigo_municipio: '68572', codigo_departamento: 68, nombre_municipio: 'PUENTE NACIONAL' },
      { codigo_municipio: '68573', codigo_departamento: 68, nombre_municipio: 'PUERTO PARRA' },
      { codigo_municipio: '68575', codigo_departamento: 68, nombre_municipio: 'PUERTO WILCHES' },
      { codigo_municipio: '68615', codigo_departamento: 68, nombre_municipio: 'RIONEGRO' },
      { codigo_municipio: '68655', codigo_departamento: 68, nombre_municipio: 'SABANA DE TORRES' },
      { codigo_municipio: '68669', codigo_departamento: 68, nombre_municipio: 'SAN ANDRÉS' },
      { codigo_municipio: '68673', codigo_departamento: 68, nombre_municipio: 'SAN BENITO' },
      { codigo_municipio: '68679', codigo_departamento: 68, nombre_municipio: 'SAN GIL' },
      { codigo_municipio: '68682', codigo_departamento: 68, nombre_municipio: 'SAN JOAQUÍN' },
      { codigo_municipio: '68684', codigo_departamento: 68, nombre_municipio: 'SAN JOSÉ DE MIRANDA' },
      { codigo_municipio: '68686', codigo_departamento: 68, nombre_municipio: 'SAN MIGUEL' },
      { codigo_municipio: '68689', codigo_departamento: 68, nombre_municipio: 'SAN VICENTE DE CHUCURÍ' },
      { codigo_municipio: '68705', codigo_departamento: 68, nombre_municipio: 'SANTA BÁRBARA' },
      { codigo_municipio: '68720', codigo_departamento: 68, nombre_municipio: 'SANTA HELENA DEL OPÓN' },
      { codigo_municipio: '68745', codigo_departamento: 68, nombre_municipio: 'SIMACOTA' },
      { codigo_municipio: '68755', codigo_departamento: 68, nombre_municipio: 'SOCORRO' },
      { codigo_municipio: '68770', codigo_departamento: 68, nombre_municipio: 'SUAITA' },
      { codigo_municipio: '68773', codigo_departamento: 68, nombre_municipio: 'SUCRE' },
      { codigo_municipio: '68780', codigo_departamento: 68, nombre_municipio: 'SURATÁ' },
      { codigo_municipio: '68820', codigo_departamento: 68, nombre_municipio: 'TONA' },
      { codigo_municipio: '68855', codigo_departamento: 68, nombre_municipio: 'VALLE DE SAN JOSÉ' },
      { codigo_municipio: '68861', codigo_departamento: 68, nombre_municipio: 'VÉLEZ' },
      { codigo_municipio: '68867', codigo_departamento: 68, nombre_municipio: 'VETAS' },
      { codigo_municipio: '68872', codigo_departamento: 68, nombre_municipio: 'VILLANUEVA' },
      { codigo_municipio: '68895', codigo_departamento: 68, nombre_municipio: 'ZAPATOCA' },
      
      // Municipios de Sucre
      { codigo_municipio: '70001', codigo_departamento: 70, nombre_municipio: 'SINCELEJO' },
      { codigo_municipio: '70110', codigo_departamento: 70, nombre_municipio: 'BUENAVISTA' },
      { codigo_municipio: '70124', codigo_departamento: 70, nombre_municipio: 'CAIMITO' },
      { codigo_municipio: '70204', codigo_departamento: 70, nombre_municipio: 'COLOSÓ' },
      { codigo_municipio: '70215', codigo_departamento: 70, nombre_municipio: 'COROZAL' },
      { codigo_municipio: '70221', codigo_departamento: 70, nombre_municipio: 'COVEÑAS' },
      { codigo_municipio: '70230', codigo_departamento: 70, nombre_municipio: 'CHALÁN' },
      { codigo_municipio: '70233', codigo_departamento: 70, nombre_municipio: 'EL ROBLE' },
      { codigo_municipio: '70235', codigo_departamento: 70, nombre_municipio: 'GALERAS' },
      { codigo_municipio: '70265', codigo_departamento: 70, nombre_municipio: 'GUARANDA' },
      { codigo_municipio: '70400', codigo_departamento: 70, nombre_municipio: 'LA UNIÓN' },
      { codigo_municipio: '70418', codigo_departamento: 70, nombre_municipio: 'LOS PALMITOS' },
      { codigo_municipio: '70429', codigo_departamento: 70, nombre_municipio: 'MAJAGUAL' },
      { codigo_municipio: '70473', codigo_departamento: 70, nombre_municipio: 'MORROA' },
      { codigo_municipio: '70508', codigo_departamento: 70, nombre_municipio: 'OVEJAS' },
      { codigo_municipio: '70523', codigo_departamento: 70, nombre_municipio: 'PALMITO' },
      { codigo_municipio: '70670', codigo_departamento: 70, nombre_municipio: 'SAMPUÉS' },
      { codigo_municipio: '70678', codigo_departamento: 70, nombre_municipio: 'SAN BENITO ABAD' },
      { codigo_municipio: '70690', codigo_departamento: 70, nombre_municipio: 'SAN JUAN DE BETULIA' },
      { codigo_municipio: '70702', codigo_departamento: 70, nombre_municipio: 'SAN MARCOS' },
      { codigo_municipio: '70708', codigo_departamento: 70, nombre_municipio: 'SAN ONOFRE' },
      { codigo_municipio: '70713', codigo_departamento: 70, nombre_municipio: 'SAN PEDRO' },
      { codigo_municipio: '70717', codigo_departamento: 70, nombre_municipio: 'SANTIAGO DE TOLÚ' },
      { codigo_municipio: '70742', codigo_departamento: 70, nombre_municipio: 'SINCÉ' },
      { codigo_municipio: '70771', codigo_departamento: 70, nombre_municipio: 'SUCRE' },
      { codigo_municipio: '70820', codigo_departamento: 70, nombre_municipio: 'TOLÚ VIEJO' },
      
      // Municipios de Tolima
      { codigo_municipio: '73001', codigo_departamento: 73, nombre_municipio: 'IBAGUÉ' },
      { codigo_municipio: '73024', codigo_departamento: 73, nombre_municipio: 'ALPUJARRA' },
      { codigo_municipio: '73026', codigo_departamento: 73, nombre_municipio: 'ALVARADO' },
      { codigo_municipio: '73030', codigo_departamento: 73, nombre_municipio: 'AMBALEMA' },
      { codigo_municipio: '73043', codigo_departamento: 73, nombre_municipio: 'ANZOÁTEGUI' },
      { codigo_municipio: '73055', codigo_departamento: 73, nombre_municipio: 'ARMERO' },
      { codigo_municipio: '73067', codigo_departamento: 73, nombre_municipio: 'ATACO' },
      { codigo_municipio: '73124', codigo_departamento: 73, nombre_municipio: 'CAJAMARCA' },
      { codigo_municipio: '73148', codigo_departamento: 73, nombre_municipio: 'CARMEN DE APICALÁ' },
      { codigo_municipio: '73152', codigo_departamento: 73, nombre_municipio: 'CASABIANCA' },
      { codigo_municipio: '73168', codigo_departamento: 73, nombre_municipio: 'CHAPARRAL' },
      { codigo_municipio: '73200', codigo_departamento: 73, nombre_municipio: 'COELLO' },
      { codigo_municipio: '73217', codigo_departamento: 73, nombre_municipio: 'COYAIMA' },
      { codigo_municipio: '73226', codigo_departamento: 73, nombre_municipio: 'CUNDAY' },
      { codigo_municipio: '73236', codigo_departamento: 73, nombre_municipio: 'DOLORES' },
      { codigo_municipio: '73268', codigo_departamento: 73, nombre_municipio: 'ESPINAL' },
      { codigo_municipio: '73270', codigo_departamento: 73, nombre_municipio: 'FALAN' },
      { codigo_municipio: '73275', codigo_departamento: 73, nombre_municipio: 'FLANDES' },
      { codigo_municipio: '73283', codigo_departamento: 73, nombre_municipio: 'FRESNO' },
      { codigo_municipio: '73319', codigo_departamento: 73, nombre_municipio: 'GUAMO' },
      { codigo_municipio: '73347', codigo_departamento: 73, nombre_municipio: 'HERVEO' },
      { codigo_municipio: '73349', codigo_departamento: 73, nombre_municipio: 'HONDA' },
      { codigo_municipio: '73352', codigo_departamento: 73, nombre_municipio: 'ICONONZO' },
      { codigo_municipio: '73408', codigo_departamento: 73, nombre_municipio: 'LÉRIDA' },
      { codigo_municipio: '73411', codigo_departamento: 73, nombre_municipio: 'LÍBANO' },
      { codigo_municipio: '73443', codigo_departamento: 73, nombre_municipio: 'MARIQUITA' },
      { codigo_municipio: '73449', codigo_departamento: 73, nombre_municipio: 'MELGAR' },
      { codigo_municipio: '73461', codigo_departamento: 73, nombre_municipio: 'MURILLO' },
      { codigo_municipio: '73483', codigo_departamento: 73, nombre_municipio: 'NATAGAIMA' },
      { codigo_municipio: '73504', codigo_departamento: 73, nombre_municipio: 'ORTEGA' },
      { codigo_municipio: '73520', codigo_departamento: 73, nombre_municipio: 'PALOCABILDO' },
      { codigo_municipio: '73547', codigo_departamento: 73, nombre_municipio: 'PIEDRAS' },
      { codigo_municipio: '73555', codigo_departamento: 73, nombre_municipio: 'PLANADAS' },
      { codigo_municipio: '73563', codigo_departamento: 73, nombre_municipio: 'PRADO' },
      { codigo_municipio: '73585', codigo_departamento: 73, nombre_municipio: 'PURIFICACIÓN' },
      { codigo_municipio: '73616', codigo_departamento: 73, nombre_municipio: 'RIOBLANCO' },
      { codigo_municipio: '73622', codigo_departamento: 73, nombre_municipio: 'RONCESVALLES' },
      { codigo_municipio: '73624', codigo_departamento: 73, nombre_municipio: 'ROVIRA' },
      { codigo_municipio: '73671', codigo_departamento: 73, nombre_municipio: 'SALDAÑA' },
      { codigo_municipio: '73675', codigo_departamento: 73, nombre_municipio: 'SAN ANTONIO' },
      { codigo_municipio: '73678', codigo_departamento: 73, nombre_municipio: 'SAN LUIS' },
      { codigo_municipio: '73686', codigo_departamento: 73, nombre_municipio: 'SANTA ISABEL' },
      { codigo_municipio: '73770', codigo_departamento: 73, nombre_municipio: 'SUÁREZ' },
      { codigo_municipio: '73854', codigo_departamento: 73, nombre_municipio: 'VALLE DE SAN JUAN' },
      { codigo_municipio: '73861', codigo_departamento: 73, nombre_municipio: 'VENADILLO' },
      { codigo_municipio: '73870', codigo_departamento: 73, nombre_municipio: 'VILLAHERMOSA' },
      { codigo_municipio: '73873', codigo_departamento: 73, nombre_municipio: 'VILLARRICA' },
      
      // Municipios de Valle del Cauca
      { codigo_municipio: '76001', codigo_departamento: 76, nombre_municipio: 'CALI' },
      { codigo_municipio: '76020', codigo_departamento: 76, nombre_municipio: 'ALCALÁ' },
      { codigo_municipio: '76036', codigo_departamento: 76, nombre_municipio: 'ANDALUCÍA' },
      { codigo_municipio: '76041', codigo_departamento: 76, nombre_municipio: 'ANSERMANUEVO' },
      { codigo_municipio: '76054', codigo_departamento: 76, nombre_municipio: 'ARGELIA' },
      { codigo_municipio: '76100', codigo_departamento: 76, nombre_municipio: 'BOLÍVAR' },
      { codigo_municipio: '76109', codigo_departamento: 76, nombre_municipio: 'BUENAVENTURA' },
      { codigo_municipio: '76111', codigo_departamento: 76, nombre_municipio: 'GUADALAJARA DE BUGA' },
      { codigo_municipio: '76113', codigo_departamento: 76, nombre_municipio: 'BUGALAGRANDE' },
      { codigo_municipio: '76122', codigo_departamento: 76, nombre_municipio: 'CAICEDONIA' },
      { codigo_municipio: '76126', codigo_departamento: 76, nombre_municipio: 'CALIMA' },
      { codigo_municipio: '76130', codigo_departamento: 76, nombre_municipio: 'CANDELARIA' },
      { codigo_municipio: '76147', codigo_departamento: 76, nombre_municipio: 'CARTAGO' },
      { codigo_municipio: '76233', codigo_departamento: 76, nombre_municipio: 'DAGUA' },
      { codigo_municipio: '76243', codigo_departamento: 76, nombre_municipio: 'EL ÁGUILA' },
      { codigo_municipio: '76246', codigo_departamento: 76, nombre_municipio: 'EL CAIRO' },
      { codigo_municipio: '76248', codigo_departamento: 76, nombre_municipio: 'EL CERRITO' },
      { codigo_municipio: '76250', codigo_departamento: 76, nombre_municipio: 'EL DOVIO' },
      { codigo_municipio: '76275', codigo_departamento: 76, nombre_municipio: 'FLORIDA' },
      { codigo_municipio: '76306', codigo_departamento: 76, nombre_municipio: 'GINEBRA' },
      { codigo_municipio: '76318', codigo_departamento: 76, nombre_municipio: 'GUACARÍ' },
      { codigo_municipio: '76364', codigo_departamento: 76, nombre_municipio: 'JAMUNDÍ' },
      { codigo_municipio: '76377', codigo_departamento: 76, nombre_municipio: 'LA CUMBRE' },
      { codigo_municipio: '76400', codigo_departamento: 76, nombre_municipio: 'LA UNIÓN' },
      { codigo_municipio: '76403', codigo_departamento: 76, nombre_municipio: 'LA VICTORIA' },
      { codigo_municipio: '76497', codigo_departamento: 76, nombre_municipio: 'OBANDO' },
      { codigo_municipio: '76520', codigo_departamento: 76, nombre_municipio: 'PALMIRA' },
      { codigo_municipio: '76563', codigo_departamento: 76, nombre_municipio: 'PRADERA' },
      { codigo_municipio: '76606', codigo_departamento: 76, nombre_municipio: 'RESTREPO' },
      { codigo_municipio: '76616', codigo_departamento: 76, nombre_municipio: 'RIOFRÍO' },
      { codigo_municipio: '76622', codigo_departamento: 76, nombre_municipio: 'ROLDANILLO' },
      { codigo_municipio: '76670', codigo_departamento: 76, nombre_municipio: 'SAN PEDRO' },
      { codigo_municipio: '76736', codigo_departamento: 76, nombre_municipio: 'SEVILLA' },
      { codigo_municipio: '76823', codigo_departamento: 76, nombre_municipio: 'TORO' },
      { codigo_municipio: '76828', codigo_departamento: 76, nombre_municipio: 'TRUJILLO' },
      { codigo_municipio: '76834', codigo_departamento: 76, nombre_municipio: 'TULUÁ' },
      { codigo_municipio: '76845', codigo_departamento: 76, nombre_municipio: 'ULLOA' },
      { codigo_municipio: '76863', codigo_departamento: 76, nombre_municipio: 'VERSALLES' },
      { codigo_municipio: '76869', codigo_departamento: 76, nombre_municipio: 'VIJES' },
      { codigo_municipio: '76890', codigo_departamento: 76, nombre_municipio: 'YOTOCO' },
      { codigo_municipio: '76892', codigo_departamento: 76, nombre_municipio: 'YUMBO' },
      { codigo_municipio: '76895', codigo_departamento: 76, nombre_municipio: 'ZARZAL' },
      
      // Municipios de Arauca
      { codigo_municipio: '81001', codigo_departamento: 81, nombre_municipio: 'ARAUCA' },
      { codigo_municipio: '81065', codigo_departamento: 81, nombre_municipio: 'ARAUQUITA' },
      { codigo_municipio: '81220', codigo_departamento: 81, nombre_municipio: 'CRAVO NORTE' },
      { codigo_municipio: '81300', codigo_departamento: 81, nombre_municipio: 'FORTUL' },
      { codigo_municipio: '81591', codigo_departamento: 81, nombre_municipio: 'PUERTO RONDÓN' },
      { codigo_municipio: '81736', codigo_departamento: 81, nombre_municipio: 'SARAVENA' },
      { codigo_municipio: '81794', codigo_departamento: 81, nombre_municipio: 'TAME' },
      
      // Municipios de Casanare
      { codigo_municipio: '85001', codigo_departamento: 85, nombre_municipio: 'YOPAL' },
      { codigo_municipio: '85010', codigo_departamento: 85, nombre_municipio: 'AGUAZUL' },
      { codigo_municipio: '85015', codigo_departamento: 85, nombre_municipio: 'CHÁMEZA' },
      { codigo_municipio: '85125', codigo_departamento: 85, nombre_municipio: 'HATO COROZAL' },
      { codigo_municipio: '85136', codigo_departamento: 85, nombre_municipio: 'LA SALINA' },
      { codigo_municipio: '85139', codigo_departamento: 85, nombre_municipio: 'MANÍ' },
      { codigo_municipio: '85162', codigo_departamento: 85, nombre_municipio: 'MONTERREY' },
      { codigo_municipio: '85225', codigo_departamento: 85, nombre_municipio: 'NUNCHÍA' },
      { codigo_municipio: '85230', codigo_departamento: 85, nombre_municipio: 'OROCUÉ' },
      { codigo_municipio: '85250', codigo_departamento: 85, nombre_municipio: 'PAZ DE ARIPORO' },
      { codigo_municipio: '85263', codigo_departamento: 85, nombre_municipio: 'PORE' },
      { codigo_municipio: '85279', codigo_departamento: 85, nombre_municipio: 'RECETOR' },
      { codigo_municipio: '85300', codigo_departamento: 85, nombre_municipio: 'SABANALARGA' },
      { codigo_municipio: '85315', codigo_departamento: 85, nombre_municipio: 'SÁCAMA' },
      { codigo_municipio: '85325', codigo_departamento: 85, nombre_municipio: 'SAN LUIS DE PALENQUE' },
      { codigo_municipio: '85400', codigo_departamento: 85, nombre_municipio: 'TÁMARA' },
      { codigo_municipio: '85410', codigo_departamento: 85, nombre_municipio: 'TAURAMENA' },
      { codigo_municipio: '85430', codigo_departamento: 85, nombre_municipio: 'TRINIDAD' },
      { codigo_municipio: '85440', codigo_departamento: 85, nombre_municipio: 'VILLANUEVA' },
      
      // Municipios de Putumayo
      { codigo_municipio: '86001', codigo_departamento: 86, nombre_municipio: 'MOCOA' },
      { codigo_municipio: '86219', codigo_departamento: 86, nombre_municipio: 'COLÓN' },
      { codigo_municipio: '86320', codigo_departamento: 86, nombre_municipio: 'ORITO' },
      { codigo_municipio: '86568', codigo_departamento: 86, nombre_municipio: 'PUERTO ASÍS' },
      { codigo_municipio: '86569', codigo_departamento: 86, nombre_municipio: 'PUERTO CAICEDO' },
      { codigo_municipio: '86571', codigo_departamento: 86, nombre_municipio: 'PUERTO GUZMÁN' },
      { codigo_municipio: '86573', codigo_departamento: 86, nombre_municipio: 'LEGUIZAMO' },
      { codigo_municipio: '86749', codigo_departamento: 86, nombre_municipio: 'SIBUNDOY' },
      { codigo_municipio: '86755', codigo_departamento: 86, nombre_municipio: 'SAN FRANCISCO' },
      { codigo_municipio: '86757', codigo_departamento: 86, nombre_municipio: 'SAN MIGUEL' },
      { codigo_municipio: '86760', codigo_departamento: 86, nombre_municipio: 'SANTIAGO' },
      { codigo_municipio: '86865', codigo_departamento: 86, nombre_municipio: 'VALLE DEL GUAMUEZ' },
      { codigo_municipio: '86885', codigo_departamento: 86, nombre_municipio: 'VILLAGARZÓN' },
      
      // Municipios de San Andrés
      { codigo_municipio: '88001', codigo_departamento: 88, nombre_municipio: 'SAN ANDRÉS' },
      { codigo_municipio: '88564', codigo_departamento: 88, nombre_municipio: 'PROVIDENCIA' },
      
      // Municipios de Amazonas
      { codigo_municipio: '91001', codigo_departamento: 91, nombre_municipio: 'LETICIA' },
      { codigo_municipio: '91263', codigo_departamento: 91, nombre_municipio: 'EL ENCANTO' },
      { codigo_municipio: '91405', codigo_departamento: 91, nombre_municipio: 'LA CHORRERA' },
      { codigo_municipio: '91407', codigo_departamento: 91, nombre_municipio: 'LA PEDRERA' },
      { codigo_municipio: '91430', codigo_departamento: 91, nombre_municipio: 'LA VICTORIA' },
      { codigo_municipio: '91460', codigo_departamento: 91, nombre_municipio: 'MIRITI - PARANÁ' },
      { codigo_municipio: '91530', codigo_departamento: 91, nombre_municipio: 'PUERTO ALEGRÍA' },
      { codigo_municipio: '91536', codigo_departamento: 91, nombre_municipio: 'PUERTO ARICA' },
      { codigo_municipio: '91540', codigo_departamento: 91, nombre_municipio: 'PUERTO NARIÑO' },
      { codigo_municipio: '91669', codigo_departamento: 91, nombre_municipio: 'PUERTO SANTANDER' },
      { codigo_municipio: '91798', codigo_departamento: 91, nombre_municipio: 'TARAPACÁ' },
      
      // Municipios de Guainía
      { codigo_municipio: '94001', codigo_departamento: 94, nombre_municipio: 'INÍRIDA' },
      { codigo_municipio: '94343', codigo_departamento: 94, nombre_municipio: 'BARRANCO MINAS' },
      { codigo_municipio: '94663', codigo_departamento: 94, nombre_municipio: 'MAPIRIPANA' },
      { codigo_municipio: '94883', codigo_departamento: 94, nombre_municipio: 'SAN FELIPE' },
      { codigo_municipio: '94884', codigo_departamento: 94, nombre_municipio: 'PUERTO COLOMBIA' },
      { codigo_municipio: '94885', codigo_departamento: 94, nombre_municipio: 'LA GUADALUPE' },
      { codigo_municipio: '94886', codigo_departamento: 94, nombre_municipio: 'CACAHUAL' },
      { codigo_municipio: '94887', codigo_departamento: 94, nombre_municipio: 'PANA PANA' },
      { codigo_municipio: '94888', codigo_departamento: 94, nombre_municipio: 'MORICHAL' },
      
      // Municipios de Guaviare
      { codigo_municipio: '95001', codigo_departamento: 95, nombre_municipio: 'SAN JOSÉ DEL GUAVIARE' },
      { codigo_municipio: '95015', codigo_departamento: 95, nombre_municipio: 'CALAMAR' },
      { codigo_municipio: '95025', codigo_departamento: 95, nombre_municipio: 'EL RETORNO' },
      { codigo_municipio: '95200', codigo_departamento: 95, nombre_municipio: 'MIRAFLORES' },
      
      // Municipios de Vaupés
      { codigo_municipio: '97001', codigo_departamento: 97, nombre_municipio: 'MITÚ' },
      { codigo_municipio: '97161', codigo_departamento: 97, nombre_municipio: 'CARURU' },
      { codigo_municipio: '97511', codigo_departamento: 97, nombre_municipio: 'PACOA' },
      { codigo_municipio: '97666', codigo_departamento: 97, nombre_municipio: 'TARAIRA' },
      { codigo_municipio: '97777', codigo_departamento: 97, nombre_municipio: 'PAPUNAUA' },
      { codigo_municipio: '97889', codigo_departamento: 97, nombre_municipio: 'YAVARATÉ' },
      
      // Municipios de Vichada
      { codigo_municipio: '99001', codigo_departamento: 99, nombre_municipio: 'PUERTO CARREÑO' },
      { codigo_municipio: '99524', codigo_departamento: 99, nombre_municipio: 'LA PRIMAVERA' },
      { codigo_municipio: '99624', codigo_departamento: 99, nombre_municipio: 'SANTA ROSALÍA' },
      { codigo_municipio: '99773', codigo_departamento: 99, nombre_municipio: 'CUMARIBO' }
    ]);

    municipios.value.sort((a, b) => a.nombre_municipio.localeCompare(b.nombre_municipio, 'es', { sensitivity: 'base' }));

    let ListaSubdirectivas = ref([]);

    /* Información Personal Usuarios */
    let usu_dat_pers = ref({
      id_afiliado: '',
      tipo_id: '',        
      nombre: '',        
      fecha_nac: '',  /*date*/
      edad: '', /*int*/
      sexo: '',        
      telefono: '',
      direccion: '',
      depto: '',
      municipio: '',
      email: '',        
      rol: '',
      subdirectiva: '',
      passwd: '',
    });

    /* Información Afiliado*/
    let datos_afi = ref({
      fecha_afi: '',  /*date*/   //Nuevo
      cod_interno: '',
      est_civil: '',
      grupo_sang: '',        
      empresa: '',
      fecha_vinc: '',  /*date*/
      tipo_contrato: '',
      cargo: '',
      area: '',      //Nuevo
      depto_labor: '',
      municipio_labor: '',
      tiempo_vinc: '',  /*int*/
      empl_activo: '',        
      fecha_ret: '',  /*date*/
      motivo_ret: '',
    });

    /* Protección de Datos */
    let trata_datos = ref({
      autorizacion: '',
    });

    async function listarSubdirectivas(){

      try {
        /* Información Subdirectivas */
        let url = `${cnxsrv.url}/api/SrvGestionSubdirectivas/consultarListaSubdirectivas`;
        let respuesta = await axios.get(url);
        let data = respuesta.data;        

        if(data.body.length != 0){
          if (respuesta.status == 200) {
            console.log('Información subdirectivas OK');
            
            ListaSubdirectivas.value = data.body;            
            console.log('ListaSubdirectivas.value:', ListaSubdirectivas.value);
            
          } else {
            console.error(`Error al buscar la información de las subdirectivas`, respuesta.statusText);
          }
        } else {console.log('No hay subdirectivas');}

      } catch (error) {
        console.error(error);
      }
    }

    const recargarComponente = () => {
      renderizado.value++;
    }      

    function limpiarCampos() {
  
      usu_dat_pers.value = {
        id_afiliado: '',
        tipo_id: '',        
        nombre: '',        
        fecha_nac: '',  /*date*/
        edad: '', /*int*/
        sexo: '',        
        telefono: '',
        direccion: '',
        depto: '',
        municipio: '',
        email: '',        
        rol: '',
        subdirectiva: '',
        passwd: '',
      };
    
      datos_afi.value = {
        fecha_afi: '',  /*date*/   //Nuevo
        cod_interno: '',
        est_civil: '',
        grupo_sang: '',        
        empresa: '',
        fecha_vinc: '',  /*date*/
        tipo_contrato: '',
        cargo: '',
        area: '',      //Nuevo
        depto_labor: '',
        municipio_labor: '',
        tiempo_vinc: '',  /*int*/
        empl_activo: '',        
        fecha_ret: '',  /*date*/
        motivo_ret: '',        
      };

      trata_datos.value = {
        autorizacion: '',
      };
    }

    async function descargar(afiliado){
      try {
        /* Reportes */
        let usuario = Usuario.value.usuario;
        let subdirectiva = Usuario.value.subdirectiva;
        let fecha_actual = new Date().toLocaleString().replace(/[/:]/g, '-').replace(/,/g, '_').replace(/ /g, '').replace(/. /g, '');
        console.log('fecha_actual:', fecha_actual); 

        let parametros = { usuario, subdirectiva, afiliado };

        let url = `${cnxsrv.url}/api/SrvReportes/generarFormatoAfiliacion/`;
        let respuesta = await axios.get(url, { params: parametros, responseType: 'blob' });
        let data = respuesta.data; 

        console.log('respuesta:', respuesta);            

        if(data.length != 0){
          if (respuesta.status == 200) {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            console.log('blob:', blob);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Reporte_Afiliacion_${afiliado}_${fecha_actual}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error(`Error al buscar la información del reporte`, respuesta.statusText);
          }

        } else {console.log('No hay datos para el reporte');}

      } catch (error) {
        console.error(error);
      }
    };

    async function insertarLog(log){ 
      try {
        let url = `${cnxsrv.url}/api/SrvGestionAdmin/insertarLog`;
        let respuesta = await axios.post(url, log);
        if (respuesta.status != 201) {
          console.error(`Error al insertar log:`, respuesta.statusText);
        } else {console.log('Log insertado');}
      } catch (error) {
        console.error(error);
      }  
    }

    async function handleSubmit(){

      try {        
        /* ---------------------- */
        console.log('INICIANDO CREACIÓN');
        /* ---------------------- */

        let url = null;
        let respuesta = null;
        let band = true;    

        /* ----------- TODOS LOS ROLES ----------- */
        /* Información Usuario */
        let usuario =  {  
          usuario: usu_dat_pers.value.id_afiliado,
          passwd: usu_dat_pers.value.passwd,
          rol: usu_dat_pers.value.rol,
          subdirectiva: usu_dat_pers.value.subdirectiva,
          activo: 1, //Usuario Activo
        };
        console.log('usuario:', usuario);

        /* Protección de Datos */
        let afi_trata_datos = {
          id_afiliado: usu_dat_pers.value.id_afiliado,
          autorizacion: trata_datos.value.autorizacion,
        };
        console.log('afi_trata_datos:', afi_trata_datos);

        /* ---------------------- */
        url = `${cnxsrv.url}/api/SrvGestionAdmin/crearUsuario`;
        respuesta = await axios.post(url, usuario);
        if (respuesta.status != 201) {
          console.error(`Error al crear la información base del(a) afiliado(a) ${usuario.usuario}:`, respuesta.statusText);
          band = false;
        } else {console.log('Información base de usuario creada');}

        /* ---------------------- */
        url = `${cnxsrv.url}/api/SrvGestionAfiliados/crearAfiTrataDatos`;
        respuesta = await axios.post(url, afi_trata_datos);
        if (respuesta.status != 201) {
          console.error(`Error al crear la información de autorización del tratamiento de datos del(a) afiliado(a) ${usuario.usuario}:`, respuesta.statusText);
          band = false;
        } else {console.log('Información tratamiento de datos creada');}
        /* ----------- FIN TODOS LOS ROLES ----------- */


        if(usuario.rol == 1) {  /* ----------- AFILIADO ----------- */           
          /* Información Personal Afiliado */
          let usu_afi_dat_pers = {
            id_afiliado: usu_dat_pers.value.id_afiliado,
            fecha_afi: datos_afi.value.fecha_afi,   /*date*/   //Nuevo
            tipo_id: usu_dat_pers.value.tipo_id,
            nombre: usu_dat_pers.value.nombre,
            cod_interno: datos_afi.value.cod_interno,
            fecha_nac: usu_dat_pers.value.fecha_nac,  /*date*/
            edad: usu_dat_pers.value.edad, /*int*/
            sexo: usu_dat_pers.value.sexo,
            grupo_sang: datos_afi.value.grupo_sang,
            telefono: usu_dat_pers.value.telefono,
            direccion: usu_dat_pers.value.direccion,
            depto:usu_dat_pers.value.depto,
            municipio: usu_dat_pers.value.municipio,
            est_civil: datos_afi.value.est_civil,
            email: usu_dat_pers.value.email,
            nhijos: 0,
            activo: 1, //Afiliado Activo
          };
          console.log('usu_afi_dat_pers:', usu_afi_dat_pers);

          /* Información Laboral Afiliado*/
          let afi_info_lab = {
            id_afiliado: usu_dat_pers.value.id_afiliado,
            empresa: datos_afi.value.empresa,
            fecha_vinc: datos_afi.value.fecha_vinc,  /*date*/
            tipo_contrato: datos_afi.value.tipo_contrato,
            cargo: datos_afi.value.cargo,
            area: datos_afi.value.area,    //Nuevo 
            depto_labor: datos_afi.value.depto_labor,
            municipio_labor: datos_afi.value.municipio_labor,
            empl_activo: datos_afi.value.empl_activo,
            tiempo_vinc: datos_afi.value.tiempo_vinc,  /*int*/
            fecha_ret: datos_afi.value.fecha_ret,  /*date*/
            motivo_ret: datos_afi.value.motivo_ret,
            subdirectiva: usu_dat_pers.value.subdirectiva,
          };
          console.log('afi_info_lab:', afi_info_lab);    
          
          /* ---------------------- */
          url = `${cnxsrv.url}/api/SrvGestionAfiliados/crearAfiliado`;
          respuesta = await axios.post(url, usu_afi_dat_pers);
          if (respuesta.status != 201) {
            console.error(`Error al crear la información personal del(a) afiliado(a) ${usuario.usuario}:`, respuesta.statusText);
            band = false;
          } else {console.log('Información personal creada');}

          /* ---------------------- */
          url = `${cnxsrv.url}/api/SrvGestionAfiliados/crearAfiInfoLab`;
          respuesta = await axios.post(url, afi_info_lab);
          if (respuesta.status != 201) {
            console.error(`Error al crear la información laboral del(a) afiliado(a) ${usuario.usuario}:`, respuesta.statusText);
            band = false;
          } else {console.log('Información laboral creada');}

        } else {    /* ----------- SUBDIR / SINDICATO / ADMIN ----------- */      
          /* Información Personal Usuarios */
          let dat_pers = {
            id_afiliado: usu_dat_pers.value.id_afiliado,
            tipo_id: usu_dat_pers.value.tipo_id,
            nombre: usu_dat_pers.value.nombre,
            fecha_nac: usu_dat_pers.value.fecha_nac,  /*date*/
            edad: usu_dat_pers.value.edad, /*int*/
            sexo: usu_dat_pers.value.sexo,
            telefono: usu_dat_pers.value.telefono,
            direccion: usu_dat_pers.value.direccion,
            depto:usu_dat_pers.value.depto,
            municipio: usu_dat_pers.value.municipio,
            email: usu_dat_pers.value.email,
          };
          console.log('dat_pers:', dat_pers);

          url = `${cnxsrv.url}/api/SrvGestionAdmin/crearUsuarioGeneral`;
          respuesta = await axios.post(url, dat_pers);
          if (respuesta.status != 201) {
            console.error(`Error al crear la información laboral del(a) afiliado(a) ${usuario.usuario}:`, respuesta.statusText);
            band = false;
          } else {console.log('Información laboral creada');}

        }
        
        /* ---------------------- */
        if(band){
          alert('Registro creado con Éxito');
          //-------- Log Éxito
          let log = {
            id_log: null,
            usuario: Usuario.value.usuario,
            tiempo: new Date().toLocaleString(),
            tipo: 'CREACIÓN', //EDICIÓN / CREACIÓN / ELIMINACIÓN / INHABILITACIÓN
            descripcion: `Usuario crea al usuario ${usuario.usuario}`,
            resultado: 'Éxito',
          }
          insertarLog(log);
          //--------
        } else {
          //-------- Log Error 
          let log = {
            id_log: null,
            usuario: Usuario.value.usuario,
            tiempo: new Date().toLocaleString(),
            tipo: 'CREACIÓN', //EDICIÓN / CREACIÓN / ELIMINACIÓN / INHABILITACIÓN
            descripcion: `Usuario crea al usuario ${usuario.usuario}`,
            resultado: 'Error',
          }
          insertarLog(log);
          //--------
        }

        /* ---------------------- */
        console.log('CREACIÓN FINALIZADA');
        /* ---------------------- */   

        limpiarCampos();
        recargarComponente();
        console.log('usuario.rol:', usuario.rol );
        if(usuario.rol == 1){
          descargar(usuario.usuario);
        }

      } catch (error) {
        console.error(error);
      }  
    }

    const retornar = () => {
        store.dispatch('actualizarAppIniciada', true);
        store.dispatch('actualizarVistaInicial', true);
        recargarComponente();
      };

    onMounted(listarSubdirectivas);

    return {
      renderizado,
      isEditing,
      verpass,
      departamentos,
      municipios,
      ListaSubdirectivas,
      usu_dat_pers,
      datos_afi,
      trata_datos,
      recargarComponente,
      limpiarCampos,
      handleSubmit,
      retornar,
    };
  },
  
  computed: {
    AppIniciada() {
        return this.$store.state.AppIniciada;
    },

    VistaInicial() {
      return this.$store.state.VistaInicial;
    },
    
    Usuario() {
          return this.$store.state.Usuario;
      }
    },

}
</script>

<style scoped>

  #crearUsuario {
    color:#025920;
    background-color: #E1F2E7;
    width: 100%;
    /* display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(4, auto);

    grid-template-areas:    "H H H H"                             
                            "M M M M"
                            "M M M M"
                            "F F F F"; */
  }

  #h4 {
    color:#025920;
    font-weight: bold;
    padding: 30px 6px 20px 6px;  /*  arriba | derecha | abajo | izquierda */
  }

  #sform {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 20% 20px 20%;  /*  arriba | derecha | abajo | izquierda */
  }

  form {
    color:#025920;
    background-color: #B8CFBE;
    border-color:#025920;
    border-width: 2px;
    border-radius: 10px;
    text-align: center;
    padding: 40px;
  }

  table {
    border-collapse: separate; 
    border-spacing: 2px;
    width: 100%;
    /* border:solid; */
    /* margin: 5px; 
    padding-left: 5px;
    padding-right: 5px;  */
  }

  .celdaizq{
    text-align: left;
  }

  .lbl {
    color:#007D39;
    font-size: 12pt;
    font-weight: 600;
  }

  .inp {
    width: 100%; /*#4A4F4B*/
    height: 30px;
    font-size: 12pt;
    font-weight: 500;
    color: #025920;
    background-color: #E1F2E7;
    border: double;
    border-color: #025920;
    box-shadow: 3px 3px 5px -2px #737A75 inset; 
    border-radius: 5px;
    padding-left: 10px;
    margin-left: 5px;
    text-align: left;
  }

  .boton {
    width: 100px;
    padding: 2px 6px 2px 6px;  /* arriba | derecha | abajo | izquierda */
    border: solid;
    border-color: #02732A;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px #025920;
    color: #025920;
    font-size: 10pt;
    font-weight: bold;
    background-color: #C2F2D3;
  }

  .boton:hover {
    border-color: #C2F2D3;
    color: #C2F2D3;
    background-color: #007D39;
  }

  #lblbtn {
    width: 40px;
  }

  #protecdatos{
    margin: 0px 0px 30px 0px;  /*  arriba | derecha | abajo | izquierda */
    width: 100%;
  }

  #p1{
    text-align: justify;
  }

  /* ----- */
  @media screen and (max-width: 768px) {

    #sform {
      margin: 0px 3% 20px 3%;  /*  arriba | derecha | abajo | izquierda */
    }
  }

  @media screen and (max-width: 489px) {

    #lblbtn {
      width: 20px;
    }
  }

</style>
