<template>
  <div id="usuarioActivo" :key="renderizado">

    <h4 id="h4">MI PERFIL</h4>
    <section id="sform">
      <form @submit.prevent="handleSubmit">
        <table>
          <tr>
            <td class="celdaizq"><label for="" class="lbl">Rol: </label></td>
            <td>
              <select id="sel12" class="inp" v-model="dat_pers.rol" disabled>
                <option value=""></option>
                <option value="1">AFILIADO</option>
                <option value="2">ADMIN SUBDIRECTIVA</option>
                <option value="3">ADMIN SINDICATO</option>
                <option value="4">ADMIN GENERAL</option>                
              </select>
            </td>
          </tr>
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Subdirectiva: </label></td>
            <td><input id="" class="inp" type="text" v-model="dat_pers.subdirectiva" disabled></td>              
          </tr>
          
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Usuario: </label></td>
            <td><input id="" class="inp" type="text" v-model="dat_pers.usuario" disabled><br></td>
          </tr>
          <tr>  
            <td class="celdaizq"><label for="" class="lbl">Nombre Completo: </label></td>
            <td><input id="" class="inp" type="text" v-model="dat_pers.nombre" disabled><br></td>
          </tr>
          <template  v-if="!isEditing">          
            <tr>
              <td class="celdaizq"><label for="" class="lbl">Contraseña: </label></td>
              <td><input id="" class="inp" type="password" v-model="dat_pers.passwd" disabled><br></td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td class="celdaizq"><label for="" class="lbl">Nueva Contraseña: </label></td>
              <td><input id="" class="inp" type="password" v-model="dat_pers.passwd" :disabled="!isEditing"><br></td>
            </tr>
            <tr>
              <td class="celdaizq"><label for="" class="lbl">Repite la Contraseña: </label></td>
              <td><input id="" class="inp" type="password" v-model="validacion" :disabled="!isEditing"><br></td>
            </tr>
          </template>
          <tr><td>&nbsp;</td></tr>
          <tr><td>&nbsp;</td></tr>          
        </table>                  
        <button v-if="isEditing" id="saveafil" class="boton" type="submit">GUARDAR</button>
        <label v-if="isEditing" id="lblbtn"> </label>
        <button id="" class="boton" type="button" @click="alternarEdicion">{{ isEditing ? 'CANCELAR' : 'EDITAR' }}</button>
        <label id="lblbtn"> </label>
        <button id="" class="boton" type="button" @click="retornar">CERRAR</button>
      </form>
    </section>
  </div>
</template>

<script>
import store from '../store';
import axios from 'axios'; 
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex'; 
import { cnxsrv } from '../config';

export default {
  name: 'ConsultarUsuarioActivo',

  setup(){

    let renderizado = ref(0);
    let isEditing = ref(false);
    let validacion = ref('');

    let Usuario = computed(() => store.state.Usuario);

    /* Información Usuario */
    let dat_pers = ref({
      rol: '',
      subdirectiva: '',
      usuario: '',    
      nombre: '',    
      passwd: '',       
    });

    const recargarComponente = () => {
      renderizado.value++;
    }
    
    const alternarEdicion = () => {
       isEditing.value = !isEditing.value;
    };

    async function consultarUsuarioActivo(){
      try {        
        /* Información Base Usuario */
        let url = `${cnxsrv.url}/api/SrvGestionAdmin/consultarUsuarioActivo/${Usuario.value.usuario}`;
        let respuesta = await axios.get(url);
        let data = respuesta.data;

        if(data.body.length != 0){
          if (respuesta.status == 200) {
            console.log('Información usuario OK');
            
            dat_pers.value.usuario = data.body[0].usuario;
            dat_pers.value.passwd = data.body[0].passwd;
            dat_pers.value.rol = data.body[0].rol;
            dat_pers.value.subdirectiva = data.body[0].subdirectiva;
            dat_pers.value.nombre = data.body[0].nombre;

          } else {
            console.error(`Error al buscar la información base del(a) usuario(a) ${Usuario.value.usuario}:`, respuesta.statusText);
          } 
          recargarComponente();
        }

      } catch (error) {
        console.error(error);
      }  
    }

    async function insertarLog(log){ 
      try {
        let url = `${cnxsrv.url}/api/SrvGestionAdmin/insertarLog`;
        let respuesta = await axios.post(url, log);
        if (respuesta.status != 201) {
          console.error(`Error al insertar log:`, respuesta.statusText);
        } else {console.log('Log insertado');}
      } catch (error) {
        console.error(error);
      }  
    }

    async function handleSubmit(){  //Actualizar Contraseña

      try {
        
        if(dat_pers.value.passwd == validacion.value){
          /* Información Usuario */
          let usuario =  {  
            usuario: dat_pers.value.usuario,
            passwd: dat_pers.value.passwd,
            rol: dat_pers.value.rol,
            subdirectiva: dat_pers.value.subdirectiva,
            activo: 1, //Usuario Activo
          };
          console.log('usuario:', usuario);

          /* ---------------------- */
          let url = `${cnxsrv.url}/api/SrvGestionAdmin/actualizarUsuario`;
          let respuesta = await axios.put(url, usuario);
          if (respuesta.status != 200) {
            console.error(`Error al actualizar la contraseña del(a) usuario(a) ${Usuario.value.usuario}:`, respuesta.statusText);
            //-------- Log Error 
            let log = {
              id_log: null,
              usuario: Usuario.value.usuario,
              tiempo: new Date().toLocaleString(),
              tipo: 'EDICIÓN',
              descripcion: 'Usuario cambia su contraseña',
              resultado: 'Error',
            }
            insertarLog(log);
            //--------
          } else {
            alert('SU CONTRASEÑA HA SIDO ACTUALIZADA CON ÉXITO!');
            alternarEdicion();
            //-------- Log Éxito
            let log = {
              id_log: null,
              usuario: Usuario.value.usuario,
              tiempo: new Date().toLocaleString(),
              tipo: 'EDICIÓN',
              descripcion: 'Usuario cambia su contraseña',
              resultado: 'Éxito',
            }
            insertarLog(log);
            //--------
          }

          recargarComponente();

        } else {
          alert('Las contraseñas no coinciden. Inténtelo de nuevo.');
          validacion.value = '';
          //-------- Log Error 
          let log = {
              id_log: null,
              usuario: Usuario.value.usuario,
              tiempo: new Date().toLocaleString(),
              tipo: 'EDICIÓN',
              descripcion: 'Usuario cambia su contraseña',
              resultado: 'Error',
            }
            insertarLog(log);
            //--------
        }

      } catch (error) {
        console.error(error);
      }  
    }
    
    const retornar = () => {
        store.dispatch('actualizarAppIniciada', true);
        store.dispatch('actualizarVistaInicial', true);
        recargarComponente();
    };

    onMounted(consultarUsuarioActivo);

    return {
      renderizado,
      isEditing,
      validacion,
      dat_pers,
      recargarComponente,
      alternarEdicion,
      consultarUsuarioActivo,
      handleSubmit,
      retornar,      
    };
  },

  computed: {
    AppIniciada() {
        return this.$store.state.AppIniciada;
    },

    VistaInicial() {
      return this.$store.state.VistaInicial;
    },
    
    Usuario() {
        return this.$store.state.Usuario;
    },
  }

}
</script>

<style scoped>

  #usuarioActivo {
    color:#025920;
    background-color: #E1F2E7;
    width: 100%;
    /* display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(4, auto);

    grid-template-areas:    "H H H H"                             
                            "M M M M"
                            "M M M M"
                            "F F F F"; */
  }

  #h4 {
    color:#025920;
    font-weight: bold;
    padding: 30px 6px 20px 6px;  /*  arriba | derecha | abajo | izquierda */
  }

  #sform {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 25% 20px 25%;  /*  arriba | derecha | abajo | izquierda */
  }

  form {
    color:#025920;
    background-color: #B8CFBE;
    border-color:#025920;
    border-width: 2px;
    border-radius: 10px;
    text-align: center;
    padding: 40px;
  }

  table {
    border-collapse: separate; 
    border-spacing: 2px;
    width: 100%;
    /* border:solid; */
    /* margin: 5px; 
    padding-left: 5px;
    padding-right: 5px;  */
  }

  .celdaizq{
    text-align: left;
  }

  .lbl {
    color:#007D39;
    font-size: 12pt;
    font-weight: 600;
  }

  .inp {
    width: 100%; /*#4A4F4B*/
    height: 30px;
    font-size: 12pt;
    font-weight: 500;
    color: #025920;
    background-color: #E1F2E7;
    border: double;
    border-color: #025920;
    box-shadow: 3px 3px 5px -2px #737A75 inset; 
    border-radius: 5px;
    padding-left: 10px;
    margin-left: 5px;
    text-align: left;
  }

  .boton {
    width: 100px;
    padding: 2px 6px 2px 6px;  /* arriba | derecha | abajo | izquierda */
    border: solid;
    border-color: #02732A;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px #025920;
    color: #025920;
    font-size: 10pt;
    font-weight: bold;
    background-color: #C2F2D3;
  }

  .boton:hover {
    border-color: #C2F2D3;
    color: #C2F2D3;
    background-color: #007D39;
  }

  #lblbtn {
    width: 40px;
  }

  #p1{
    text-align: justify;
  }

  /* ----- */

  /* @media screen and (min-width: 615px) and (max-width: 900px) { 
    div {        
      grid-template-columns: repeat(3, 33.3%);
      grid-template-rows: repeat(4, auto);

      grid-template-areas:    "H H H"
                              "M M M"
                              "M M M"                                                                
                              "F F F"; 
    }

  } */

  @media screen and (max-width: 885px) {  

    #lblbtn {
      width: 20px;
    }

    #sform {
      margin: 0px 5% 20px 5%;  /*  arriba | derecha | abajo | izquierda */
    }

    form {
      padding: 20px;
    }
  } 

</style>
