import { createRouter, createWebHistory } from 'vue-router'
// -----------------------------------------------------------------------
import AutenticacionView from '../views/AutenticacionView.vue'
import ModuloAfiliadoView from '../views/ModuloAfiliadoView.vue'
import ModuloSubdirectivaView from '../views/ModuloSubdirectivaView.vue'
import ModuloNacionalView from '../views/ModuloNacionalView.vue'
import ModuloAdminView from '../views/ModuloAdminView.vue'
// -----------------------------------------------------------------------
import Vacio from '../components/Vacio.vue'
import CrearAfiliado from '../components/CrearAfiliado.vue'
import ListarAfiliados from '../components/ListarAfiliados.vue'
import ListarAfiliadosSindi from '../components/ListarAfiliadosSindi.vue'
import ListarSubdirectivas from '../components/ListarSubdirectivas.vue'
import ListarUsuarios from '../components/ListarUsuarios.vue'
import ConsultarAfiliado from '../components/ConsultarAfiliado.vue'
import ConsultarSubdirectiva from '../components/ConsultarSubdirectiva.vue'
import ConsultarSubdirectivaAfi from '../components/ConsultarSubdirectivaAfi.vue'
import ConsultarSubdirectivaAdmin from '../components/ConsultarSubdirectivaAdmin.vue'
import ConsultarUsuario from '../components/ConsultarUsuario.vue'
import ConsultarUsuarioActivo from '../components/ConsultarUsuarioActivo.vue'
import CrearUsuario from '../components/CrearUsuario.vue'
import RegistrarSubdirectiva from '../components/RegistrarSubdirectiva.vue'
//import RegistrarDelegados from '../components/RegistrarDelegados.vue'
import GenerarReportes from '../components/GenerarReportes.vue'
import Salir from '../components/Salir.vue'
// -----------------------------------------------------------------------

const routes = [
  {
    path: '/',
    name: 'home',
    component: AutenticacionView
  },
  {
    path: '/ModuloAfiliadoView',
    name: 'ModuloAfiliadoView',
    component: ModuloAfiliadoView
  },
  {
    path: '/ModuloSubdirectivaView',
    name: 'ModuloSubdirectivaView',
    component: ModuloSubdirectivaView
  },
  {
    path: '/ModuloNacionalView',
    name: 'ModuloNacionalView',
    component: ModuloNacionalView
  },
  {
    path: '/ModuloAdminView',
    name: 'ModuloAdminView',
    component: ModuloAdminView
  },
  {
    path: '/Vacio',
    name: 'Vacio',
    component: Vacio
  },
  {
    path: '/CrearAfiliado',
    name: 'CrearAfiliado',
    component: CrearAfiliado
  },
  {
    path: '/ListarAfiliados',
    name: 'ListarAfiliados',
    component: ListarAfiliados
  },
  {
    path: '/ListarAfiliadosSindi',
    name: 'ListarAfiliadosSindi',
    component: ListarAfiliadosSindi
  },
  {
    path: '/ListarSubdirectivas',
    name: 'ListarSubdirectivas',
    component: ListarSubdirectivas
  },
  {
    path: '/ListarUsuarios',
    name: 'ListarUsuarios',
    component: ListarUsuarios
  },
  {
    path: '/ConsultarAfiliado',
    name: 'ConsultarAfiliado',
    component: ConsultarAfiliado
  },
  {
    path: '/ConsultarSubdirectiva',
    name: 'ConsultarSubdirectiva',
    component: ConsultarSubdirectiva
  },
  {
    path: '/ConsultarSubdirectivaAfi',
    name: 'ConsultarSubdirectivaAfi',
    component: ConsultarSubdirectivaAfi
  },
  {
    path: '/ConsultarSubdirectivaAdmin',
    name: 'ConsultarSubdirectivaAdmin',
    component: ConsultarSubdirectivaAdmin
  },
  {
    path: '/ConsultarUsuario',
    name: 'ConsultarUsuario',
    component: ConsultarUsuario
  },
  {
    path: '/ConsultarUsuarioActivo',
    name: 'ConsultarUsuarioActivo',
    component: ConsultarUsuarioActivo
  },
  {
    path: '/CrearUsuario',
    name: 'CrearUsuario',
    component: CrearUsuario
  },
  {
    path: '/RegistrarSubdirectiva',
    name: 'RegistrarSubdirectiva',
    component: RegistrarSubdirectiva
  },
  // {
  //   path: '/RegistrarDelegados',
  //   name: 'RegistrarDelegados',
  //   component: RegistrarDelegados
  // },
  {
    path: '/GenerarReportes',
    name: 'GenerarReportes',
    component: GenerarReportes
  },
  {
    path: '/Salir',
    name: 'Salir',
    component: Salir
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
