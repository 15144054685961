<template>
  <div id="autenticacionView" :key="renderizado">  

    <section id="s1" v-if="AppIniciada === false">
      <form @submit.prevent="handleSubmit(credenciales)">
        <table>
          <caption id="cap2">AUTENTICACIÓN DE USUARIO</caption>
          <tbody>
            <tr><td> </td></tr>
            <tr>
              <td><label for="in4" class="lbl">USUARIO: </label></td>
              <td><input id="in4" class="intbl" type="text" v-model="credenciales.usuario"></td> <!-- v-model="usuario" -->
            </tr>
            <tr>
              <td><label for="in5" class="lbl">CONTRASEÑA: </label></td>
              <td>
                <input v-if="verpass==''" id="in5" class="intbl" type="password" v-model="credenciales.passwd" >
                <input v-else id="in5" class="intbl" type="text" v-model="credenciales.passwd" >
              </td> <!-- v-model="passwd" -->
            </tr>
            <tr>
              <td><label for="" class="lbl"></label></td>
              <td>
                <input id="smmr1" type="checkbox" name="verpasswd" value="s" v-model="verpass">
                <label for="smmr1"> &nbsp;Mostrar Contraseña </label>
              </td>
            </tr>
            <tr><td> </td></tr>
          </tbody>
          <tfoot>      
            <tr>
              <td colspan="2">
                <button id="btn5" class="boton" type="submit">INGRESAR</button>
                <label id="lblbtn"> </label>
                <button id="btn6" class="boton" type="reset">CANCELAR</button>
              </td>            
            </tr>
          </tfoot>
        </table>
      </form>
    </section>

    <section id="s2" v-else>
      <ModuloAfiliadoView v-if="usuario.rol === 1"/>
      <ModuloSubdirectivaView v-else-if="usuario.rol === 2"/>
      <ModuloNacionalView v-else-if="usuario.rol === 3"/>
      <ModuloAdminView v-else-if="usuario.rol === 4"/>
      <VacioView v-else/>
    </section>

  </div>  
</template>

<script>
  import store from '../store';
  import axios from 'axios'; 
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { cnxsrv } from '../config';

  import VacioView from './VacioView.vue'
  import ModuloAfiliadoView from './ModuloAfiliadoView.vue'
  import ModuloSubdirectivaView from './ModuloSubdirectivaView.vue'
  import ModuloNacionalView from './ModuloNacionalView.vue'
  import ModuloAdminView from './ModuloAdminView.vue'
  
  export default {
    name: 'AutenticacionView',  

    components: {  
      VacioView,
      ModuloAfiliadoView,
      ModuloSubdirectivaView,
      ModuloNacionalView,
      ModuloAdminView
    },

    setup(){  

      const renderizado = ref(0);
      let verpass = ref('');
      let data = null; 
      let credenciales = ref({usuario: '', passwd: ''});
      let usuario = ref({usuario: '', passwd: '', rol: null, subdirectiva: '', activo: null});

      /* ----------------------------------------- */
      // var ListaUsuarios = [ {usuario: 'afiliado', passwd: '123', rol: 1, subdirectiva: 'subdirectiva 1', activo: 1}, 
      //                       {usuario: 'subdir', passwd: '123', rol: 2, subdirectiva: 'subdirectiva 2', activo: 1},
      //                       {usuario: 'sindicato', passwd: '123', rol: 3, subdirectiva: 'subdirectiva 1', activo: 1}, 
      //                       {usuario: 'admin', passwd: '123', rol: 4, subdirectiva: 'subdirectiva 2', activo: 1}, 
      //                       {usuario: '42147721', passwd: '123', rol: 1, subdirectiva: 'subdirectiva 1', activo: 1} ];
      /* ----------------------------------------- */

      const recargarComponente = () => {
        renderizado.value++;
      }

      async function handleSubmit (credenciales) {

        try {
          const url = `${cnxsrv.url}/api/SrvGestionAdmin/consultarUsuario/${credenciales.usuario}`;
          const respuesta = await axios.get(url);
          data = respuesta.data;
          console.log('credenciales.usuario:', credenciales.usuario);
          console.log('data.body:', data.body[0]);
          
          if(( !data.body[0] ) || (data.body[0].activo == 0)){
            alert('El usuario no existe. Por favor verifique sus credenciales.');
            store.dispatch('actualizarAppIniciada', false);

          } else if((data.body[0].usuario == credenciales.usuario) && (data.body[0].passwd == credenciales.passwd)){
            console.log('Registro encontrado: ', data.body[0]);
            usuario.value.usuario = data.body[0].usuario;
            usuario.value.passwd = data.body[0].passwd;
            usuario.value.rol = data.body[0].rol;
            usuario.value.subdirectiva = data.body[0].subdirectiva;
            usuario.value.activo = data.body[0].activo;
            console.log('Usuario: ', usuario.value);

            store.dispatch('actualizarUsuario', usuario.value);
            store.dispatch('actualizarAppIniciada', true);

          } else if((data.body[0].usuario == credenciales.usuario) && (data.body[0].passwd != credenciales.passwd)){
            alert('La contraseña es incorrecta. Por favor verifique sus credenciales.');
            store.dispatch('actualizarAppIniciada', false);
          }
        } catch (error) {
          console.error(error);
        }

        recargarComponente();
        credenciales.usuario = '';
        credenciales.passwd = '';
      };

      return{
        renderizado,
        verpass,
        credenciales,
        usuario,
        handleSubmit,
      };
    },

    computed: {
      AppIniciada() {
          return this.$store.state.AppIniciada;
      },

      Usuario() {
          return this.$store.state.Usuario;
      },
    },

  }
</script>

<style scoped>

  #autenticacionView {
    color:#025920;
    background-color: #E1F2E7;
    width: 100%;
    /* display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(4, auto);

    grid-template-areas:    "H H H H"                             
                            "M M M M"
                            "M M M M"
                            "F F F F"; */
  }

  #s1, #s2 {
    display: flex; 
    justify-content: center; 
    align-items: center;
  }

  #s1 {
    padding: 30px;
  }

  table {
    border-collapse: separate;  /* collapse, separate*/
    border-spacing: 2px;
    height:auto;
    margin: 5px; 
    padding: 1px;
  }

  caption {
    font-size: 12pt;
    font-weight: bolder;
    color:#025920;
    caption-side: top; 
    text-align: center; 
    padding:5px; 
  }

  td {  
    padding: 5px;
    text-align: left;
    vertical-align: middle;
  }

  tfoot td {
    padding: 5px;
    text-align: center;
    vertical-align: middle;
  }

  .lbl {
    color:#007D39;
    font-size: 11pt;
    font-weight: 600;
  }

  .intbl {
    width: 98%; /*#4A4F4B*/
    font-size: 12pt;
    font-weight: 500;
    color: #025920;
    background-color: #E1F2E7;
    border: double;
    border-color: #025920;
    box-shadow: 3px 3px 5px -2px #737A75 inset; 
    border-radius: 5px;
    padding-left: 10px;
  }

  .boton {
    width: 100px;
    padding: 2px 6px 2px 6px;  /* arriba | derecha | abajo | izquierda */
    border: solid;
    border-color: #02732A;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px #025920;
    color: #025920;
    font-size: 10pt;
    font-weight: bold;
    background-color: #C2F2D3;
  }

  .boton:hover {
    border-color: #C2F2D3;
    color: #C2F2D3;
    background-color: #007D39;
  }

  #lblbtn {
    width: 40px;
  }

  h5 {
    color:#025920;
    font-weight: bold;
  }

  /* @media screen and (min-width: 615px) and (max-width: 900px) { 
    div {        
      grid-template-columns: repeat(3, 33.3%);
      grid-template-rows: repeat(4, auto);

      grid-template-areas:    "H H H"
                              "M M M"
                              "M M M"                                                                
                              "F F F"; 
    }

  } */

  @media screen and (max-width: 400px) {  

    #s1 {
      padding: 10px;
    }

    #lblbtn {
      width: 10px;
    }

  } 

</style>