<template>
  <div id="consultarAfiliado" :key="renderizado">
    <section id="sform">
      <div class="accordion" id="accordionPanelsStayOpenExample">
        <div class="accordion-item"> <!-- Datos personales -->
          <h2 class="accordion-header acordeonbtn">
            <button class="accordion-button acordeonbtn" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
              DATOS PERSONALES
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
            <div class="accordion-body acordeonbody">
              <div id="grid_acordeon1" class="grid_acordeon">
                <section id="sizq1" class="formAfil s_izq">
                  <table>
                    <tr>  
                        <td><label for="" class="lbl">Fecha de Afiliación: </label></td>
                        <td><input id="" class="inp" type="date" v-model="usu_afi_dat_pers.fecha_afi" disabled><br></td> <!-- value="2024-08-24" -->
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Tipo Documento de Identidad: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.tipo_id" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Nombre Completo: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.nombre" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Fecha de Nacimiento: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.fecha_nac" disabled><br></td> <!-- value="2024-08-24" -->
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Sexo Asignado al Nacer: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.sexo" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Teléfono: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.telefono" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Departamento: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.depto" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Correo Electrónico: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.email" disabled><br></td>
                    </tr>
                  </table>
                </section>
                <section id="sder1" class="formAfil s_der">
                  <table>
                    <tr>  
                      <td><label for="" class="lbl">Documento de Identidad: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.id_afiliado" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Código Interno: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.cod_interno" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Edad: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.edad" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Grupo Sanguíneo: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.grupo_sang" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Dirección: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.direccion" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Municipio: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.municipio" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Estado Civil: </label></td>
                      <td><input id="" class="inp" type="text" v-model="usu_afi_dat_pers.est_civil" disabled><br></td>
                    </tr>
                  </table>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item"> <!-- Información Laboral -->
          <h2 class="accordion-header acordeonbtn">
            <button class="accordion-button acordeonbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
              INFORMACIÓN LABORAL
            </button>
          </h2>
          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
            <div class="accordion-body acordeonbody">
              <div id="grid_acordeon2" class="grid_acordeon">
                <section id="sizq2" class="formAfil s_izq">
                  <table>
                    <tr>  
                      <td><label for="" class="lbl">Empresa: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_info_lab.empresa" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Tipo de Contrato: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_info_lab.tipo_contrato" disabled><br></td>
                    </tr>
                    <tr>  
                        <td><label for="" class="lbl">Área: </label></td>
                        <td><input id="" class="inp" type="text" v-model="afi_info_lab.area" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Departamento donde labora: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_info_lab.depto_labor" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Empleado Activo? </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_info_lab.empl_activo" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Fecha de retiro: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_info_lab.fecha_ret" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Subdirectiva a la que pertenece: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_info_lab.subdirectiva" disabled><br></td>
                    </tr>
                  </table>
                </section>
                <section id="sder2" class="formAfil s_der">
                  <table>
                    <tr>  
                      <td><label for="" class="lbl">Fecha de Vinculación: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_info_lab.fecha_vinc" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Cargo: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_info_lab.cargo" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Municipio donde labora: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_info_lab.municipio_labor" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Tiempo de Vinculación (Meses): </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_info_lab.tiempo_vinc" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Motivo del retiro: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_info_lab.motivo_ret" disabled><br></td>
                    </tr>
                  </table>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item"> <!-- Salud y Seguridad Social -->
          <h2 class="accordion-header acordeonbtn">
            <button class="accordion-button acordeonbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
              SALUD Y SEGURIDAD SOCIAL
            </button>
          </h2>
          <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
            <div class="accordion-body acordeonbody">
              <div id="grid_acordeon3" class="grid_acordeon">
                <section id="sizq3" class="formAfil s_izq">
                  <table>
                    <tr>  
                      <td><label for="" class="lbl">EPS: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_salud_ss.eps" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">ARL: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_salud_ss.arl" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Tiene Plan Complementario de Salud? </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_salud_ss.plan_comp_salud" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Prestador de Salud Prepagada: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_salud_ss.prestador_salud_prep" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Presenta discapacidad: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_salud_ss.discapacidad" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Tiene Servicios Funerarios? </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_salud_ss.serve_fune" disabled><br></td>
                    </tr>
                  </table>
                </section>
                <section id="sder3" class="formAfil s_der">
                  <table>
                    <tr>  
                      <td width="55%"><label for="" class="lbl">AFP: </label></td>
                      <td width="45%"><input id="" class="inp" type="text" v-model="afi_salud_ss.afp" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Caja de Compensación: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_salud_ss.caja_compen" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Tiene Salud Prepagada? </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_salud_ss.salud_prepag" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Tiene alguna de las siguientes condiciones de salud? Si tiene varias, seleccione la más predominante </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_salud_ss.condicion_salud" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Tiene Fondo Mutuo Hospitalario? </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_salud_ss.fondo_m_hospi" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Prestador Servicios Funerarios:</label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_salud_ss.prestador_serv_fune" disabled><br></td>
                    </tr>
                  </table>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item"> <!-- Formación Académica -->
          <h2 class="accordion-header acordeonbtn">
            <button class="accordion-button acordeonbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
              FORMACIÓN ACADÉMICA
            </button>
          </h2>
          <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse">
            <div class="accordion-body acordeonbody">
              <div id="grid_acordeon4" class="grid_acordeon">
                <section id="sizq4" class="formAfil s_izq">
                  <table>
                    <tr>  
                      <td><label for="" class="lbl">Sabe leer y escribir? </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_form_acad.leer_escribir" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Año de graduación: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_form_acad.anio_grad" disabled><br></td>   <!-- value="2024-09" -->
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Manejo del computador: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_form_acad.manejo_pc" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Estudia actualmente? </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_form_acad.estudia_act" disabled><br></td>
                    </tr>
                  </table>
                </section>
                <section id="sder4" class="formAfil s_der">
                  <table>
                    <tr>  
                      <td width="50%"><label for="" class="lbl">Máximo nivel de formación terminado: </label></td>
                      <td width="50%"><input id="" class="inp" type="text" v-model="afi_form_acad.nivel_form" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Título obtenido: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_form_acad.titulo" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Manejo de segunda lengua: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_form_acad.segunda_lengua" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Grado o Carrera que cursa actualmente: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_form_acad.grad_carrera" disabled><br></td>
                    </tr>
                  </table>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item"> <!-- Información Familiar -->
          <h2 class="accordion-header acordeonbtn">
            <button class="accordion-button acordeonbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
              INFORMACIÓN FAMILIAR
            </button>
          </h2>
          <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse">
            <div class="accordion-body acordeonbody">
              <div id="grid_acordeon5" class="grid_acordeon">
                <section id="sizq5" class="formAfil s_izq" >
                  <table>
                    <tr>  
                      <td>
                        <label for="" class="lbl">No. de Hijos: &nbsp;&nbsp;</label>
                        <input id="nchild" class="inp" type="text" v-model="usu_afi_dat_pers.nhijos" disabled><br>
                      </td>
                    </tr>
                  </table>
                  <br>
                  <label class="lbl">INFORMACIÓN DEL(A) CÓNYUGE</label>
                    <table v-if="usu_afi_dat_pers.est_civil === 'Casado(a)' || usu_afi_dat_pers.est_civil === 'Unión Libre'">
                      <tr>  
                        <td><label for="" class="lbl">Tipo Documento de Identidad: </label></td>
                        <td><input id="" class="inp" type="text" v-model="afi_conyuge.tipo_id" disabled><br></td>
                      </tr>
                      <tr>  
                        <td><label for="" class="lbl">Documento de Identidad: </label></td>
                        <td><input id="" class="inp" type="text" v-model="afi_conyuge.id_conyuge" disabled><br></td>
                      </tr>
                      <tr>  
                        <td><label for="" class="lbl">Nombre Completo: </label></td>
                        <td><input id="" class="inp" type="text" v-model="afi_conyuge.nombre" disabled><br></td>
                      </tr>
                      <tr>  
                        <td><label for="" class="lbl">Sexo Asignado al Nacer: </label></td>
                        <td><input id="" class="inp" type="text" v-model="afi_conyuge.sexo" disabled><br></td>
                      </tr>
                      <tr>  
                        <td><label for="" class="lbl">Fecha de Nacimiento: </label></td>
                        <td><input id="" class="inp" type="text" v-model="afi_conyuge.fecha_nac" disabled><br></td> <!-- value="2024-08-24" -->
                      </tr>
                      <tr>  
                        <td><label for="" class="lbl">Teléfono: </label></td>
                        <td><input id="" class="inp" type="text" v-model="afi_conyuge.telefono" disabled><br></td>
                      </tr>
                      <tr>  
                        <td><label for="" class="lbl">Correo Electrónico: </label></td>
                        <td><input id="" class="inp" type="text" v-model="afi_conyuge.email" disabled><br></td>
                      </tr>
                      <tr>  
                        <td><label for="" class="lbl">Presenta discapacidad: </label></td>
                        <td><input id="" class="inp" type="text" v-model="afi_conyuge.discapacidad" disabled><br></td>
                      </tr>
                    </table>
                    <table v-else>
                      <tr>  
                        <td><label for="" class="lbl">No registra Cónyuge </label></td>
                      </tr>
                    </table>  
                  <br>
                  <label class="lbl">INFORMACIÓN DE LOS(AS) HIJOS(AS)</label>
                    <template v-if="usu_afi_dat_pers.nhijos != 0">
                      <table v-for="(hijo, index) in afi_hijos" :key="index">
                        <tr>  
                          <td><hr></td>
                        </tr>
                        <tr>  
                          <td><label for="" class="lbl">HIJO(A) {{index + 1}} </label></td>
                        </tr>
                        <tr>  
                          <td><label for="" class="lbl">Tipo Documento de Identidad: </label></td>
                          <td><input id="" class="inp" type="text" v-model="afi_hijos[index].tipo_id" disabled><br></td>
                        </tr>
                        <tr>  
                          <td><label for="" class="lbl">Documento de Identidad: </label></td>
                          <td><input id="" class="inp" type="text" v-model="afi_hijos[index].id_hijo" disabled><br></td>
                        </tr>
                        <tr>  
                          <td><label for="" class="lbl">Nombre Completo: </label></td>
                          <td><input id="" class="inp" type="text" v-model="afi_hijos[index].nombre" disabled><br></td>
                        </tr>
                        <tr>  
                          <td><label for="" class="lbl">Sexo Asignado al Nacer: </label></td>
                          <td><input id="" class="inp" type="text" v-model="afi_hijos[index].sexo" disabled><br></td>
                        </tr>
                        <tr>  
                          <td><label for="" class="lbl">Fecha de Nacimiento: </label></td>
                          <td><input id="" class="inp" type="text" v-model="afi_hijos[index].fecha_nac" disabled><br></td> <!-- value="2024-08-24" -->
                        </tr>
                        <tr>  
                          <td><label for="" class="lbl">Teléfono: </label></td>
                          <td><input id="" class="inp" type="text" v-model="afi_hijos[index].telefono" disabled><br></td>
                        </tr>
                        <tr>  
                          <td><label for="" class="lbl">Correo Electrónico: </label></td>
                          <td><input id="" class="inp" type="text" v-model="afi_hijos[index].email" disabled><br></td>
                        </tr>
                        <tr>  
                          <td><label for="" class="lbl">Presenta discapacidad: </label></td>
                          <td><input id="" class="inp" type="text" v-model="afi_hijos[index].discapacidad" disabled><br></td>
                        </tr>
                        <tr>  
                          <td><label for="" class="lbl">Estudia actualmente? </label></td>
                          <td><input id="" class="inp" type="text" v-model="afi_hijos[index].estud_actual" disabled><br></td>
                        </tr>
                        <tr>  
                          <td><label for="" class="lbl">Nivel de formación que cursa: </label></td>
                          <td><input id="" class="inp" type="text" v-model="afi_hijos[index].nivel_form" disabled><br></td>
                        </tr>
                      </table>
                    </template>
                    <template v-else>
                      <table>
                        <tr>  
                          <td><label for="" class="lbl">No registra Hijos(as) </label></td>
                        </tr>
                      </table>
                    </template>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item"> <!-- Información Socio-Económica-Cultural -->
          <h2 class="accordion-header acordeonbtn">
            <button class="accordion-button acordeonbtn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
              INFORMACIÓN SOCIO-ECONÓMICA-CULTURAL
            </button>
          </h2>
          <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse">
            <div class="accordion-body acordeonbody">
              <div id="grid_acordeon6" class="grid_acordeon">
                <section id="sizq6" class="formAfil s_izq">
                  <table>
                    <tr>  
                      <td><label for="" class="lbl">Estrato Socio-Económico: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_soc_eco_cul.estrato" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Vehículos propios: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_soc_eco_cul.vehiculos" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Usted se reconoce como: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_soc_eco_cul.iden_cul" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Hace parte de la comunidad LGTBIQ+? </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_soc_eco_cul.lgtbiq" disabled><br></td>
                    </tr>
                  </table>
                </section>
                <section id="sder6" class="formAfil s_der">
                  <table>
                    <tr>  
                      <td><label for="" class="lbl">Tipo de Vivienda: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_soc_eco_cul.vivienda" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Actividad económica secundaria: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_soc_eco_cul.act_econo" disabled><br></td>
                    </tr>
                     <tr>  
                      <td><label for="" class="lbl">Habita en algún: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_soc_eco_cul.habita_terri" disabled><br></td>
                    </tr>
                    <tr>  
                      <td><label for="" class="lbl">Práctica deportiva: </label></td>
                      <td><input id="" class="inp" type="text" v-model="afi_soc_eco_cul.prac_depor" disabled><br></td>
                    </tr>                     
                  </table>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>        
      <button id="" class="boton" type="button" @click="descargar()">DESCARGAR</button>      
    </section>
  </div>
</template>

<script>

import store from '../store';
import axios from 'axios'; 
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { cnxsrv } from '../config';

export default {
  name: 'ConsultarAfiliadoSub',

  props: {
      afiliado: String,
  },

  setup(props){  

    let renderizado = ref(0);
    let data = null; 

    const Usuario = computed(() => store.state.Usuario);

    /* Datos personales */
    let usu_afi_dat_pers = ref({
      id_afiliado: '',
      fecha_afi: '',  /*date*/   //Nuevo
      tipo_id: '',
      nombre: '',
      cod_interno: '',
      fecha_nac: '',  /*date*/
      edad: '', /*int*/
      sexo: '',
      grupo_sang: '',
      telefono: '',
      direccion: '',
      depto: '',
      municipio: '',
      est_civil: '',
      email: '',
      nhijos: 0,
      activo: 1,
    });

    /* Información Laboral */
    let afi_info_lab = ref({
      id_afiliado: '',
      empresa: '',
      fecha_vinc: '',  /*date*/
      tipo_contrato: '',
      cargo: '',
      area: '',
      depto_labor: '',
      municipio_labor: '',
      empl_activo: '',
      tiempo_vinc: '',  /*int*/
      fecha_ret: '',  /*date*/
      motivo_ret: '',
      subdirectiva: '',
    });

    /* Salud y Seguridad Social */
    let afi_salud_ss = ref({
      id_afiliado: '',
      eps: '',
      afp: '',
      arl: '',
      caja_compen: '',
      plan_comp_salud: '',
      salud_prepag: '',
      prestador_salud_prep: '',
      discapacidad: '',
      condicion_salud: '',
      fondo_m_hospi:'',
      serv_fune:'',
      prestador_serv_fune:'',
    });

    /* Formación Académica */
    let afi_form_acad = ref({
      id_afiliado: '',
      leer_escribir: '',
      nivel_form: '',
      anio_grad: '',
      titulo: '',
      manejo_pc: '',
      segunda_lengua: '',
      estudia_act: '',
      grad_carrera: '',
    });

    /* Información Familiar: Conyuge */
    let afi_conyuge = ref({
      id_afiliado: '',
      id_conyuge: '',
      tipo_id: '',
      nombre: '',
      fecha_nac: '', /*date*/
      sexo: '',
      telefono: '',
      email: '',
      discapacidad: '',
    });

    /* Información Familiar: Hijos */
    let afi_hijo = ref({
      id_hijo: '',
      tipo_id: '',
      id_afiliado: '',
      nombre: '',
      fecha_nac: '', /*date*/
      sexo: '',
      telefono: '',
      email: '',
      discapacidad: '',
      estud_actual: '',
      nivel_form: '',
    });

    let afi_hijos = ref([]);

    /* Información Socio-Económica-Cultural */
    let afi_soc_eco_cul = ref({
      id_afiliado: '',
      estrato: '',  /*int*/
      vehiculos: '',
      vivienda: '',
      act_econo: '',
      iden_cul: '',
      habita_terri: '',
      lgtbiq: '',
      prac_depor:'',
    });

    const recargarComponente = () => {
        renderizado.value++;
    }

    // const AppIniciada = computed(() => store.state.AppIniciada);
    // const Usuario = computed(() => store.state.Usuario);
    
    async function consultarAfiliado(){

      try {
        /* ---------------------- */
        console.log('INICIANDO CONSULTA');
        /* ---------------------- */

        /* ---------------------- */
        /* Información Personal Afiliado */
        let url = `${cnxsrv.url}/api/SrvGestionAfiliados/consultarAfiliado/${props.afiliado.replace(/^['"]|['"]$/g, '')}`;
        let respuesta = await axios.get(url);
        data = respuesta.data;

        if(data.body.length != 0){
          if (respuesta.status == 200) {
            console.log('Información personal OK');
            
            usu_afi_dat_pers.value = data.body[0];
            usu_afi_dat_pers.value.fecha_nac = data.body[0].fecha_nac.substr(0, 10);
            usu_afi_dat_pers.value.fecha_afi = data.body[0].fecha_afi.substr(0, 10);
            console.log('usu_afi_dat_pers.value:', usu_afi_dat_pers.value);

          } else {
            console.error(`Error al buscar la información personal del(a) afiliado(a) ${props.afiliado}:`, respuesta.statusText);
          }
        } else {console.log('SIN Información personal');}

        /* ---------------------- */
        /* Información Laboral Afiliado*/
        data = null;
        url = `${cnxsrv.url}/api/SrvGestionAfiliados/consultarAfiInfoLab/${props.afiliado.replace(/^['"]|['"]$/g, '')}`;
        respuesta = await axios.get(url);
        data = respuesta.data;

        if(data.body.length != 0){
          if (respuesta.status == 200) {
            console.log('Información laboral OK');
            
            afi_info_lab.value = data.body[0];
            afi_info_lab.value.fecha_vinc = data.body[0].fecha_vinc.substr(0, 10);
            afi_info_lab.value.fecha_ret = data.body[0].fecha_ret.substr(0, 10);
            console.log('afi_info_lab.value:', afi_info_lab.value);

          } else {
            console.error(`Error al buscar la información laboral del(a) afiliado(a) ${props.afiliado}:`, respuesta.statusText);
          }
        } else {console.log('SIN Información laboral');}

        /* ---------------------- */
        /* Salud y Seguridad Social */
        data = null;
        url = `${cnxsrv.url}/api/SrvGestionAfiliados/consultarAfiSaludSS/${props.afiliado.replace(/^['"]|['"]$/g, '')}`;
        respuesta = await axios.get(url);
        data = respuesta.data;

        if(data.body.length != 0){
          if (respuesta.status == 200) {
            console.log('Información salud OK');
            
            afi_salud_ss.value = data.body[0];
            console.log('afi_salud_ss.value:', afi_salud_ss.value);

          } else {
            console.error(`Error al buscar la información de salud del(a) afiliado(a) ${props.afiliado}:`, respuesta.statusText);
          }
        } else {console.log('SIN Información salud');}

        /* ---------------------- */
        /* Formación Académica */
        data = null;
        url = `${cnxsrv.url}/api/SrvGestionAfiliados/consultarAfiFormAcad/${props.afiliado.replace(/^['"]|['"]$/g, '')}`;
        respuesta = await axios.get(url);
        data = respuesta.data;

        if(data.body.length != 0){
          if (respuesta.status == 200) {
            console.log('Información académica OK');
            
            afi_form_acad.value = data.body[0];
            console.log('afi_form_acad.value:', afi_form_acad.value);

          } else {
            console.error(`Error al buscar la información académica del(a) afiliado(a) ${props.afiliado}:`, respuesta.statusText);
          }
        } else {console.log('SIN Información académica');}

        /* ---------------------- */
        /* Información Familiar: Conyuge */
        data = null;
        url = `${cnxsrv.url}/api/SrvGestionAfiliados/consultarAfiConyuge/${props.afiliado.replace(/^['"]|['"]$/g, '')}`;
        respuesta = await axios.get(url);
        data = respuesta.data;

        if(data.body.length != 0){
          if (respuesta.status == 200) {
            console.log('Información cónyuge OK'); 
            
            afi_conyuge.value = data.body[0];
            afi_conyuge.value.fecha_nac = data.body[0].fecha_nac.substr(0, 10);
            console.log('afi_conyuge.value:', afi_conyuge.value);

          } else {
            console.error(`Error al buscar la información sobre el cónyuge del(a) afiliado(a) ${props.afiliado}:`, respuesta.statusText);
          }
        } else {console.log('SIN Información cónyuge');}

        /* ---------------------- */
        /* Información Familiar: Hijos */
        data = null;
        url = `${cnxsrv.url}/api/SrvGestionAfiliados/consultarAfiHijo/${props.afiliado.replace(/^['"]|['"]$/g, '')}`;
        respuesta = await axios.get(url);
        data = respuesta.data;

        if(data.body.length != 0){
          if (respuesta.status == 200) {
            console.log('Información hijos OK');        

            afi_hijos.value = data.body; 
            afi_hijos.value.forEach(function(hijo, index){
              afi_hijos.value[index].fecha_nac = data.body[index].fecha_nac.substr(0, 10);
            });
            console.log('afi_hijos.value:', afi_hijos.value);

          } else {
            console.error(`Error al buscar la información de los hijos del(a) afiliado(a) ${props.afiliado}:`, respuesta.statusText);
          }
        } else {console.log('SIN Información hijos');}

        /* ---------------------- */
        /* Información Socio-Económica-Cultural */
        data = null;
        url = `${cnxsrv.url}/api/SrvGestionAfiliados/consultarAfiSocEcoCul/${props.afiliado.replace(/^['"]|['"]$/g, '')}`;
        respuesta = await axios.get(url);
        data = respuesta.data;

        if(data.body.length != 0){
          if (respuesta.status == 200) {
            console.log('Información socio-económica-cultural OK');
            
            afi_soc_eco_cul.value = data.body[0];
            console.log('afi_soc_eco_cul.value:', afi_soc_eco_cul.value);

          } else {
            console.error(`Error al buscar la información socio-económica-cultural del(a) afiliado(a) ${props.afiliado}:`, respuesta.statusText);
          }
        } else {console.log('SIN Información socio-económica-cultural');}

        /* ---------------------- */
        /* Protección de Datos */
        // data = null;
        // url = `${cnxsrv.url}/api/SrvGestionAfiliados/consultarAfiTrataDatos/'${props.afiliado}'`;
        // respuesta = await axios.get(url);
        // data = respuesta.data;

        // if(data.body.length != 0){
        //   if (respuesta.status == 200) {
        //     console.log('Información tratamiento de datos OK');
            
        //     afi_trata_datos.value = data.body[0];
        //     console.log('afi_trata_datos.value:', afi_trata_datos.value);

        //   } else {
        //     console.error(`Error al buscar la información de autorización del tratamiento de datos del(a) afiliado(a) ${props.afiliado}:`, respuesta.statusText);
        //   }    
        // } else {console.log('SIN Información tratamiento de datos');}

        /* ---------------------- */
        console.log('CONSULTA FINALIZADA');
        /* ---------------------- */

      } catch (error) {
        console.error(error);
      }  
    }

    async function descargar(){
      try {
        /* Reportes */
        let afiliado = props.afiliado.replace(/^['"]|['"]$/g, '');
        let usuario = Usuario.value.usuario;
        let subdirectiva = Usuario.value.subdirectiva;
        let fecha_actual = new Date().toLocaleString().replace(/[/:]/g, '-').replace(/,/g, '_').replace(/ /g, '').replace(/. /g, '');
        console.log('fecha_actual:', fecha_actual); 

        let parametros = { usuario, subdirectiva, afiliado };

        let url = `${cnxsrv.url}/api/SrvReportes/generarReporteAfiliado/`;
        let respuesta = await axios.get(url, { params: parametros, responseType: 'blob' });
        let data = respuesta.data; 

        console.log('respuesta:', respuesta);            

        if(data.length != 0){
          if (respuesta.status == 200) {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            console.log('blob:', blob);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Reporte_Afiliado_${fecha_actual}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error(`Error al buscar la información del reporte`, respuesta.statusText);
          }

        } else {console.log('No hay datos para el reporte');}

      } catch (error) {
        console.error(error);
      }

    };

    onMounted(consultarAfiliado);

    return{
      renderizado,
      usu_afi_dat_pers,
      afi_info_lab,
      afi_salud_ss,
      afi_form_acad,
      afi_conyuge,
      afi_hijos,
      afi_hijo,
      afi_soc_eco_cul,
      recargarComponente,
      descargar,
    };
  },

  computed: {
    Usuario() {
        return this.$store.state.Usuario;
    }
  }

}
</script>

<style scoped>

 #consultarAfiliado {
    color:#025920;
    background-color: #E1F2E7;
    width: 100%;
    /* display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(4, auto);

    grid-template-areas:    "H H H H"                             
                            "M M M M"
                            "M M M M"
                            "F F F F"; */
  }

  #h2 {
    color:#025920;
    font-weight: bold;
    padding: 20px 6px 15px 6px;  /*  arriba | derecha | abajo | izquierda */
  }

  #sform {
    margin: 0px 0% 20px 0%;  /*  arriba | derecha | abajo | izquierda */
  } 

  .accordion {
    margin: 0px 10% 20px 10%;
    --bs-accordion-border-color: #025920;
    --bs-accordion-btn-focus-border-color: #01290F;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #025920;
    --bs-accordion-active-color: #025920;
    --bs-accordion-active-bg: #B8CFBE;
    --bs-accordion-color: #025920;
    --bs-accordion-bg: #E1F2E7;
    --bs-accordion-btn-color: #025920;
    --bs-accordion-btn-bg: #B8CFBE;
    --bs-accordion-btn-icon:url('../assets/img/flecha_dark.png');
    --bs-accordion-btn-active-icon: url('../assets/img/flecha_dark.png');
  }

  .acordeonbtn {
    font-size: 12pt;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /* border-width: 6px; */
    border-color: #C2F2D3;
    height: 35px;
  }

  .acordeonbody {
    font-size: 12pt;
    font-weight: 500;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  .s_izq {
    padding-right: 10px;
    text-align: left;
  }

  .s_der {
    padding-left: 10px;
    text-align: left;
  }

  table {
    border-collapse: separate; 
    border-spacing: 2px;
    width: 95%;
    /* margin: 5px; 
    padding-left: 5px;
    padding-right: 5px;  */
  }

  hr {
    height: 5px;
    background-color: #01290F; /* Color de la línea */
    border: none;
  }

  .grid_acordeon {
    width: 98%;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, auto);
  }

  #grid_acordeon1 {
    grid-template-areas:    "SIZ1 SDR1"                             
                            "SIZ1 SDR1";
  }

  #sizq1 {
    grid-area: SIZ1;
  }

  #sder1 {
    grid-area: SDR1;
  }

  #grid_acordeon2 {
    grid-template-areas:    "SIZ2 SDR2"                             
                            "SIZ2 SDR2";
  }

  #sizq2 {
    grid-area: SIZ2;
  }

  #sder2 {
    grid-area: SDR2;
  }

  #grid_acordeon3 {
    grid-template-areas:    "SIZ3 SDR3"                             
                            "SIZ3 SDR3";
  }

  #sizq3 {
    grid-area: SIZ3;
  }

  #sder3 {
    grid-area: SDR3;
  }

  #grid_acordeon4 {
    grid-template-areas:    "SIZ4 SDR4"                             
                            "SIZ4 SDR4";
  }

  #sizq4 {
    grid-area: SIZ4;
  }

  #sder4 {
    grid-area: SDR4;
  }

  #grid_acordeon5 {
    grid-template-areas:    "SIZ5 SIZ5"                             
                            "SIZ5 SIZ5";
  }

  #sizq5 {
    grid-area: SIZ5;
  }

  /* #sder5 {
    grid-area: SDR5;
  } */

  #grid_acordeon6 {
    grid-template-areas:    "SIZ6 SDR6"                             
                            "SIZ6 SDR6";
  }

  #sizq6 {
    grid-area: SIZ6;
  }

  #sder6 {
    grid-area: SDR6;
  }

  #p1 {
    text-align: justify;
  }

  .lbl {
    color:#007D39;
    font-size: 12pt;
    font-weight: 600;
  }

  .inp {
    width: 100%; /*#4A4F4B*/
    height: 30px;
    font-size: 12pt;
    font-weight: 500;
    color: #025920;
    background-color: #E1F2E7;
    border: double;
    border-color: #025920;
    box-shadow: 3px 3px 5px -2px #737A75 inset; 
    border-radius: 5px;
    padding-left: 10px;
    margin-left: 5px;
    text-align: left;
  }

  #nchild{
    width: 60px;
  }

  .boton {
    width: 110px;
    padding: 2px 6px 2px 6px;  /* arriba | derecha | abajo | izquierda */
    border: solid;
    border-color: #02732A;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px #025920;
    color: #025920;
    font-size: 10pt;
    font-weight: bold;
    background-color: #C2F2D3;
  }

  .boton:hover {
    border-color: #C2F2D3;
    color: #C2F2D3;
    background-color: #007D39;
  }

  #saveafil{
    width: 200px;
  }

  #lblbtn {
    width: 40px;
  }

  #protecdatos{
    margin: 0px 10% 20px 10%; /*  arriba | derecha | abajo | izquierda */
    width: 80%;
  }


  /* @media screen and (max-width: 900px) and (min-width: 615px) { 
    div {        
      grid-template-columns: repeat(3, 33.3%);
      grid-template-rows: repeat(4, auto);

      grid-template-areas:    "H H H"
                              "M M M"
                              "M M M"                                                                
                              "F F F"; 
    }

  }

  @media screen and (max-width: 615px) {  
    div {        
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: repeat(4, auto);

      grid-template-areas:    "H H"
                              "M M"
                              "M M"                                                                  
                              "F F"; 
    }

  } */

</style>