<template>
  <div id="grid">

    <header id="encabezado" data-bs-theme="dark" class="p-3 m-0 bg-body-tertiary">
      <div id="grid_encabezado">
        <!-- <span id="spn"></span> -->
        <figure id="fig1">
          <img id="img1" src="./assets/img/cana.png" alt="Caña de Azúcar" >
        </figure>
        <h1 id="h1">SICS</h1>
        <h2 id="h21">SISTEMA DE INFORMACIÓN PARA LA GESTIÓN DEL CENSO SINDICAL</h2>
        <h2 id="h22">SINTRAICAÑAZUCOL</h2>
        <figure id="fig2">
          <img id="img2" src="./assets/img/logo.png" alt="Logo de SINTRAICAÑAZUCOL" >
        </figure>
      </div>
    </header>

    <main id="bloque_principal" class="p-0 m-0 text-primary-emphasis">
      <AutenticacionView/>
    </main>

    <footer id="pie_pagina" data-bs-theme="dark" class="p-3 m-0 bg-body-tertiary">
      <address><a href="mailto: soporte@sicssintraicanazucol.org">soporte@sicssintraicanazucol.org</a></address>
      <small id="sml1">&copy; Todos los Derechos Reservados 2024</small>
      <br><br>
      <small>
        <p>Desarrollado por<br>
        <a href="https://mail.google.com/mail/?view=cm&fs=1&to=ivonnecastao@gmail.com&su=Me interesa tu App Web!&body=Hola, vi tu App Web y me interesan tus desarrollos..." target="_blank" rel="external noopener noreferrer">TECDIASOFT Consultoría & Soluciones Tecnológicas</a><br> para la Central Unitaria de Trabajadores - CUT</p>
      </small>
    </footer>

  </div>
</template>

<script>

  import AutenticacionView from './views/AutenticacionView.vue'

  export default {
    name: 'App',  

    components: {  
      AutenticacionView,
    },
  }

</script>

<style scoped>

  * {
      text-align: center;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  #grid { 
    width: 100%;
    color:#025920;
    background-color: #E1F2E7;
    display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(4, auto);

    grid-template-areas:    "H H H H"                             
                            "M M M M"
                            "M M M M"
                            "F F F F";  
  }

  #grid_encabezado { 
    width: 100%;
    justify-content: center; 
    align-items: center;
    display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(5, 20%);
    grid-template-rows: repeat(3, auto);

    grid-template-areas:    "F1 H1 H1 H1 F2"
                            "F1 H21 H21 H21 F2"
                            "F1 H22 H22 H22 F2";  
  }

  header {
    grid-area: H; 
  }

  #spn {
    grid-area: SPN;
  }

  #h1 {
    font-size: 40pt;
    font-weight: bolder;
    color: #007D39;
    text-shadow: 3px 3px 5px #C2F2D3;
    grid-area: H1;
  }

  #h21 {
    grid-area: H21;
  }

  #h22 {
    grid-area: H22;
  }

  #h21, #h22 {
    font-size: 20pt;
    font-weight: bolder;
    color: #C2F2D3;  /* #C2F2D3 - #007D39 */
    text-shadow: 3px 3px 5px #007D39;
  }

  #fig1 {
    display: flex; 
    justify-content: center; 
    align-items: center;
    grid-area: F1;
  }

  #img1 {
    width: 30%;
    height: 30%;
  }

  #fig2 {
    display: flex; 
    justify-content: center; 
    align-items: center;
    grid-area: F2;
  }

  #img2 {
    width: 60%;
    height: 60%;
  }

  main {
    grid-area: M; 
  }

  footer {
    color: #C2F2D3;
    text-align: center;
    margin: 10px;
    padding: 10px;
    padding-bottom: 30px;
    grid-area: F; 
  }

  address a, #sml1 {
    font-weight: 600;
  }

  footer a {
    color: #C2F2D3;
    text-decoration: none;
  }

  a img {
    border-width: 0px;
  }

  @media screen and (min-width: 615px) and (max-width: 900px) { 
    #grid {        
      grid-template-columns: repeat(3, 33.3%);
      grid-template-rows: repeat(4, auto);

      grid-template-areas:    "H H H"
                              "M M M"
                              "M M M"                                                                
                              "F F F"; 
    }

    #h21, #spn {
      display: none;
    }

    #grid_encabezado { 
      grid-template-columns: 25% 50% 25%;
      grid-template-rows: repeat(2, auto);

      grid-template-areas:   "F1 H1 F2"
                                          "F1 H22 F2";  
    }
  }

  @media screen and (min-width: 300px) and (max-width: 615px) {  
    #grid {        
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: repeat(4, auto);

      grid-template-areas:   "H H"
                                          "M M"
                                          "M M"                                                                  
                                          "F F"; 
    }

    #h1 {
      font-size: 50pt;
    }

    #h21, #h22, #spn {
      display: none;
    }

    #fig2 {
      padding-top: 20px;
    }

     #grid_encabezado { 
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: repeat(1, auto);

      grid-template-areas:    "H1 F2";  
    }
  }

  @media screen and (max-width: 300px) {  
    #grid {        
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: repeat(4, auto);

      grid-template-areas:    "H H"
                              "M M"
                              "M M"                                                                  
                              "F F"; 
    }

    #h1 {
      font-size: 45pt;
    }

    #h21, #h22, #spn {
      display: none;
    }

    #fig2 {
      padding-top: 20px;
    }

     #grid_encabezado { 
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: repeat(1, auto);

      grid-template-areas:    "H1 F2";  
    }
  }

</style>