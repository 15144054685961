<template>
  <div id="modAdminView">

    <nav class="navbar navbar-expand-lg p-2 m-0 bg-body-tertiary" data-bs-theme="dark">
      <div class="container-fluid">
        <label class="modulo">ADMINISTRATIVO <img class="imgmenu" src="../assets/img/admin.png"></label>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                USUARIOS
              </a>
              <ul class="dropdown-menu">
                <li><router-link to="/CrearUsuario" class="dropdown-item" aria-current="page" @click="this.$store.dispatch('actualizarVistaInicial', false)">CREAR USUARIO</router-link></li>
                <li><hr class="dropdown-divider"></li>
                <li><router-link to="/ConsultarUsuario" class="dropdown-item" @click="this.$store.dispatch('actualizarVistaInicial', false)">CONSULTAR USUARIO</router-link></li>
                <li><hr class="dropdown-divider"></li>
                <li><router-link to="/ListarUsuarios" class="dropdown-item" @click="this.$store.dispatch('actualizarVistaInicial', false)">LISTAR USUARIOS</router-link></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                SUBDIRECTIVAS
              </a>
              <ul class="dropdown-menu">
                <li><router-link to="/RegistrarSubdirectiva" class="dropdown-item" aria-current="page" @click="this.$store.dispatch('actualizarVistaInicial', false)">REGISTRAR SUBDIRECTIVA</router-link></li>
                <li><hr class="dropdown-divider"></li>
                <li><router-link to="/ConsultarSubdirectivaAdmin" class="dropdown-item" @click="this.$store.dispatch('actualizarVistaInicial', false)">CONSULTAR SUBDIRECTIVA</router-link></li>
                <li><hr class="dropdown-divider"></li>
                <li><router-link to="/ListarSubdirectivas" class="dropdown-item" @click="this.$store.dispatch('actualizarVistaInicial', false)">LISTAR SUBDIRECTIVAS</router-link></li>
              </ul>
            </li>
          </ul>
          <router-link id="btnuser" to="/ConsultarUsuarioActivo" class="nav-link usuactivo" @click="this.$store.dispatch('actualizarVistaInicial', false)">{{ Usuario.usuario }} <img class="imgmenu2" src="../assets/img/perfil.png"></router-link> 
          <router-link to="/Salir" class="nav-link" @click="this.$store.dispatch('actualizarVistaInicial', false)"><img class="out" src="../assets/img/salir.png"></router-link>
        </div>
      </div>
    </nav>

    <section id="" v-if="VistaInicial === true">
      <Bienvenida/>
    </section>
    <section id="" v-else>
      <router-view/>
    </section>

  </div>  
</template>

<script>
  import store from '../store';
  import { ref } from 'vue';
  
  import Vacio from '../components/Vacio.vue'
  import CrearUsuario from '../components/CrearUsuario.vue'
  import ConsultarUsuario from '../components/ConsultarUsuario.vue'
  import ListarUsuarios from '../components/ListarUsuarios.vue'
  import ConsultarUsuarioActivo from '../components/ConsultarUsuarioActivo.vue'
  import RegistrarSubdirectiva from '../components/RegistrarSubdirectiva.vue'
  import ConsultarSubdirectivaAdmin from '../components/ConsultarSubdirectivaAdmin.vue'  
  import ListarSubdirectivas from '../components/ListarSubdirectivas.vue'
  import Bienvenida from '../components/Bienvenida.vue'
  import Salir from '../components/Salir.vue'
  
  export default {
    name: 'ModuloAdminView',  

    components: {  
      Vacio,
      CrearUsuario,
      ConsultarUsuario,
      ListarUsuarios,
      ConsultarUsuarioActivo,
      RegistrarSubdirectiva,
      ConsultarSubdirectivaAdmin,
      ListarSubdirectivas,
      Bienvenida,
      Salir
    },

    setup(){        

      return{

      };
    },
    
    computed: {
      VistaInicial() {
        return this.$store.state.VistaInicial;
      },

      Usuario() {
        return this.$store.state.Usuario;
      }
    },
  }
</script>

<style scoped>

  #modAdminView {
    color:#025920;
    background-color: #E1F2E7;
    width: 100%;
    /* display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(4, auto);

    grid-template-areas:    "H H H H"                             
                            "M M M M"
                            "M M M M"
                            "F F F F"; */
  }

  nav {
    width: 100%; 
  }

  .nav-link, .dropdown-item {
    color: #C2F2D3;
    font-weight: bolder;
  }

  .nav-link:hover, .dropdown-item:hover {
    color: #007D39;
  }

  .nav-link:active, .dropdown-item:active {
    background-color: #C2F2D3;
  }

  .modulo {
    font-weight: bolder;
    font-size: 16pt;
    padding: 0 15px 0 15px;  /* arriba | derecha | abajo | izquierda */
    margin-right: 30px;
    color: #C2F2D3;
    border: solid;
    border-width: 2px;
    border-color: #C2F2D3;
  }

  .usuactivo {
    text-align: left;
    font-weight: bolder;
    padding: 2px 15px 2px 15px;  /* arriba | derecha | abajo | izquierda */
    color: #007D39;
    background-color: #C2F2D3;
    border: solid;
    border-color: #025920;
    border-radius: 5px;
  }
  
  .imgmenu {
    width: 30px;
    height: 30px;
    margin: 0px 0px 1px 5px;
  }

  .imgmenu2 {
    width: 25px;
    height: 25px;
    margin: 0px 0px 1px 5px;
  }

  .out {
    width: 32px;
    height: 32px;
  }

  h5 {
    color:#025920;
    font-weight: bold;
  }

  @media screen and (max-width: 992px) { 

    #btnuser{
       text-align: center;
    }

  }

  /* 
  @media screen and (max-width: 615px) {  
    #modAdminView {        
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: repeat(4, auto);

      grid-template-areas:    "H H"
                              "M M"
                              "M M"                                                                  
                              "F F"; 
    }

  } */

</style>