<template>
  <div id="listasubdirectivas" :key="renderizado">

    <h4 id="h4">LISTADO DE SUBDIRECTIVAS</h4>
    <section id="s1">      
      <br><br>
      <div class="scrollable-table">  
        <table>        
          <thead id="thead1"> 
            <tr>              
              <th>NOMBRE</th>
              <th>DIRECCIÓN</th>
              <th>TELÉFONO</th>
              <th>@CORREO</th>
              <th>DEPARTAMENTO</th>
              <th>MUNICIPIO</th>
              <th>PRESIDENTE(A)</th>
              <th>SECRETARIO(A)</th>
            </tr>
          </thead>
          <tbody id="tbody1">
            <tr v-for="(item, index) in ListaSubdirectivas" :key="item.nombre_sub">
              <td>{{ item.nombre_sub }}</td>
              <td>{{ item.direccion }}</td>
              <td>{{ item.telefono }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.depto }}</td>
              <td>{{ item.municipio }}</td>
              <td>{{ item.nombre_presi }}</td>
              <td>{{ item.nombre_secre }}</td>
            </tr>
          </tbody>      
        </table>
      </div>
    </section>
    <br>
   <section id="s2">
      <br><br>
      <button id="" class="boton" type="button" @click="descargar">DESCARGAR</button>
      <label id="lblbtn"> </label>
      <button id="" class="boton" type="button" @click="retornar">CERRAR</button>
      <br><br>
    </section>
    <br>
  </div>
</template>

<script>
import store from '../store';
import axios from 'axios'; 
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex'; 
import { cnxsrv } from '../config';

export default {
  name: 'ListarSubdirectivas',

  setup(){

    let renderizado = ref(0);

    const Usuario = computed(() => store.state.Usuario);

    /* Datos personales */
    let subdirectiva_basic = ref({      
      nombre_sub: '', 
      direccion: '', 
      telefono: '', 
      email: '',
      depto: '', 
      municipio: '', 
      nombre_presi: '', 
      nombre_secre: '',
    });

    let ListaSubdirectivas = ref([]);

    const recargarComponente = () => {
        renderizado.value++;
    }

    async function listarSubdirectivas(){

      try {
        /* ---------------------- */
        console.log('INICIANDO CONSULTA');
        /* ---------------------- */

        /* Información Personal Afiliado */
        let url = `${cnxsrv.url}/api/SrvGestionSubdirectivas/consultarListaSubdirectivasGen`;
        let respuesta = await axios.get(url);

        console.log('respuesta:', respuesta);

        let data = respuesta.data;        

        if(data.body.length != 0){
          if (respuesta.status == 200) {
            console.log('Información de subdirectivas OK');
            
            ListaSubdirectivas.value = data.body;            
            console.log('ListaSubdirectivas.value:', ListaSubdirectivas.value);
            
          } else {
            console.error(`Error al buscar la información de las subdirectivas`, respuesta.statusText);
          }
        } else {console.log('No hay subdirectivas');}

      } catch (error) {
        console.error(error);
      } 

    }

    const descargar = async () => {
      try {
        /* Reportes */

        let usuario = Usuario.value.usuario;
        let subdirectiva = Usuario.value.subdirectiva;
        let fecha_actual = new Date().toLocaleString().replace(/[/:]/g, '-').replace(/,/g, '_').replace(/ /g, '').replace(/. /g, '');
        console.log('fecha_actual:', fecha_actual); 

        let parametros = { usuario, subdirectiva };

        let url = `${cnxsrv.url}/api/SrvReportes/generarReporteListaSubdirectiva`;
        let respuesta = await axios.get(url, { params: parametros, responseType: 'blob' });
        let data = respuesta.data; 

        console.log('respuesta:', respuesta);            

        if(data.length != 0){
          if (respuesta.status == 200) {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            console.log('blob:', blob);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Reporte_Listado_Subdirectivas_${fecha_actual}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error(`Error al buscar la información del reporte`, respuesta.statusText);
          }

        } else {console.log('No hay datos para el reporte');}

      } catch (error) {
        console.error(error);
      }

    };

    const retornar = () => {
      store.dispatch('actualizarAppIniciada', true);
      store.dispatch('actualizarVistaInicial', true);
      recargarComponente();
    };

    onMounted(listarSubdirectivas);

    return {
      renderizado,
      recargarComponente,
      ListaSubdirectivas,
      descargar,
      retornar,
    };
  },

  computed: {
    AppIniciada() {
        return this.$store.state.AppIniciada;
    },

    VistaInicial() {
      return this.$store.state.VistaInicial;
    },
    
    Usuario() {
        return this.$store.state.Usuario;
    },
  }

}
</script>

<style scoped>

#s1, #s2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boton {
    width: 110px;
    padding: 2px 6px 2px 6px;  /* arriba | derecha | abajo | izquierda */
    border: solid;
    border-color: #02732A;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px #025920;
    color: #025920;
    font-size: 10pt;
    font-weight: bold;
    background-color: #C2F2D3;
  }

  .boton:hover {
    border-color: #C2F2D3;
    color: #C2F2D3;
    background-color: #007D39;
  }

#h4 {
  color:#025920;
  font-weight: bold;
  padding: 30px 6px 20px 6px;  /*  arriba | derecha | abajo | izquierda */
  text-transform: uppercase;
}

.scrollable-table {
  width: 80%; 
  height: 265px; 
  overflow: scroll;
  display: block;
  box-shadow: 3px 3px 5px 1px #007D39;
  scrollbar-color: #025920 #C2F2D3;
  scrollbar-width: thin; /* thin*/
}

table {
  border-style: solid;
  border-width: 2px;
  border-color: #01290F;  
  border-collapse: separate;  /* collapse, separate*/
  border-spacing: 2px;
  width: 99%;
  margin: 5px; 
  padding: 1px;
}

thead {
  background-color: #B8CFBE;
  color:#025920; 
  font-size:12pt; 
  font-weight:bolder;
  /* Para que al mover el scroll no se muevan los encabezados*/    
  position: sticky;
  top: 0;
}

tbody {
  background-color: #E1F2E7;
  color:#01290F; 
  font-size:13pt; 
  font-weight:500;
}

thead th {  /* celdas encabezado */
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  border-style: solid;
  border-width: 3px;
  border-color: #01290F;
}

tbody td { 
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: #01290F;
}

.accion {  
  padding: 0px;
  /* border-style: none; */
  /* border-width: 0px;  */
}

#lblbtn {
    width: 40px;
  }


  @media screen and (max-width: 576px) {

    #lblbtn {
      width: 20px;
    }
  }

</style>