import { createStore } from 'vuex'
import Vue from 'vue';
import Vuex from 'vuex'

export default new Vuex.Store({

  state: {

    AppIniciada: false,

    VistaInicial: true,
     
    Usuario: {  
      usuario: '',
      passwd: '',
      rol: null,
      subdirectiva: '',
      activo: null,
    },

  },

  getters: {
  },

  mutations: {

    actualizarAppIniciada(state, paramIniciada) {
      state.AppIniciada = paramIniciada;
      console.log('AppIniciada: ', state.AppIniciada);
    },

    actualizarVistaInicial(state, paramInicio) {
      state.VistaInicial = paramInicio;
    },

    actualizarUsuario(state, paramUsuario) {
      state.Usuario = paramUsuario;
      state.Usuario.usuario= state.Usuario.usuario.replace(/^['"]|['"]$/g, '');
      state.Usuario.subdirectiva= state.Usuario.subdirectiva.replace(/^['"]|['"]$/g, '');
      console.log('Usuario STORE: ', state.Usuario);
    },

  },

  actions: {
    
    actualizarAppIniciada({ commit }, paramIniciada) {   
      commit('actualizarAppIniciada', paramIniciada);
    },

    actualizarVistaInicial({ commit }, paramInicio) {   
      commit('actualizarVistaInicial', paramInicio);
    },

    actualizarUsuario({ commit }, paramUsuario) {
      commit('actualizarUsuario', paramUsuario);
    },

  },

  modules: {
  }
})
