<template>
  <div id="vacio">
  </div>
</template>

<script>
  export default {
    name: 'Vacio'
  }
</script>

<style scoped>
</style>