<template>
  <div id="vacioView">
  </div>
</template>

<script>
  export default {
    name: 'VacioView'
  }
</script>

<style scoped>
</style>