<template>
  <div id="bienvenida">
    <section>
      <p id="saludo">BIENVENIDO/A AL SISTEMA DE INFORMACIÓN PARA LA GESTIÓN DEL CENSO SINDICAL DE SINTRAICAÑAZUCOL</p>
    </section>
  </div>
</template>

<script>
  // import { ref, onMounted } from 'vue';

  export default {
    name: 'Bienvenida',

    // setup() {
    //   const deviceInfo = ref({
    //     userAgent: '',
    //     userGeolocation: '',
    //   });

    //   onMounted(() => {
    //     deviceInfo.value = {
    //       userAgent: navigator.userAgentData,
    //       userGeolocation: navigator.geolocation,
    //     };
    //     console.log(deviceInfo.value);
    //   });

    //   return {
    //   };
    // },
  }
</script>

<style scoped>

  #bienvenida {
    color:#025920;
    background-color: #E1F2E7;
    width: 100%;
    background-image: url(../assets/img/fondocanas.png);
    background-repeat: repeat;
    background-size: cover;
    /* display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(4, auto);

    grid-template-areas:    "H H H H"                             
                            "M M M M"
                            "M M M M"
                            "F F F F"; */
  }

  section {
    display: flex; 
    justify-content: center; 
    align-items: center;
    width: 100%;
    padding: 70px 20% 50px 20%; /* arriba | derecha | abajo | izquierda */
  }

  p {
    color:#025920;
    font-size: 22pt;
    font-weight: bolder;
    text-align: center;
  }

  @media screen and (max-width: 400px) { 

    p {
      font-size: 18pt;
    }

  }

</style>