<template>
  <div id="generarReportes" :key="renderizado">

    <h4 id="h4">REPORTES DE SUBDIRECTIVA</h4>
    <section id="s1">      
      <br><br>
      <div class="scrollable-table">  
        <table>        
          <thead id="thead1"> 
            <tr>
              <th>REPORTE</th>
              <th>ACCIÓN</th>
            </tr>
          </thead>
          <tbody id="tbody1">
            <tr>
              <td>Reporte 1</td>
              <td class="accion"><button id="saveafil" class="boton1" type="button" @click="generareporte1(); recargarComponente();">GENERAR REPORTE</button></td>
            </tr>
          </tbody>      
        </table>
      </div>
    </section>
    <br>
    <section id="s2">
      <br><br>
      <button id="" class="boton" type="button" @click="retornar">CERRAR</button>
      <br><br>
    </section>
    <br>
  </div>
</template>

<script>
import store from '../store';
  import axios from 'axios'; 
  import { ref, computed, onMounted } from 'vue';
  import { useStore } from 'vuex'; 
  import { cnxsrv } from '../config';

export default {
  name: 'GenerarReportes',

  setup(){ 

    let renderizado = ref(0);

    const Usuario = computed(() => store.state.Usuario);

    const recargarComponente = () => {
      renderizado.value++;
    }

    async function generareporte1() {
      try {
        /* Reportes */

        let usuario = Usuario.value.usuario;
        let subdirectiva = Usuario.value.subdirectiva;
        let fecha_actual = new Date().toLocaleString().replace(/[/:]/g, '-').replace(/,/g, '_').replace(/ /g, '').replace(/. /g, '');
        console.log('fecha_actual:', fecha_actual); 

        let parametros = { usuario, subdirectiva };
        console.log('parametros:', parametros); 

        let url = `${cnxsrv.url}/api/SrvReportes/generarReporteBase`;
        let respuesta = await axios.get(url, { params: parametros, responseType: 'blob' });
        let data = respuesta.data; 

        console.log('respuesta:', respuesta);            

        if(data.length != 0){
          if (respuesta.status == 200) {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            console.log('blob:', blob);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Reporte_Generico_1_${fecha_actual}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error(`Error al buscar la información del reporte`, respuesta.statusText);
          }

        } else {console.log('No hay datos para el reporte');}

      } catch (error) {
        console.error(error);
      }

    };

    const retornar = () => {
        store.dispatch('actualizarAppIniciada', true);
        store.dispatch('actualizarVistaInicial', true);
        recargarComponente();
      };

    return{
      renderizado,
      recargarComponente,
      generareporte1,
      retornar,
    };
  },

  computed: {
    AppIniciada() {
        return this.$store.state.AppIniciada;
    },

    VistaInicial() {
      return this.$store.state.VistaInicial;
    },
    
    Usuario() {
        return this.$store.state.Usuario;
    }
  },

}
</script>

<style scoped>

#generarReportes {
    color:#025920;
    background-color: #E1F2E7;
    width: 100%;
    text-align: center;
    display: grid;
    /* grid-gap: 1px;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(4, auto);

    grid-template-areas:    "H H H H"                             
                            "M M M M"
                            "M M M M"
                            "F F F F"; */
  }

  #s1, #s2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .boton {
    width: 100px;
    padding: 2px 6px 2px 6px;  /* arriba | derecha | abajo | izquierda */
    border: solid;
    border-color: #02732A;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px #025920;
    color: #025920;
    font-size: 10pt;
    font-weight: bold;
    background-color: #C2F2D3;
  }

  .boton:hover {
    border-color: #C2F2D3;
    color: #C2F2D3;
    background-color: #007D39;
  }

  .boton1 {
    background-color: #C2F2D3; 
    color: #025920; 
    font-size: 10px; 
    font-weight: bold;
    border-radius: 3px;
    border-color: #02732A; 
    width: 80px; 
    height: 25px; 
    text-align: center; 
    margin: 3px;
    box-shadow: 3px 3px 5px #025920; 
  }

  .boton1:hover {
    border-color: #C2F2D3;
    color: #C2F2D3;
    background-color: #007D39; 
  }

  #h4 {
    color:#025920;
    font-weight: bold;
    padding: 30px 6px 20px 6px;  /*  arriba | derecha | abajo | izquierda */
    text-transform: uppercase;
  }

  .scrollable-table {
    width: 80%; 
    height: 265px; 
    overflow: scroll;
    display: block;
    box-shadow: 3px 3px 5px 1px #007D39;
    scrollbar-color: #025920 #C2F2D3;
    scrollbar-width: thin; /* thin*/
  }

  table {
    border-style: solid;
    border-width: 2px;
    border-color: #01290F;  
    border-collapse: separate;  /* collapse, separate*/
    border-spacing: 2px;
    width: 99%;
    margin: 5px; 
    padding: 1px;
  }

  thead {
    background-color: #B8CFBE;
    color:#025920; 
    font-size:12pt; 
    font-weight:bolder;
    /* Para que al mover el scroll no se muevan los encabezados*/    
    position: sticky;
    top: 0;
  }

  tbody {
    background-color: #E1F2E7;
    color:#01290F; 
    font-size:13pt; 
    font-weight:500;
  }

  thead th {  /* celdas encabezado */
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    border-style: solid;
    border-width: 3px;
    border-color: #01290F;
  }

  tbody td { 
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    border-style: solid;
    border-width: 1px;
    border-color: #01290F;
  }

  .accion {  
    padding: 0px;
    text-align: center;
    width: 180px;
    /* border-style: none; */
    /* border-width: 0px;  */
  }

  #saveafil{
    width: 150px;
  }

  #sform {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 25% 20px 25%;  /*  arriba | derecha | abajo | izquierda */
  }

</style>