<template>
  <div id="listafiliadossindi" :key="renderizado">

    <h4 id="h4">LISTADO DE AFILIADOS(AS)</h4>

    <section id="s1">
      <label for="" class="lbl">AFILIADO(A): &nbsp;</label>
      <input id="in3" class="inp" type="search" placeholder="Id Afiliado(a)" v-model="Id_Afiliado" @focus="Subdirectiva=''"/>
      <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
      <label for="" class="lbl">SUBDIRECTIVA: &nbsp;</label>
      <select id="sel0" class="inp" v-model="Subdirectiva" @focus="Id_Afiliado=''">
        <option value="" selected>Seleccione una opción</option>
        <option value="TODAS">TODAS</option>
        <template v-for="(sub, index) in ListaSubdirectivas" :key="index">                            
          <option :value="sub.nombre_sub">{{sub.nombre_sub}}</option>
        </template>
      </select>
      <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
      <button id="btn4" class="btnsrch" @click="listarAfiliados(); recargarComponente();"><img id="img1" src="../assets/img/buscar.png"></button>
    </section>
    <br>

    <section id="s2">      
      <br><br>
      <div class="scrollable-table">  
        <table>        
          <thead id="thead1"> 
            <tr>
              <th>IDENTIFICACIÓN</th>
              <th>NOMBRE</th>
              <th>CÓD. INTERNO</th>
              <th>TELÉFONO</th>
              <th>DIRECCIÓN</th>
              <th>DEPARTAMENTO</th>
              <th>MUNICIPIO</th>
              <th>SUBDIRECTIVA</th>
            </tr>
          </thead>
          <tbody id="tbody1">
            <tr v-for="(item, index) in ListaAfiliados" :key="item.id_afiliado">
              <td>{{ item.id_afiliado }}</td>
              <td>{{ item.nombre }}</td>
              <td>{{ item.cod_interno }}</td>
              <td>{{ item.telefono }}</td>
              <td>{{ item.direccion }}</td>
              <td>{{ item.depto }}</td>
              <td>{{ item.municipio }}</td>
              <td>{{ item.subdirectiva }}</td>
            </tr>
          </tbody>      
        </table>
      </div>
    </section>
    <br>

    <section id="s3">
      <button id="" class="boton2" @click="descargar()" >DESCARGAR</button>
      <label id="lblbtn"> </label>
      <button id="" class="boton" type="button" @click="retornar">CERRAR</button>
    </section>
   
    <br>
  </div>
</template>

<script>
import store from '../store';
import axios from 'axios'; 
import { ref, computed, onMounted } from 'vue';
import { cnxsrv } from '../config';

export default {
  name: 'ListarAfiliadosSindi',

  setup(){

    let renderizado = ref(0);
    let Id_Afiliado =  ref('');
    let Subdirectiva =  ref('');

    const Usuario = computed(() => store.state.Usuario);

    /* Datos personales */
    let afi_dat_pers_basic = ref({      
      id_afiliado: '',
      nombre: '',
      cod_interno: '',
      telefono: '',
      direccion: '',
      depto: '',
      municipio: '',
      subdirectiva: '',
    });

    let ListaSubdirectivas = ref([]);

    let ListaAfiliados = ref([]);
    
    const recargarComponente = () => {
        renderizado.value++;
    }
    
    async function listarSubdirectivas(){

      try {
        /* Información Subdirectivas */
        let url = `${cnxsrv.url}/api/SrvGestionSubdirectivas/consultarListaSubdirectivas`;
        let respuesta = await axios.get(url);
        let data = respuesta.data;        

        if(data.body.length != 0){
          if (respuesta.status == 200) {
            console.log('Información subdirectivas OK');
            
            ListaSubdirectivas.value = data.body;            
            console.log('ListaSubdirectivas.value:', ListaSubdirectivas.value);
            
          } else {
            console.error(`Error al buscar la información de las subdirectivas`, respuesta.statusText);
          }
        } else {console.log('No hay subdirectivas');}

      } catch (error) {
        console.error(error);
      }
    }

    async function descargar(){
      try {
        /* Reportes */

        let usuario = Usuario.value.usuario;
        let subdirectiva = Usuario.value.subdirectiva;
        let fecha_actual = new Date().toLocaleString().replace(/[/:]/g, '-').replace(/,/g, '_').replace(/ /g, '').replace(/. /g, '');
        console.log('fecha_actual:', fecha_actual); 
        let afiliado = Id_Afiliado.value;
        let sub_afiliado = Subdirectiva.value;

        let parametros = { usuario, subdirectiva, afiliado, sub_afiliado };

        let url = `${cnxsrv.url}/api/SrvReportes/generarReporteListaAfiliadosSindi`;
        let respuesta = await axios.get(url, { params: parametros, responseType: 'blob' });
        let data = respuesta.data; 

        console.log('respuesta:', respuesta);            

        if(data.size != 0){
          if (respuesta.status == 200) {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            console.log('blob:', blob);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Reporte_Listado_Afiliados_Sindi_${fecha_actual}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error(`Error al buscar la información del reporte`, respuesta.statusText);
          }

        } else {console.log('No hay datos para el reporte');}

      } catch (error) {
        console.error(error);
      }

    };

    async function listarAfiliados(){

      try {
        /* ---------------------- */
        console.log('INICIANDO CONSULTA');
        console.log('Subdirectiva:', Subdirectiva.value);
        ListaAfiliados.value = null;
        /* ---------------------- */

        /* Información Básica Personal Afiliados Activos */
        let url = `${cnxsrv.url}/api/SrvGestionAfiliados/consultarListaAfiliados`;
        let respuesta = await axios.get(url);
        let data = respuesta.data;        

        if(data.body.length != 0){
          if (respuesta.status == 200) {
            console.log('Información de afiliados OK'); 

            if(Id_Afiliado.value != ''){
              ListaAfiliados.value = data.body;
              ListaAfiliados.value = ListaAfiliados.value.filter(item => item.id_afiliado == Id_Afiliado.value);
            } else if((Id_Afiliado.value == '') && (Subdirectiva.value == '')){
              ListaAfiliados.value = null;
            } else if(Subdirectiva.value == 'TODAS'){
              ListaAfiliados.value = data.body;
            } else {
              ListaAfiliados.value = data.body;
              ListaAfiliados.value = ListaAfiliados.value.filter(item => item.subdirectiva == Subdirectiva.value);
            }
            console.log('ListaAfiliados.value:', ListaAfiliados.value);
            
          } else {
            console.error(`Error al buscar la información de afiliados`, respuesta.statusText);
          }
        } else {console.log('No hay afiliados');}

      } catch (error) {
        console.error(error);
      } 
    }

    const retornar = () => {
      store.dispatch('actualizarAppIniciada', true);
      store.dispatch('actualizarVistaInicial', true);
      recargarComponente();
    };

    onMounted(listarSubdirectivas);

    return {
      renderizado,
      Id_Afiliado,
      Subdirectiva,
      ListaAfiliados,
      ListaSubdirectivas,
      recargarComponente,
      descargar,
      listarAfiliados,
      retornar,
    };
  },

  computed: {
    AppIniciada() {
        return this.$store.state.AppIniciada;
    },

    VistaInicial() {
      return this.$store.state.VistaInicial;
    },
    
    Usuario() {
        return this.$store.state.Usuario;
    }
  }

}
</script>

<style scoped>

  #s1, #s2, #s3 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #h4 {
    color:#025920;
    font-weight: bold;
    padding: 30px 6px 20px 6px;  /*  arriba | derecha | abajo | izquierda */
  }

  #img1 {
    width: 22px; 
    height: 22px;
  }

  .btnsrch {
    border-radius: 4px;
    box-shadow: 2px 2px 5px 1px #025920;
    width: 30px; 
    height: 30px; 
    padding: 1px;
  }

  .btnsrch:hover {
    box-shadow: 2px 2px 5px #007D39;
  }

  #img1:hover {
    width: 26px; 
    height: 26px;
  }

  .inp {
    height: 30px;
    font-size: 12pt;
    font-weight: 500;
    color: #025920;
    background-color: #E1F2E7;
    border: double;
    border-color: #025920;
    box-shadow: 3px 3px 5px -2px #737A75 inset; 
    border-radius: 5px;
    padding-left: 10px;
    margin-left: 5px;
    text-align: left;
  }

  .inpsel {
    height: 30px;
    font-size: 12pt;
    font-weight: 500;
    color: #025920;
    background-color: #E1F2E7;
    border: solid;
    border-color: #025920;
    border-width: 2px;
    /* box-shadow: 3px 3px 5px -2px #737A75 inset;  */
    border-radius: 3px;
    padding-left: 10px;
    margin-left: 5px;
    text-align: left;
  }

  .lbl {
    color:#007D39;
    font-size: 12pt;
    font-weight: 600;
  }

  .scrollable-table {
    width: 80%; 
    height: 265px; 
    overflow: scroll;
    display: block;
    box-shadow: 3px 3px 5px 1px #007D39;
    scrollbar-color: #025920 #C2F2D3;
    scrollbar-width: thin; /* thin*/
  }

  table {
    border-style: solid;
    border-width: 2px;
    border-color: #01290F;  
    border-collapse: separate;  /* collapse, separate*/
    border-spacing: 2px;
    width: 99%;
    margin: 5px; 
    padding: 1px;
  }

  thead {
    background-color: #B8CFBE;
    color:#025920; 
    font-size:12pt; 
    font-weight:bolder;
    /* Para que al mover el scroll no se muevan los encabezados*/    
    position: sticky;
    top: 0;
  }

  tbody {
    background-color: #E1F2E7;
    color:#01290F; 
    font-size:13pt; 
    font-weight:500;
  }

  thead th {  /* celdas encabezado */
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    border-style: solid;
    border-width: 3px;
    border-color: #01290F;
  }

  tbody td { 
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    border-style: solid;
    border-width: 1px;
    border-color: #01290F;
  }

  .boton {
    width: 110px;
    padding: 2px 6px 2px 6px;  /* arriba | derecha | abajo | izquierda */
    border: solid;
    border-color: #02732A;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px #025920;
    color: #025920;
    font-size: 10pt;
    font-weight: bold;
    background-color: #C2F2D3;
  }

  .boton:hover {
    border-color: #C2F2D3;
    color: #C2F2D3;
    background-color: #007D39;
  }

  .boton2 {
    width: 110px;
    padding: 2px 6px 2px 6px;  /* arriba | derecha | abajo | izquierda */
    border: solid;
    border-color: #02732A;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px #025920;
    color: #025920;
    font-size: 10pt;
    font-weight: bold;
    background-color: #C2F2D3;
  }

  .boton2:hover {
    border-color: #C2F2D3;
    color: #C2F2D3;
    background-color: #007D39;
  }

  #lblbtn {
    width: 40px;
  }

  @media screen and (max-width: 576px) {

    #lblbtn {
      width: 20px;
    }
  }

</style>