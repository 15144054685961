<template>
  <div id="salir" :key="renderizado">

    <br><br>
    <label id="salida">Está seguro/a de que desea salir de la aplicación?</label>
    <br><br>
    <button id="btnSi" class="boton" @click="salir()">SI</button>
    <label id="lblbtn"> </label>
    <button id="btnNo" class="boton" @click="quedarse()">NO</button>
    <br><br><br>
  </div>
</template>

<script>

  import store from '../store';
  import { ref, computed } from 'vue';

  export default {
    name: 'Salir',

    setup(){  

      const renderizado = ref(0);
      //const Usuario = computed(() => store.state.Usuario);

      const recargarComponente = () => {
        renderizado.value++;
      }

      const salir = () => {
        store.dispatch('actualizarAppIniciada', false);
        let user = {  
          usuario: '',
          passwd: '',
          rol: null,
          subdirectiva: '',
          activo: null,
        }
        //console.log('usuario:', Usuario.value);
        store.dispatch('actualizarUsuario', user);
        store.dispatch('actualizarVistaInicial', true);
        recargarComponente();
        //console.log('usuario:', Usuario.value);
      };

      const quedarse = () => {
        store.dispatch('actualizarAppIniciada', true);
        store.dispatch('actualizarVistaInicial', true);
        recargarComponente();
      };

      return{
        renderizado,
        salir,
        quedarse
      };
    },

    computed: {
      AppIniciada() {
          return this.$store.state.AppIniciada;
      },

      VistaInicial() {
        return this.$store.state.VistaInicial;
      },
      
      Usuario() {
          return this.$store.state.Usuario;
      },
    },
  }
</script>

<style scoped>

  #salida {
    color:#025920;
    font-size: 18pt;
    font-weight: bolder;
  }

  .boton {
    padding: 2px 6px 2px 6px;  /* arriba | derecha | abajo | izquierda */
    border: solid;
    border-color: #02732A;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px #025920;
    color: #025920;
    font-size: 10pt;
    font-weight: bold;
    background-color: #C2F2D3;
  }

  .boton:hover {
    border-color: #C2F2D3;
    color: #C2F2D3;
    background-color: #007D39;
  }

  #btnSi, #btnNo, #lblbtn {
    width: 60px;
  }
</style>